import { merge, pickBy } from 'lodash';
import Tooltip, { RCTooltip } from 'rc-tooltip';
import * as React from 'react';
import { LinkProps } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components/macro';
import { ZIndices } from '../types/enums';
import AriaReadable from './AriaReadable';
import CircleSpinner from './CircleSpinner';
import { UnstyledLink } from './Link';
import outlineCss from './outlineCss';

import 'rc-tooltip/assets/bootstrap.css';
import './button-tooltip.css';

export { Routes } from '../types/enums';

export type AriaButtonProps = {
  active?: boolean;
  label?: string;
  to?: LinkProps['to'];
  tooltipOptions?: { id: string } & RCTooltip.Props;
  replace?: boolean;
} & React.ComponentPropsWithRef<'button'>;

export type ButtonWithSpinnerProps = AriaButtonProps & {
  showSpinner?: boolean;
  spinnerSize?: number;
};

const DEFAULT_TOOLTIP_OPTIONS = {
  trigger: ['focus', 'hover'],
  mouseEnterDelay: 0.5,
  overlayStyle: {
    opacity: 1,
    zIndex: ZIndices.Popup,
  },
};

/**
 * Most basic a11y-friendly button component
 * NB: native 'disabled' attribute is omitted, otherwise the reader will ignore it completely
 * (instead of alerting user that there is a disabled button)
 */
export const AriaButton = React.forwardRef(function AriaButton(
  props: AriaButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  const {
    children,
    label,
    active,
    disabled,
    onClick,
    to,
    className,
    style,
    tooltipOptions,
    replace,
    ...rest
  } = props;
  const [popupVisible, setPopupVisible] = React.useState<boolean | undefined>(
    false,
  );

  const noTooltip = !tooltipOptions;

  React.useEffect(() => {
    if (noTooltip) {
      setPopupVisible(false);
    }
  }, [noTooltip]);

  const handleClick = (e) => {
    // we have to manage it manually since we omitted 'disabled' property
    if (disabled) {
      e.preventDefault();
      return;
    }

    setPopupVisible(false);

    if (onClick) {
      onClick(e);
    }
  };

  let buttonElement: React.ReactElement;

  if (to != null) {
    buttonElement = (
      <UnstyledLink
        data-active={active}
        replace={replace}
        aria-disabled={!!disabled}
        onClick={handleClick}
        to={to}
        className={className}
        style={style}
        tabIndex={rest.tabIndex}
        role={rest.role}
        aria-label={rest['aria-label']}
        aria-labelledby={rest['aria-label'] ? undefined : tooltipOptions?.id}
        aria-current={active ? 'page' : undefined}
        id={rest.id}
        {...(pickBy(
          rest,
          (val, key) => key.startsWith('data-') || key.startsWith('on'),
        ) as any)}
      >
        {label ? <AriaReadable>{label}</AriaReadable> : null}
        {children}
      </UnstyledLink>
    );
  } else {
    buttonElement = (
      <button
        data-active={active}
        aria-pressed={active}
        aria-disabled={!!disabled}
        onClick={handleClick}
        className={className}
        style={style}
        ref={ref}
        aria-labelledby={rest['aria-label'] ? undefined : tooltipOptions?.id}
        {...rest}
      >
        {label && !tooltipOptions ? <AriaReadable>{label}</AriaReadable> : null}
        {children}
      </button>
    );
  }

  if (tooltipOptions) {
    const options = merge({ ...DEFAULT_TOOLTIP_OPTIONS }, tooltipOptions);
    return (
      <Tooltip
        {...options}
        visible={popupVisible}
        onVisibleChange={setPopupVisible}
      >
        {buttonElement}
      </Tooltip>
    );
  }

  return buttonElement;
});

// :where lowers specificity, allowing to override styles without highly specific selectors
const textButtonCss = css`
  cursor: pointer;

  font-family: ${(p) => p.theme.fonts.display};
  font-size: 16px;
  font-weight: 400;

  height: 54px;
  border: 0;

  /* force button layout for non-button components (as=...) */
  a:where(&),
  li:where(&) {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    &:hover {
      text-decoration: none;
    }
  }

  a&:hover {
    text-decoration: none;
  }
`;

export const ButtonWithSpinner = React.forwardRef(function ButtonWithSpinner(
  props: ButtonWithSpinnerProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  const { children, showSpinner, spinnerSize = 24, disabled, ...rest } = props;

  return (
    <AriaButton {...rest} ref={ref} disabled={showSpinner ? true : disabled}>
      {showSpinner ? (
        <ButtonSpinner
          role="progressbar"
          aria-valuetext="loading"
          $size={spinnerSize}
        >
          <CircleSpinner
            lineWidth={3}
            style={{
              width: `${spinnerSize}px`,
              height: `${spinnerSize}px`,
              alignSelf: 'center',
            }}
          />
        </ButtonSpinner>
      ) : (
        children
      )}
    </AriaButton>
  );
});

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ButtonSpinner = styled.div<{ $size: number }>`
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
  margin: 3px auto;
  & > img {
    display: block;
    width: 100%;
    height: 100%;
    animation: ${spin} 1s linear infinite;
  }
`;

//
// SolidButton
//

export const solidButtonColorCss = css`
  background: rgba(255 255 255 / 10%);
  color: #ffffff;

  transition:
    background 0.15s ease-out,
    box-shadow 0.15s ease-out;

  &[aria-disabled='true'] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &[data-active='true'] {
    background: rgba(255 255 255 / 20%);
    ${outlineCss()}
    outline-offset: 0;
  }

  &[data-active='false']:focus-visible:focus {
    ${outlineCss()}
  }

  &:hover {
    background: rgba(255 255 255 / 30%);
  }

  &:focus {
    background: rgba(255 255 255 / 15%);
  }

  &[data-active='true']:hover,
  &[data-active='true']:focus {
    background: rgba(255 255 255 / 40%);
  }

  &:focus-visible:focus {
    box-shadow: none;
    ${outlineCss()}
  }
`;

export const SolidButton = styled(ButtonWithSpinner)`
  ${textButtonCss}

  border-radius: 24px;
  padding: 0 30px;
  backdrop-filter: blur(20px);

  ${solidButtonColorCss};
`;

export const DarkSolidButton = styled(SolidButton)`
  background: rgba(0 0 0 / 10%);

  &[data-active='true'] {
    background: rgba(0 0 0 / 15%);
  }

  &:hover {
    background: rgba(0 0 0 / 20%);
  }

  &:focus {
    background: rgba(0 0 0 / 20%);
  }
`;

//
// PseudoRadio & PseudoCheckbox buttons
//

const PseudoButton = styled(SolidButton)`
  position: relative;
  background: rgba(0 0 0 / 10%);
  transition: all 0.15s ease-out;
  padding: 0 44px 0 20px;

  @supports (backdrop-filter: blur(25px)) {
    backdrop-filter: blur(25px);
  }

  &:after {
    content: '';
    position: absolute;
    right: 10px;
    bottom: 0;
    top: 0;
    margin: auto;
    display: flex;
    height: 24px;
    width: 24px;
    opacity: 0.3;
    border: 1px solid #ffffff;
  }

  &:hover,
  &:focus {
    background: rgba(0 0 0 / 20%);
  }

  &[aria-disabled='true']:hover,
  &[aria-disabled='true']:focus {
    background: rgba(0 0 0 / 10%);
  }

  &[aria-checked='true'] {
    color: #222;
    background: rgba(255, 255, 255, 0.7);
    outline: none;
    box-shadow: none;

    &:after {
      opacity: 1;
    }
  }

  &[aria-checked='true']:hover,
  &[aria-checked='true']:focus {
    background: rgba(255, 255, 255, 0.5);
  }
`;

export const PseudoRadio = styled(PseudoButton)`
  &:after {
    content: '';
    border-radius: 50%;
  }

  &:hover,
  &:focus-visible:focus,
  &[aria-checked='true'] {
    &:after {
      height: 8px;
      width: 8px;
      background: #222;
      border: 9px solid #fff;
    }
  }
`;

export const PseudoCheckbox = styled(PseudoButton)`
  &:after {
    content: '';
    border-radius: 9px;
  }

  &:hover:not([aria-disabled='true']),
  &:focus-visible:focus:not([aria-disabled='true']),
  &[aria-checked='true'] {
    &:after {
      background: #fff url('/images/CheckIcon.svg');
      background-size: 8px 8px;
      background-size: auto;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`;

//
// AccentButton
//

export const AccentButton = styled(ButtonWithSpinner)`
  ${textButtonCss}

  border-radius: 24px;
  padding: 0 30px;

  background: linear-gradient(180deg, #ffffff 0%, #d9d9d9 100%);
  color: #5e5e5e;
  box-shadow: 0px 0px 30px 0px rgba(209, 209, 209, 0.9);

  transition:
    box-shadow 0.15s ease-out,
    opacity 0.15s ease-out;

  &:hover {
    box-shadow: 0px 0px 35px 3px rgba(209, 209, 209, 0.9);
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
    box-shadow: none;
    opacity: 0.5;
  }

  &:focus-visible:focus {
    ${outlineCss()}
    box-shadow: 0px 0px 35px 3px rgba(209, 209, 209, 0.9);
  }

  &[aria-disabled='true']:focus-visible:focus {
    box-shadow: none;
  }
`;

//
// LinkButton
//

export const LinkButton = styled(AriaButton)<{ $noUnderline?: boolean }>`
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 2px;
  cursor: pointer;

  background: transparent;
  font-size: inherit;
  text-decoration: ${(p) => (p.$noUnderline ? 'none' : 'underline')};
  color: inherit;

  &:hover {
    text-decoration: underline;
  }

  &.focus-visible:focus,
  &:focus-visible:focus {
    ${outlineCss()}
  }
`;

//
// IconButton
//

export const IconButton = styled(ButtonWithSpinner)`
  margin: 0;
  padding: 0;
  border: 0;

  background: transparent;
  font-size: inherit;
  color: inherit;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  &:focus-visible:focus {
    box-shadow: none;
    ${outlineCss()}
  }
`;

// This name is matching SolidButton for consistency
// TODO: rename it and SolidButton to be in line with design
export const SolidIconButton = styled(IconButton)`
  width: 34px;
  height: 34px;
  border-radius: 14px;

  backdrop-filter: blur(25px);
  color: #fff;

  &[data-active='true'] {
    outline-offset: 0;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 44px;
    height: 44px;
    border-radius: 19px;
  }
`;

export const TranslusentIconButton = styled(SolidIconButton)`
  background: rgb(0 0 0 / 15%);
  transition: background 0.15s ease-out;

  &:hover {
    background: rgb(0 0 0 / 20%);
  }
`;
