import { push } from 'connected-react-router';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components/macro';

import { useAtom, useAtomValue } from 'jotai';
import { updateBot } from '../../../actions/profile';
import { buyUserItems } from '../../../actions/store';
import { CoinIcon, GemIcon } from '../../../components/legacy/WalletIcons';
import { avatarPreviewAtom, basketAtom } from '../../../core/atoms';
import { Routes } from '../../../types/enums';
import { BotPatch, Credit, isStoreVoiceItem } from '../../../types/models';
import {
  DialogButton,
  DialogFooter,
} from '../../DialogLayout/legacy/DialogLayout';

type SumPrice = {
  gems_count: number;
  coins_count: number;
};

function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

function getPriceElement(price: SumPrice) {
  return [
    price.coins_count > 0 ? (
      <React.Fragment key="coin">
        <StyledCoinIcon />
        {price.coins_count}
      </React.Fragment>
    ) : null,
    price.gems_count > 0 ? (
      <React.Fragment key="gem">
        <StyledGemIcon />
        {price.gems_count}
      </React.Fragment>
    ) : null,
  ].filter(nonNullable);
}

function PurchaseDialogFooter({
  sumPrice,
  onError,
  onClose,
}: {
  sumPrice: SumPrice;
  onError: (message: string | null) => void;
  onClose: () => void;
}) {
  const dispatch = useDispatch();
  const [basket, setBasket] = useAtom(basketAtom);
  const avatarV2 = useSelector((state) => state.profile.persist.bot?.avatar_v2);
  const { variations, roomItems } = useAtomValue(avatarPreviewAtom);
  const selectedVariations = variations ?? [];
  const selectedRoomVariations = roomItems ?? [];

  const [purchaseState, setPurchaseState] = React.useState<
    'idle' | 'purchasing'
  >('idle');

  const handleBuyItemClick = async () => {
    onError(null);
    if (basket.length > 0 && purchaseState === 'idle') {
      setPurchaseState('purchasing');
      try {
        const variations = basket
          .map(
            (item) => item.storeItem.variations[item.variationIndex ?? 0]?.id,
          )
          .filter(nonNullable);
        const voiceToBuy = basket
          .map((item) =>
            isStoreVoiceItem(item.storeItem)
              ? item.storeItem.variations[0]?.internal_voice_id
              : null,
          )
          .filter(nonNullable)?.[0];
        const itemsToBuy = variations.map((id) => ({
          id,
          count: 1,
        }));
        if (voiceToBuy) {
          variations.push(voiceToBuy);
        }
        await dispatch(buyUserItems(itemsToBuy));
        setBasket([]);

        let botPatch: BotPatch = {};

        if (avatarV2) {
          botPatch.avatar_v2 = {
            ...avatarV2,
            active_variations: selectedVariations,
          };
        }

        botPatch.room_items = selectedRoomVariations;

        if (voiceToBuy) {
          botPatch.voice_id = voiceToBuy;
        }

        await updateBot(botPatch);

        dispatch(push(Routes.Chat));
        onClose();
      } catch (e) {
        if (e instanceof Error) {
          onError(e.message);
        }
      } finally {
        setPurchaseState('idle');
      }
    }
  };

  return (
    <DialogFooter>
      {sumPrice.coins_count + sumPrice.gems_count > 0 && (
        <DialogButton
          disabled={purchaseState !== 'idle'}
          onClick={handleBuyItemClick}
        >
          Buy for {getPriceElement(sumPrice)}
        </DialogButton>
      )}
    </DialogFooter>
  );
}

function ExtraCoinsNeededFooter({
  currencyNeeded,
  onClose,
}: {
  currencyNeeded: SumPrice;
  onClose: () => void;
}) {
  const price = getPriceElement(currencyNeeded);
  const location = useLocation();

  return (
    <>
      <ExtraNeeded>You need extra {price}</ExtraNeeded>
      <DialogFooter style={{ paddingTop: 20 }}>
        <DialogButton
          onClick={onClose}
          to={{
            pathname: Routes.Wallet,
            state: {
              from: location.pathname + location.search,
              source: 'store',
            },
          }}
        >
          Get more coins and gems
        </DialogButton>
      </DialogFooter>
    </>
  );
}

type Props = {
  credit: null | Credit;
  sumPrice: SumPrice;
  onError: (message: string | null) => void;
  onClose: () => void;
};

function getCurrencyNeeded(price: SumPrice, credit: Credit | null) {
  if (!credit) return null;

  const coinsNeeded = price.coins_count - credit.coins_count;
  const gemsNeeded = price.gems_count - credit.gems_count;

  if (coinsNeeded > 0 || gemsNeeded > 0) {
    return {
      coins_count: Math.max(0, coinsNeeded),
      gems_count: Math.max(0, gemsNeeded),
    } as const;
  }

  return null;
}

function BasketDialogFooter({ credit, sumPrice, onError, onClose }: Props) {
  const currencyNeeded = getCurrencyNeeded(sumPrice, credit);

  if (currencyNeeded) {
    return (
      <ExtraCoinsNeededFooter
        currencyNeeded={currencyNeeded}
        onClose={onClose}
      />
    );
  } else {
    return (
      <PurchaseDialogFooter
        sumPrice={sumPrice}
        onError={onError}
        onClose={onClose}
      />
    );
  }
}

export default BasketDialogFooter;

const StyledGemIcon = styled(GemIcon)`
  width: 12px;
  margin: 0 3px;
`;

const StyledCoinIcon = styled(CoinIcon)`
  width: 12px;
  margin: 0 3px;
`;

const ExtraNeeded = styled.div`
  margin-top: 20px;
  color: ${(p) => p.theme.dimmedFgColor};
`;
