import * as React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as LockIcon } from '../icons/Lock.svg';
import isKeyCombination from '../utils/isKeyCombination';
import mobileMedia from '../utils/mobileMedia';
import useFocusGroup from '../utils/useFocusGroup';
import { DarkSolidButton, SolidButton } from './Buttons';

type Layout = 'horizontal' | 'vertical' | 'horizontal-wrap';

export type Props<T> = {
  value?: T;
  defaultValue?: T;
  onChange: (value: T) => void;
  id: string;
  variants: Array<{ label: React.ReactNode; value: T; locked?: boolean }>;
  className?: string;
  onLockedClick?: (value: T) => void;
  layout?: Layout;
  buttonTheme?: 'outline' | 'solid' | 'square' | 'darkSolid';
  disabled?: boolean;
  autoFocus?: boolean;
};

function isHorizontal(layout: Layout) {
  return layout === 'horizontal' || layout === 'horizontal-wrap';
}

const RadioButtonSelector = <T extends string>(props: Props<T>) => {
  const {
    defaultValue,
    onChange,
    id,
    variants,
    className,
    layout = 'vertical',
    buttonTheme = 'solid',
    disabled,
    autoFocus,
  } = props;
  const [unmanagedValue, setUnmanagedValue] = React.useState(defaultValue);
  const ref = React.useRef<HTMLDivElement>(null);

  const value = props.value ?? unmanagedValue;

  useFocusGroup(
    {
      getGroupElements: () => {
        return ref.current
          ? ref.current.querySelectorAll('[role="radio"]')
          : [];
      },
      options: {
        keybindings: {
          next: { key: isHorizontal(layout) ? 'ArrowRight' : 'ArrowDown' },
          prev: { key: isHorizontal(layout) ? 'ArrowLeft' : 'ArrowUp' },
        },
        wrap: true,
      },
      focusNodeIndex: autoFocus
        ? variants.findIndex((v) => v.value === value) ?? 0
        : undefined,
    },
    [ref],
  );

  const Button =
    buttonTheme === 'outline'
      ? OutlineRadioButton
      : buttonTheme === 'square'
        ? SquareButton
        : buttonTheme === 'darkSolid'
          ? DarkRadioButton
          : RadioButton;

  return (
    <RadioButtonSelectorRoot
      className={className}
      ref={ref}
      role="radiogroup"
      $layout={layout}
      aria-activedescendant={value && `${id}-${value}`}
    >
      {variants.map((variant, idx) => {
        return (
          <Button
            id={`${id}-${variant.value}`}
            key={variant.value}
            role="radio"
            type="button"
            aria-checked={variant.value === value}
            active={variant.value === value}
            disabled={disabled}
            onClick={() => {
              if (disabled) return;
              if (variant.locked) {
                if (props.onLockedClick) {
                  props.onLockedClick(variant.value);
                }
              } else {
                setUnmanagedValue(variant.value);
                onChange(variant.value);
              }
            }}
            onKeyDown={(e) => {
              if (disabled) return;
              if (isKeyCombination(e, ['Enter', 'Space'])) {
                e.preventDefault();
                if (variant.locked) {
                  if (props.onLockedClick) {
                    props.onLockedClick(variant.value);
                  }
                } else {
                  setUnmanagedValue(variant.value);
                  onChange(variant.value);
                }
              }
            }}
            tabIndex={variant.value === value || (!value && idx === 0) ? 0 : -1}
          >
            {variant.locked && <Lock />}
            {variant.label}
          </Button>
        );
      })}
    </RadioButtonSelectorRoot>
  );
};

export default RadioButtonSelector;

const RadioButtonSelectorRoot = styled.div<{
  $layout: Layout;
}>`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: ${(p) => (isHorizontal(p.$layout) ? 'row' : 'column')};
  flex-wrap: ${(p) => (p.$layout === 'horizontal-wrap' ? 'wrap' : 'nowrap')};
  justify-content: ${(p) =>
    p.$layout === 'horizontal-wrap' ? 'center' : 'space-around'};
  gap: ${(p) => (isHorizontal(p.$layout) ? '8px' : '10px')};

  & > * {
    flex: ${(p) => (p.$layout === 'horizontal-wrap' ? '1 0 0' : '0 0 auto')};
  }

  ${mobileMedia`
    gap: ${(p) => (isHorizontal(p.$layout) ? '4px' : '10px')};;
  `}
`;

export const RadioButton = styled(SolidButton)`
  flex: 1 0 auto;
  position: relative;
  padding: 0 20px;

  &:focus {
    outline: none;
  }

  &.focus-visible:focus,
  &:focus-visible:focus {
    box-shadow: 0 0 2px 2px ${(p) => p.theme.outlineColor};
  }
`;

export const DarkRadioButton = styled(DarkSolidButton)`
  flex: 1 0 auto;
  position: relative;
  padding: 0 20px;

  &:focus {
    outline: none;
  }

  &.focus-visible:focus,
  &:focus-visible:focus {
    box-shadow: 0 0 2px 2px ${(p) => p.theme.outlineColor};
  }
`;

const OutlineRadioButton = styled(RadioButton)`
  opacity: ${(p) => (p.active ? 1 : 0.5)};
  border: 1px solid
    ${(p) =>
      p.active
        ? p.disabled
          ? p.theme.dimmedFgColor
          : p.theme.fgColor
        : 'transparent'};
  background: transparent;
  color: ${(p) => (p.disabled ? p.theme.dimmedFgColor : p.theme.fgColor)};
  padding: 0 20px;

  &:hover {
    border-color: ${(p) => p.theme.fgColor};
    opacity: 1;
  }
`;

const SquareButton = styled(RadioButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  opacity: ${(p) => (p.active ? 1 : 0.5)};
  color: ${(p) => p.theme.fgColor};
  border-radius: 25px;
  margin: 0 4px;
  padding: 0 20px;
  font-size: 24px;
  line-height: 30px;
  background: ${(p) => p.theme.cardGridItemBgColor};
  border: ${(p) =>
    p.active ? `2px solid ${p.theme.fgColor}` : `1px solid ${p.theme.fgColor}`};

  &:hover {
    border-color: ${(p) => p.theme.fgColor};
    opacity: 1;
  }
`;

const Lock = styled(LockIcon)`
  position: absolute;
  left: 24px;
  top: 13px;
  color: ${(p) => p.theme.fgColor};
  opacity: 0.4;
`;
