import * as A from '../types/actions';
import { ToError, ToRequest, ToSuccess } from '../types/asyncActions';
import { AsyncActionTypes as AAT, ActionTypes as AT } from '../types/enums';
import { StoreItem } from '../types/models';
import { Reducer } from '../types/redux';
import { Progress, StoreState } from '../types/states';
import applyReducer from '../utils/applyReducer';

const DEFAULT_STATE: StoreState = {
  status: null,
  storeItemsMap: {},
  getGiftsProgress: Progress.initial,
  storeCategories: {},
  storeItems: {},
  storefront: null,
  persist: {
    version: 5,
    credit: null,
    gemConversions: [],
    gemPurchases: [],
    categoryTree: null,
    storeUserItems: {},
    loginReward: null,
    advancedAiBoostPack: null,
    gifts: [],
  },
};

type R<X extends A.StoreAction | A.IncomingWsAction> = Reducer<StoreState, X>;
type RRequest<X> = Reducer<StoreState, ToRequest<X>>;
type RError<X> = Reducer<StoreState, ToError<X>>;
type RSuccess<X> = Reducer<StoreState, ToSuccess<X>>;

const resetState: R<any> = (state) => ({
  ...DEFAULT_STATE,
});

const updateWalletByRequest: RSuccess<A.WalletRequest> = (
  state,
  { result },
) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      credit: result.credit,
    },
  };
};

const updateWallet: R<A.WalletReceived> = (state, { credit }) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      credit,
    },
  };
};

const setGemConversions: RSuccess<A.GetGemConversions> = (
  state,
  { result: gemConversions },
) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      gemConversions,
    },
  };
};

const setGemPurchases: RSuccess<A.GetGemPurchases> = (
  state,
  { result: gemPurchases },
) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      gemPurchases,
    },
  };
};

const convertGemsSuccess: RSuccess<A.ConvertGems> = (
  state,
  { result: { credit } },
) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      credit,
    },
  };
};

const setCategoryTreeNode: RSuccess<A.GetCategoryTreeNode> = (
  state,
  { result, params: { categoryKey } },
) => {
  return {
    ...state,
    storeCategories: {
      ...state.storeCategories,
      [categoryKey]: result,
    },
  };
};

const setCategoryStoreItems: RSuccess<A.GetStoreItems> = (
  state,
  { result, params: { categoryId, offset } },
) => {
  let storeItemsMap = result.items.reduce(
    (acc, item) => {
      acc[item.id] = item;
      return acc;
    },
    { ...state.storeItemsMap },
  );

  return offset
    ? state
    : {
        ...state,
        storeItemsMap,
        storeItems: {
          ...state.storeItems,
          [categoryId]: result.items,
        },
      };
};

const setStoreItems: RSuccess<A.GetStoreItemsByIds> = (
  state,
  { result, params: { ids } },
) => {
  let storeItemsMap = result.reduce(
    (acc, item) => {
      acc[item.id] = item;
      return acc;
    },
    { ...state.storeItemsMap },
  );

  return {
    ...state,
    storeItemsMap,
  };
};

const updateStoreItems = (state: StoreState, items: StoreItem[]) => {
  let storeItems = { ...state.storeItems };
  let storeItemsMap = { ...state.storeItemsMap };

  for (const item of items) {
    storeItemsMap[item.id] = item;
    for (const [key, items] of Object.entries(storeItems)) {
      const index = items.findIndex((i) => i.id === item.id);
      if (index !== -1) {
        if (storeItems[key] === state.storeItems[key]) {
          storeItems[key] = [...storeItems[key]!];
        }
        storeItems[key]![index] = item;
      }
    }
  }

  return {
    ...state,
    storeItemsMap,
    storeItems,
  };
};

const buyUserItemsSuccess: RSuccess<A.BuyUserItems> = (state, { result }) => {
  return updateStoreItems(state, result);
};

const switchUserItemSuccess: RSuccess<A.SwitchUserItem> = (
  state,
  { result },
) => {
  return updateStoreItems(state, result);
};

const getLoginRewardSuccess: RSuccess<A.GetLoginReward> = (
  state,
  { result, status },
) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      loginReward: result,
    },
    status,
  };
};

const claimLoginRewardSuccess: RSuccess<A.ClaimDailyReward> = (
  state,
  { result },
) => {
  state = {
    ...state,
    persist: {
      ...state.persist,
      ...(state.persist.loginReward
        ? {
            loginReward: {
              ...state.persist.loginReward,
              claim_status: 'Claimed',
            },
          }
        : {}),
    },
  };

  if (result.item_earned) {
    state = updateStoreItems(state, [result.item_earned.store_item]);
  }

  return state;
};

const getStorefrontSuccess: RSuccess<A.GetStorefront> = (state, { result }) => {
  return {
    ...state,
    storefront: result,
  };
};

const getAdvancedAiInfoSuccess: RSuccess<A.GetAdvancedAiInfo> = (
  state,
  { result },
) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      advancedAiBoostPack: result.is_unlimited ? null : result.boost_pack,
    },
  };
};

const getGiftsRequest: RRequest<A.GetGifts> = (state) => ({
  ...state,
  getGiftsProgress: Progress.sending,
});

const getGiftsSuccess: RSuccess<A.GetGifts> = (state, { result }) => {
  return {
    ...state,
    getGiftsProgress: Progress.success,
    persist: {
      ...state.persist,
      gifts: result.gifts,
    },
  };
};
const getGiftsError: RError<A.GetGifts> = (state) => ({
  ...state,
  getGiftsProgress: Progress.error,
});

export default function store(
  state: StoreState = DEFAULT_STATE,
  action: A.AnyAction,
) {
  return applyReducer(
    'store',
    {
      [AAT.Logout]: {
        success: resetState,
        error: resetState,
      },
      [AAT.DeleteAccount]: {
        success: resetState,
      },
      [AAT.WsWalletRequest]: {
        success: updateWalletByRequest,
      },
      [AT.WsWalletReceived]: updateWallet,
      [AAT.GetGemConversions]: {
        success: setGemConversions,
      },
      [AAT.GetGemPurchases]: {
        success: setGemPurchases,
      },
      [AAT.ConvertGems]: {
        success: convertGemsSuccess,
      },
      [AAT.GetCategoryTreeNode]: {
        success: setCategoryTreeNode,
      },
      [AAT.GetStoreItems]: {
        success: setCategoryStoreItems,
      },
      [AAT.GetStoreItemsByIds]: {
        success: setStoreItems,
      },
      [AAT.BuyUserItems]: {
        success: buyUserItemsSuccess,
      },
      [AAT.SwitchUserItem]: {
        success: switchUserItemSuccess,
      },
      [AAT.GetLoginReward]: {
        success: getLoginRewardSuccess,
      },
      [AAT.ClaimDailyReward]: {
        success: claimLoginRewardSuccess,
      },
      [AAT.GetStorefront]: {
        success: getStorefrontSuccess,
      },
      [AAT.GetAdvancedAiInfo]: {
        success: getAdvancedAiInfoSuccess,
      },
      [AAT.GetGifts]: {
        request: getGiftsRequest,
        success: getGiftsSuccess,
        error: getGiftsError,
      },
    },
    state,
    action,
  );
}
