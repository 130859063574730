import { useAtom } from 'jotai';
import { useContext, useMemo, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { updateBot } from '../../actions/profile';
import { basketAtom } from '../../core/atoms';
import StoreCameraSlotContext from '../../routes/Store/StoreCameraSlotContext';
import {
  CameraSlot,
  StoreVoiceCustomizationItem,
  isStoreVoiceItem,
} from '../../types/models';
import { BasketItem } from '../../types/states';
import { CardGridItemLink } from '../CardGrid';
import { PriceBadge } from '../Cards/StoreItemCard';
import { CustomizationGridItem } from '../CustomizationGrid';
import { useMobileQuery } from '../responsive';
import {
  StoreCategoryGridSectionHeader,
  StyledCustomizationGrid,
  getGridHeight,
  getGridItemWidth,
} from './StoreCategoryGridSection';

const ChangeVoiceGrid = ({
  index,
  items,
  id,
  onVisible,
  title,
  onSelectItem,
  cameraSlot = 'default_store',
  voiceId,
}: {
  id: string;
  voiceId: string;
  title?: string;
  index: number;
  items: StoreVoiceCustomizationItem[];
  cameraSlot: string | undefined;
  onVisible: (index: number, visible: boolean) => void;
  onSelectItem: (item: StoreVoiceCustomizationItem) => void;
}) => {
  const isMobile = useMobileQuery();
  const { ref, inView } = useInView({
    onChange(inView) {
      onVisible?.(index, inView);
    },
  });
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const setAvatarSettings = useContext(StoreCameraSlotContext);
  const [basket, setBasket] = useAtom(basketAtom);

  const itemHeight = isMobile ? 105 : 155;
  const gridHeight = getGridHeight({
    count: items.length || 0,
    itemSize: itemHeight,
  });
  const itemWidth = getGridItemWidth(isMobile);

  const handleVoiceChange = (item: StoreVoiceCustomizationItem) => {
    const { internal_voice_id = '' } = item.variations[0] || {};
    setAvatarSettings({
      cameraSlot: (isMobile
        ? cameraSlot
        : 'desktop_' + cameraSlot) as CameraSlot,
    });
    const price = item.price.amount;
    const isBought = !!item.variations[0]?.bought_count;

    if (!price || isBought) {
      dispatch(
        updateBot({
          voice_id: internal_voice_id,
        }),
      );
    }

    const [voiceInBasket] =
      basket.filter(({ storeItem }) => isStoreVoiceItem(storeItem)) || [];

    if ((!voiceInBasket && !price) || voiceInBasket?.storeItem.id === item.id) {
      return;
    }

    let newBasket: BasketItem[] = basket.slice();

    if (voiceInBasket) {
      newBasket = basket.filter(
        ({ storeItem }) => !isStoreVoiceItem(storeItem),
      );
    }

    if (price && !isBought) {
      newBasket.push({ storeItem: item });
    }

    setBasket(newBasket);

    return;
  };
  const grid = useMemo(
    () =>
      items.map((item) => {
        const variation = item.variations[0];
        const selected = variation?.internal_voice_id === voiceId;
        return (
          <StyledCustomizationGridItem
            checked={selected}
            size={itemWidth}
            key={item.id}
            onClick={() => {
              handleVoiceChange(item);
              onSelectItem(item);
            }}
            backgroundImageUrl={item.preview_url}
            name={item.title}
          >
            {!!item.price.amount && (
              <PriceBadge
                itemBought={!!variation?.bought_count}
                price={item.price}
              />
            )}
          </StyledCustomizationGridItem>
        );
      }),
    // eslint-disable-next-line local-rules/exhaustive-deps
    [itemWidth, items, onSelectItem, voiceId, inView],
  );

  return (
    <div
      ref={(el) => {
        ref(el);
        sectionRef.current = el;
      }}
      id={id}
      style={{ height: 'auto', position: 'relative' }}
    >
      <StoreCategoryGridSectionHeader>{title}</StoreCategoryGridSectionHeader>
      <StyledCustomizationGrid
        minItemHeight={itemHeight}
        minItemWidth={itemWidth}
        effectInputs={[items]}
      >
        {inView ? grid : <div style={{ height: gridHeight }} />}
      </StyledCustomizationGrid>
      <div />
    </div>
  );
};

export default ChangeVoiceGrid;

const StyledCustomizationGridItem = styled(CustomizationGridItem)`
  height: 100%;

  & > img {
    width: calc(100% - 16px);
    left: 50%;
    transform: translateX(-50%);
    height: auto;
    bottom: 0;
    top: initial;
  }

  ${CardGridItemLink} {
    width: 100%;
  }
`;
