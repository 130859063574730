import { useCallback, useMemo } from 'react';
import { getCategoryTreeNode } from '../../actions/store';
import useApi from '../../utils/useApi';

export function useStoreCategories() {
  const getCustomizationCategory = useCallback(
    () => getCategoryTreeNode('customization'),
    [],
  );
  const getDialogCategory = useCallback(
    () => getCategoryTreeNode('dialog'),
    [],
  );
  const getRoomCategory = useCallback(() => getCategoryTreeNode('room'), []);

  const customization = useApi(
    (state) => state.store.storeCategories.customization ?? null,
    getCustomizationCategory,
  );
  const dialog = useApi(
    (state) => state.store.storeCategories.dialog ?? null,
    getDialogCategory,
  );
  const room = useApi(
    (state) => state.store.storeCategories.room ?? null,
    getRoomCategory,
  );

  return useMemo(
    () => ({ customization, dialog, room }),
    [customization, dialog, room],
  );
}

export type StoreCategories = ReturnType<typeof useStoreCategories>;
