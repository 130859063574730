import * as A from '../types/actions';
import { AsyncActionTypes as AAT, ActionTypes as AT } from '../types/enums';
import {
  Coupon,
  CustomizedSubscriptionParams,
  PayPalConfig,
  StripeConfig,
  StripeInvoice,
  StripeSubscriptionStatus,
  SubscriptionResponse,
} from '../types/models';
import { DA } from '../types/redux';
import { SubscriptionEventData } from '../types/states';
import { apiAction } from '../utils/asyncAction';
import { isEnvironment } from '../utils/environment';
import fetchOptions from '../utils/fetchOptions';
import { getSessionSetting } from '../utils/session';
import { isSubscriptionActive } from '../utils/subscription';
import { API_BASE_URL } from '../utils/uri';
import { updateUserProfile } from './profile';
import { setShowRelationshipStatusButton } from './ui';

export const getSubscriptions =
  (): DA<SubscriptionResponse> => async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetSubscriptions>(
      AAT.GetSubscriptions,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/payment_subscriptions`, fetchOpts),
        onSuccess: () => {
          const state = getState();
          const hasSubscription = isSubscriptionActive(
            state.subscriptions.persist.subscription,
          );
          const relationshipStatusToUpdate =
            state.profile.relationshipStatusToUpdate;

          if (hasSubscription && relationshipStatusToUpdate) {
            updateUserProfile({
              relationship_status: relationshipStatusToUpdate,
            })(dispatch, getState);

            dispatch(setShowRelationshipStatusButton(false));
          }
        },
      },
    );
  };

export const setReboardingDialogShown = () => {
  return {
    type: AT.SetReboardingDialogShown,
  };
};

export const setSaleScreenShown = (id: string) => {
  return {
    type: AT.SaleScreenShown,
    id,
  };
};

export const getStripeConfig =
  (): DA<StripeConfig> => async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetStripeConfig>(
      AAT.GetStripeConfig,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/payment_subscriptions/stripe/config`,
            fetchOpts,
          ),
      },
    );
  };

export const getPayPalConfig =
  (): DA<PayPalConfig> => async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetPayPalConfig>(
      AAT.GetPayPalConfig,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/payment_subscriptions/paypal/config`,
            fetchOpts,
          ),
      },
    );
  };

export const createSubscription =
  (options: {
    paymentMethodId: string;
    priceId: string;
    fbEventId: string;
    couponId?: string;
  }): DA<StripeSubscriptionStatus> =>
  async (dispatch, getState) => {
    const params = {
      price_id: options.priceId,
      payment_method_id: options.paymentMethodId,
      advertising_ids: {
        event_id: options.fbEventId,
        event_source_url: window.location.href,
      },
      coupon_id: options.couponId,
    };
    const fetchOpts = fetchOptions(getState(), 'PUT', params);

    return apiAction<A.CreateStripeSubscription>(
      AAT.CreateStripeSubscription,
      dispatch,
      options,
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/payment_subscriptions/stripe/subscription`,
            fetchOpts,
          ),
      },
    );
  };

export const deleteSubscription =
  (password: string): DA<{}> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(
      getState(),
      'DELETE',
      password.length > 0 ? { password } : {},
    );

    return apiAction<A.DeleteStripeSubscription>(
      AAT.DeleteStripeSubscription,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/payment_subscriptions`, fetchOpts),
      },
    );
  };

export const retryInvoice =
  (options: {
    paymentMethodId: string;
    latestInvoiceId: string;
  }): DA<StripeInvoice> =>
  async (dispatch, getState) => {
    const params = {
      payment_method_id: options.paymentMethodId,
      invoice_id: options.latestInvoiceId,
    };
    const fetchOpts = fetchOptions(getState(), 'PUT', params);

    return apiAction<A.RetryStripeInvoice>(
      AAT.RetryStripeInvoice,
      dispatch,
      options,
      {
        onRequest: () =>
          fetch(
            `${API_BASE_URL}/payment_subscriptions/stripe/retry-invoice`,
            fetchOpts,
          ),
      },
    );
  };

export const getPrices =
  (): DA<CustomizedSubscriptionParams> => async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'GET');

    const num = getSessionSetting('price_num');

    return apiAction<A.GetStripePrices>(
      AAT.GetStripePrices,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(
            isEnvironment('production') || !num
              ? `${API_BASE_URL}/payment_subscriptions/customized_subscriptions_screen`
              : `${API_BASE_URL}/payment_subscriptions/customized_subscriptions_screen?num=${num}`,
            fetchOpts,
          ),
      },
    );
  };

export const getCoupons = (): DA<Coupon[]> => async (dispatch, getState) => {
  const fetchOpts = fetchOptions(getState(), 'GET');

  return apiAction<A.GetStripeCoupons>(
    AAT.GetStripeCoupons,
    dispatch,
    {},
    {
      onRequest: () =>
        fetch(
          `${API_BASE_URL}/payment_subscriptions/stripe/coupons`,
          fetchOpts,
        ),
    },
  );
};

export const setLatestInvoiceId = (invoiceId: string | null) => {
  return {
    type: AT.SetStripeLatestInvoiceId,
    invoiceId,
  };
};

export const setStripeEnabled = () => {
  return {
    type: AT.SetStripeEnabled,
  };
};

export const setSubscriptionEventData = (
  subscriptionEventData: SubscriptionEventData | null,
) => {
  return {
    type: AT.SetSubscriptionEventData,
    subscriptionEventData,
  };
};

export const sendPayPalPurchaseData =
  (options: {
    id: string; // orderId | subscriptionId
    productId: string;
    fbEventId?: string;
  }): DA<{}> =>
  async (dispatch, getState) => {
    const params = {
      token: options.id,
      product_id: options.productId,
      advertising_ids: {
        event_id: options.fbEventId,
        event_source_url: window.location.href,
      },
    };
    const fetchOpts = fetchOptions(getState(), 'POST', params);

    return apiAction<A.SendPayPalPurchaseData>(
      AAT.SendPayPalPurchaseData,
      dispatch,
      {},
      {
        onRequest: () =>
          fetch(`${API_BASE_URL}/monetization/purchase`, fetchOpts),
      },
    );
  };
