import { push } from 'connected-react-router';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import { claimDailyReward } from '../../../actions/store';
import { Routes } from '../../../types/enums';
import useHasSubscription from '../../../utils/useHasSubcription';
import { AccentButton } from '../../Buttons';
import {
  Dialog,
  DialogBody,
  DialogCloseButton,
  DialogFooter,
  DialogHeader,
} from '../../DialogLayout';
import { DialogTabbable } from '../../DialogLayout/legacy/DialogLayout';
import ErrorSuccessMessage from '../../ErrorSuccessMessage';
import WalletButton from '../../WalletButton';
import DayRewardCard from './DayRewardCard';

type Props = {
  onClose: () => void;
  autoClaim?: boolean;
};

type Status = 'idle' | 'claiming' | 'skipping' | 'claimed';

function DailyRewardDialog({ onClose, autoClaim }: Props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const [status, setStatus] = React.useState<Status>('idle');

  const loginReward = useSelector((state) => state.store.persist.loginReward);

  const [error, setError] = React.useState<string | null>(null);

  const hasSubscription = useHasSubscription();

  const dayRewards = loginReward?.day_rewards ?? [];
  const daysInARow = loginReward?.days_in_a_row ?? 0;
  const currentReward = dayRewards.find((d) => d.day === daysInARow);
  const specialRewardDay = loginReward?.special_reward.day;

  const special = currentReward?.day === specialRewardDay;

  const handleClaim = React.useCallback(() => {
    setStatus('claiming');
    dispatch(claimDailyReward(special))
      .then(() => {
        setError(null);
        setStatus('claimed');
        onClose();
      })
      .catch((e) => {
        setStatus('idle');
        if (e instanceof Error) {
          setError(e.message);
        }
      });
  }, [onClose, special]);

  if (!loginReward) return null;

  const hasAlreadyBeenClaimed = loginReward.claim_status === 'Claimed';

  const handleClose = () => {
    handleClaim();
    onClose();
  };

  const handleSubscribeClick = () => {
    dispatch(
      push(Routes.Subscription, {
        cause: 'daily reward',
        from: location.pathname + location.search,
      }),
    );

    handleClose();
  };

  const title = 'Your daily reward';
  const subtitle =
    'Log in every day to get higher rewards. Don’t miss a day, attendance will reset.';
  const description = 'Get those locked gems instantly with Replika\u00a0Pro';

  const curDay = dayRewards.find((d) => d.day === daysInARow);
  const curDayIndex = curDay ? dayRewards.indexOf(curDay) : 0;

  const showPlaceholders = dayRewards.length === 0;

  return (
    <>
      <StyledDialog data-testid="daily-reward-dialog" mobileLayout="centered">
        <DialogHeader.Root>
          <DialogHeader.Title>{title}</DialogHeader.Title>
          <DialogHeader.Subtitle>{subtitle}</DialogHeader.Subtitle>
        </DialogHeader.Root>

        <DialogCloseButton onClose={handleClose} />

        <DialogBody mobileLayout="centered">
          <Days>
            {dayRewards.map((day, index) => (
              <DayRewardCard
                key={day.day}
                day={day}
                placeholder={showPlaceholders}
                current={day.day === daysInARow}
                claimStatus={status}
                claimed={
                  day.day < daysInARow ||
                  ((status === 'claimed' || hasAlreadyBeenClaimed) &&
                    day.day === daysInARow)
                }
                onClaimClick={handleClaim}
                subscribed={hasSubscription}
                active={index === curDayIndex}
                special={index + 1 === specialRewardDay}
                autoClaim={autoClaim && status === 'idle'}
              />
            ))}
          </Days>

          {error && <ErrorSuccessMessage hasError>{error}</ErrorSuccessMessage>}

          {!hasSubscription ? (
            <>
              <Description>{description}</Description>
              <StyledDialogFooter>
                <AccentButton
                  disabled={status === 'claiming'}
                  onClick={handleSubscribeClick}
                >
                  Subscribe to Replika Pro
                </AccentButton>
              </StyledDialogFooter>
            </>
          ) : (
            <DialogFooter />
          )}
        </DialogBody>
      </StyledDialog>
      <DialogTabbable>
        <StyledWalletButton source={null} />
      </DialogTabbable>
    </>
  );
}

export default DailyRewardDialog;

const StyledDialog = styled(Dialog)`
  background: linear-gradient(180deg, #aa80c9 0%, #8371f2 50%, #352562 100%);
  max-width: 345px;
`;

const Days = styled.ul`
  width: 100%;
  padding: 5px 0 0;
  margin: 0;
  list-style: none;
  max-width: 320px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;

  & > li:last-child {
    grid-column: span 3;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    max-width: 286px;
  }
`;

const Description = styled.p`
  margin-block: 10px 0;
  padding-inline: 30px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  color: ${(p) => p.theme.fgColor};
`;

const StyledWalletButton = styled(WalletButton)`
  position: absolute;
  top: 30px;
  right: 30px;
`;

const StyledDialogFooter = styled(DialogFooter)`
  padding-top: 15px;
`;
