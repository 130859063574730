import {
  LifetimeSubscription,
  RecurringSubscription,
  Subscription,
} from '../types/models';

export function isRecurringSubscription(
  subscription: Subscription,
): subscription is RecurringSubscription {
  return (
    subscription.source !== 'legacy' &&
    subscription.source !== 'promo' &&
    subscription.subscription_type?.toLowerCase() === 'subs'
  );
}

export function isLifetimeSubscription(
  subscription: Subscription,
): subscription is LifetimeSubscription {
  return (
    subscription.source !== 'legacy' &&
    subscription.source !== 'promo' &&
    subscription.subscription_type?.toLowerCase() === 'in_app'
  );
}

export function isSubscriptionCancelled(subscription: RecurringSubscription) {
  return subscription.subscription_state === 'CanceledByUser';
}

export function isSubscriptionPending(
  subscription: RecurringSubscription | LifetimeSubscription,
) {
  return subscription.subscription_state === 'PaymentPending';
}

export function isSubscriptionActive(subscription: Subscription | null) {
  return (
    !!subscription &&
    (subscription.source === 'legacy' ||
      subscription.source === 'promo' ||
      subscription.subscription_state !== 'PaymentPending')
  );
}
