import * as Select from '@radix-ui/react-select';
import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as ArrowIcon } from '../../icons/ArrowSmall.svg';
import { ReactComponent as CheckIcon } from '../../icons/Check.svg';
import { errorOutlineCss } from '../outlineCss';
import { DateSelectViewProps } from './models';

const RADIX_SELECT_DROPDOWN_PROPS = {
  position: 'popper',
  side: 'bottom',
  sideOffset: 10,
} as const;

const GAP = 5;

export const LAYOUTS = {
  horizontal: {
    month: { flex: '1' },
    day: { flex: '1' },
    year: { flex: '1' },
  },
  vertical: {
    month: { flex: `1 1 calc(50% - ${GAP / 2}px)` },
    day: { flex: `1 1 calc(50% - ${GAP / 2}px)` },
    year: { flex: '1 1 100%' },
  },
};

export const DateSelectRadixView = ({
  id,
  className,
  date,
  invalid,
  onDateChange,
  options,
  layout,
  arrowIcon = <StyledArrowIcon />,
  monthPlaceholder,
  dayPlaceholder,
  yearPlaceholder,
}: DateSelectViewProps) => {
  return (
    <DateSelectRadixRoot id={id} className={className} $layout={layout}>
      <Select.Root
        value={date.month}
        onValueChange={(value: string) => onDateChange('month', value)}
      >
        <DateSelectTriggerWrapper style={LAYOUTS[layout].month}>
          <DateSelectTrigger aria-invalid={invalid} aria-label="Month">
            <Select.Value placeholder={monthPlaceholder ?? 'Month'} />
          </DateSelectTrigger>

          <DateSelectTriggerLabel>Month</DateSelectTriggerLabel>
          <DateSelectTriggerIcon>{arrowIcon}</DateSelectTriggerIcon>
        </DateSelectTriggerWrapper>

        <Select.Portal>
          <DateSelectDropdown {...RADIX_SELECT_DROPDOWN_PROPS}>
            <DateSelectViewport>
              {options.monthOption.map(({ label, value }) => (
                <SelectItem key={value} value={value}>
                  {label}
                </SelectItem>
              ))}
            </DateSelectViewport>
          </DateSelectDropdown>
        </Select.Portal>
      </Select.Root>

      <Select.Root
        value={date.day}
        onValueChange={(value: string) => onDateChange('day', value)}
      >
        <DateSelectTriggerWrapper style={LAYOUTS[layout].day}>
          <DateSelectTrigger aria-invalid={invalid} aria-label="Day">
            <Select.Value placeholder={dayPlaceholder ?? 'Day'} />
          </DateSelectTrigger>

          <DateSelectTriggerLabel>Day</DateSelectTriggerLabel>
          <DateSelectTriggerIcon>{arrowIcon}</DateSelectTriggerIcon>
        </DateSelectTriggerWrapper>

        <Select.Portal>
          <DateSelectDropdown {...RADIX_SELECT_DROPDOWN_PROPS}>
            <DateSelectViewport>
              {options.dayOptions.map(({ label, value }) => (
                <SelectItem key={value} value={value}>
                  {label}
                </SelectItem>
              ))}
            </DateSelectViewport>
          </DateSelectDropdown>
        </Select.Portal>
      </Select.Root>

      <Select.Root
        value={date.year}
        onValueChange={(value: string) => onDateChange('year', value)}
      >
        <DateSelectTriggerWrapper style={LAYOUTS[layout].year}>
          <DateSelectTrigger aria-invalid={invalid} aria-label="Year">
            <Select.Value placeholder={yearPlaceholder ?? 'Year'} />
          </DateSelectTrigger>

          <DateSelectTriggerLabel>Year</DateSelectTriggerLabel>
          <DateSelectTriggerIcon>{arrowIcon}</DateSelectTriggerIcon>
        </DateSelectTriggerWrapper>

        <Select.Portal>
          <DateSelectDropdown {...RADIX_SELECT_DROPDOWN_PROPS}>
            <DateSelectViewport>
              {options.yearOptions.map(({ label, value }) => (
                <SelectItem key={value} value={value}>
                  {label}
                </SelectItem>
              ))}
            </DateSelectViewport>
          </DateSelectDropdown>
        </Select.Portal>
      </Select.Root>
    </DateSelectRadixRoot>
  );
};

const SelectItem = React.forwardRef<any, any>(
  ({ children, ...props }, forwardedRef) => {
    return (
      <DateSelectDropdownItem {...props} ref={forwardedRef}>
        <Select.ItemText>{children}</Select.ItemText>
        <DateSelectDropdownItemIcon>
          <CheckIcon />
        </DateSelectDropdownItemIcon>
      </DateSelectDropdownItem>
    );
  },
);

const DateSelectRadixRoot = styled.div<{ $layout: 'horizontal' | 'vertical' }>`
  display: flex;
  gap: ${GAP}px;
  justify-content: center;
  align-items: center;
  flex-wrap: ${(p) => (p.$layout === 'vertical' ? 'wrap' : 'nowrap')};
  width: 100%;
  max-width: ${(p) => (p.$layout === 'vertical' ? '100%' : '450px')};
`;

export const DateSelectTriggerWrapper = styled.div.attrs({
  'data-datetriggerwrapper': true,
})`
  height: 64px;
  position: relative;
`;

export const DateSelectTrigger = styled(Select.Trigger)`
  appearance: none;
  background: rgb(0 0 0 / 0.15);
  border: 0;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  height: 100%;
  font-size: 16px;
  font-family: ${(p) => p.theme.fonts.display};
  line-height: 20px;
  width: 100%;
  outline: none;
  padding: 28px 40px 16px 15px;
  text-align: left;
  text-overflow: ellipsis;

  &[data-state='open'] {
    box-shadow: 0 0 2px 2px ${(p) => p.theme.outlineColor};
  }

  &[aria-invalid='true'] {
    ${errorOutlineCss()}
  }
`;

export const DateSelectTriggerLabel = styled.div`
  position: absolute;
  top: 10px;
  left: 15px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.12px;
  opacity: 0.7;
  pointer-events: none;
`;

export const StyledArrowIcon = styled(ArrowIcon)`
  transform: rotate(-90deg);
`;

export const DateSelectTriggerIcon = styled(Select.Icon)`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  pointer-events: none;
  width: 24px;

  [data-state='open'] ~ & ${StyledArrowIcon} {
    transform: rotate(90deg);
  }
`;

const DateSelectDropdown = styled(Select.Content)`
  background: rgb(0 0 0 / 0.08);
  backdrop-filter: blur(25px);
  border: 0;
  border-radius: 24px;
  color: #fff;
  overflow: hidden;
  height: min(370px, var(--radix-select-content-available-height));
  width: var(--radix-select-trigger-width);
`;

const DateSelectViewport = styled(Select.Viewport)`
  padding: 15px 5px;
`;

const DateSelectDropdownItem = styled(Select.Item)`
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-family: ${(p) => p.theme.fonts.display};
  line-height: 20px;
  height: 44px;
  padding: 0 10px;

  &[data-highlighted] {
    background: rgb(255 255 255 / 0.1);
  }
`;

const DateSelectDropdownItemIcon = styled(Select.ItemIndicator)`
  display: flex;
  align-items: center;
  height: 24px;
  width: 24px;

  svg {
    transform: translateX(5px);
  }
`;
