import { useLayoutEffect, useRef } from 'react';

export default function useCallbacksRef<CB extends object>(callbacks: CB) {
  const callbacksRef = useRef(callbacks);

  useLayoutEffect(() => {
    for (const key in callbacks) {
      callbacksRef.current[key] = callbacks[key];
    }
    // eslint-disable-next-line local-rules/exhaustive-deps
  }, Object.values(callbacks));

  return callbacksRef;
}
