import { css } from 'styled-components/macro';

// Safari still does not support rounded outline
// @see https://bugs.webkit.org/show_bug.cgi?id=20807
// @see https://browserstrangeness.bitbucket.io/css_hacks.html#safari
const safariOnly = (strings, ...interpolations) => css`
  @supports (-webkit-hyphens: none) {
    ${css(strings, ...interpolations)}
  }
`;

export default function outlineCss({
  rgb = '255 255 255',
  outlineOpacity = '90%',
  offset = '2px',
  shadowSize = 'medium',
}: {
  rgb?: string;
  outlineOpacity?: string;
  offset?: string;
  shadowSize?: 'medium' | 'small';
} = {}) {
  return css`
    outline: 1px solid rgba(${rgb} / ${outlineOpacity});
    box-shadow:
      0 0 ${shadowSize === 'small' ? '15px' : '30px'} 0 rgba(${rgb} / 30%),
      inset 0 0 15px 0 rgba(${rgb} / 20%);
    outline-offset: ${offset};
    ${safariOnly`
      outline: none;
      box-shadow: 0 0 10px 0 rgba(${rgb} / 30%),
        0 0 0 ${offset} #5C74CC,
        0 0 0 calc(${offset} + 1px) rgba(${rgb} / ${outlineOpacity});
    `}
  `;
}

export function outlineCardCss({
  rgb = '255 255 255',
  outlineOpacity = '50%',
  shadow = '2px',
  offset = '0px',
}: {
  rgb?: string;
  outlineOpacity?: string;
  shadow?: string;
  offset?: string;
} = {}) {
  return css`
    outline: 2px solid rgba(${rgb} / ${outlineOpacity});
    box-shadow:
      0 0 ${shadow} 0 rgb(${rgb}),
      inset 0 0 ${shadow} 0 rgb(${rgb});
    outline-offset: ${offset};

    ${safariOnly`
      outline: none;
      border: 2px solid rgba(${rgb} / ${outlineOpacity});
    `}
  `;
}

export function errorOutlineCss({ offset }: { offset?: string } = {}) {
  return outlineCss({ rgb: '254 158 152', offset });
}
