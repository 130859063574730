import loadable from '@loadable/component';
import * as React from 'react';
import { useLocation } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingPage from './components/LoadingPage';
import AvatarProvider from './core/AvatarProvider';
import Auth from './routes/Auth';
import ModalRoutes from './routes/modals';
import { Routes } from './types/enums';
import { getSessionSetting } from './utils/session';

type Props = {
  hasAvatar: boolean;
};

const loadableOptions = { fallback: <LoadingPage /> };

const MainAppRoutes = loadable(
  () => import(/* webpackChunkName: "App" */ './routes/MainAppRoutes'),
  loadableOptions,
);

const ConfirmEmail = loadable(
  () => import(/* webpackChunkName: "ConfirmEmail" */ './routes/ConfirmEmail'),
  loadableOptions,
);

const DeleteAccount = loadable(
  () =>
    import(/* webpackChunkName: "DeleteAccount" */ './routes/DeleteAccount'),
  loadableOptions,
);

const RecoverPassword = loadable(
  () =>
    import(
      /* webpackChunkName: "RecoverPassword" */ './routes/RecoverPassword'
    ),
  loadableOptions,
);

const VerifyEmail = loadable(
  () => import(/* webpackChunkName: "VerifyEmail" */ './routes/VerifyEmail'),
  loadableOptions,
);

const AvatarReboarding = loadable(
  () =>
    import(
      /* webpackChunkName: "AvatarReboarding" */ './routes/AvatarReboarding'
    ),
  loadableOptions,
);

const Donate = loadable(
  () => import(/* webpackChunkName: "Donate" */ './routes/Donate'),
  loadableOptions,
);

const DonateSuccess = loadable(
  () =>
    import(/* webpackChunkName: "Donate" */ './routes/Donate/DonateSuccess'),
  loadableOptions,
);

const DonateCanceled = loadable(
  () =>
    import(/* webpackChunkName: "Donate" */ './routes/Donate/DonateCanceled'),
  loadableOptions,
);

const ChangeEmail = loadable(
  () => import(/* webpackChunkName: "ChangeEmail" */ './routes/ChangeEmail'),
  loadableOptions,
);

/**
 * Defines routes that should be available in any app state
 */
const RootSwitch = ({ children }: { children: React.ReactNode }) => {
  return (
    <Switch>
      <Route path={Routes.RecoverPassword} component={RecoverPassword} />
      <Route path={Routes.VerifyEmail} component={VerifyEmail} />
      <Route path={Routes.Donate} exact component={Donate} />
      <Route path={Routes.DonateSuccess} component={DonateSuccess} />
      <Route path={Routes.DonateCanceled} component={DonateCanceled} />
      <Route path={Routes.ChangeEmail} component={ChangeEmail} />
      {children}
    </Switch>
  );
};

export function AuthRoutes() {
  const location = useLocation();

  return (
    <RootSwitch>
      <Route
        path={Routes.AuthMain}
        exact
        render={() => <Auth screen="welcome" />}
      />
      <Route path={Routes.Login} render={() => <Auth screen="login" />} />
      <Route path={Routes.Signup} render={() => <Auth screen="signup" />} />
      <Redirect
        from="*"
        to={{
          pathname: Routes.AuthMain,
          state: {
            redirectTo: location.pathname + location.search,
          },
        }}
      />
    </RootSwitch>
  );
}

export function ConfirmEmailRoutes() {
  return (
    <RootSwitch>
      <Route path={Routes.ConfirmSignup} component={ConfirmEmail} />
      <Redirect from="*" to={Routes.ConfirmSignup} />
    </RootSwitch>
  );
}

export default function AppRoutes({ hasAvatar }: Props) {
  const redirectTo = getSessionSetting('redirectTo');

  return (
    <>
      <ModalRoutes />
      <AvatarProvider enabled>
        <RootSwitch>
          {redirectTo?.indexOf('/') === 0 && (
            <Redirect from="*" to={redirectTo} />
          )}
          <Route path={Routes.DeleteAccount} component={DeleteAccount} />
          <Redirect from={Routes.Login} to={Routes.Chat} />
          <Redirect from={Routes.Signup} to={Routes.Chat} />
          {/* redirect from legacy chat page */}
          <Redirect from={'/chat'} to={Routes.Chat} />
          {hasAvatar ? (
            <Redirect from={Routes.ChooseAvatar} to={Routes.Chat} />
          ) : (
            <Route path={Routes.ChooseAvatar} component={AvatarReboarding} />
          )}
          <Route path="*" component={MainAppRoutes} />
        </RootSwitch>
      </AvatarProvider>
    </>
  );
}
