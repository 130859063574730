import * as Popover from '@radix-ui/react-popover';
import {
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { setActiveDialog } from '../actions/ui';
import { ReactComponent as HintIcon } from '../icons/Info.svg';
import { Dialogs } from '../types/enums';
import useCallbacksRef from '../utils/useCallbacksRef';
import { AriaButtonProps, IconButton } from './Buttons';

type HintContent = {
  title?: string;
  imgSrc?: string;
  imgShadow?: string;
  description: React.ReactNode;
  confirmText?: string;
};

type HintButtonProps = {
  hintVisible?: boolean;
  hintContent?: HintContent;
  hintType?: 'dialog' | 'popover';
  hidePopoverTitle?: boolean;
  onHintOpen?: (open: boolean) => void;
  portal?: string;
  side?: Popover.PopoverContentProps['side'];
  align?: Popover.PopoverContentProps['align'];
  sideOffset?: Popover.PopoverContentProps['sideOffset'];
  alignOffset?: Popover.PopoverContentProps['alignOffset'];
  collisionBoundary?: Popover.PopoverContentProps['collisionBoundary'];
  collisionPadding?: Popover.PopoverContentProps['collisionPadding'];
} & AriaButtonProps;

export default function HintButton({
  label,
  hintContent,
  onClick,
  hintVisible,
  hintType = 'dialog',
  collisionBoundary,
  hidePopoverTitle,
  onHintOpen,
  portal,
  side = 'bottom',
  align = 'start',
  sideOffset = 5,
  alignOffset = -15,
  collisionPadding,
  ...rest
}: HintButtonProps) {
  const dispatch = useDispatch();

  const [popoverOpen, setPopoverOpen] = useState(hintVisible);

  const callbacks = useCallbacksRef({ onHintOpen });

  const showHint = useCallback(
    (hintContent: HintContent) => {
      if (hintType === 'popover') {
        setPopoverOpen(true);
        callbacks.current.onHintOpen?.(true);
        return;
      }

      dispatch(
        setActiveDialog({
          type: Dialogs.Hint,
          content: {
            title: hintContent.title ?? '',
            ...hintContent,
          },
        }),
      );
    },
    [hintType],
  );

  useEffect(() => {
    if (hintVisible && hintContent) {
      showHint(hintContent);
    }
  }, [hintVisible, hintContent, showHint]);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (hintType === 'popover') return;

    onClick?.(e);

    if (!hintContent) return;

    showHint(hintContent);
  };

  const ref = useRef<HTMLButtonElement>(null);

  const button = (
    <HintButtonRoot
      label={label ?? 'show hint'}
      onClick={handleClick}
      ref={ref}
      {...rest}
    >
      <HintIcon />
    </HintButtonRoot>
  );

  if (hintType === 'popover') {
    let content = (
      <PopoverContent
        side={side}
        sideOffset={sideOffset}
        align={align}
        alignOffset={alignOffset}
        collisionBoundary={collisionBoundary}
        collisionPadding={collisionPadding}
      >
        <PopoverArrow width={17} height={10} />
        {!hidePopoverTitle && hintContent?.title && (
          <HintTitle>{hintContent.title}</HintTitle>
        )}
        {hintContent?.description}
      </PopoverContent>
    );

    if (portal) {
      let el = document.querySelector(portal);
      if (el instanceof HTMLElement) {
        content = <Popover.Portal container={el}>{content}</Popover.Portal>;
      }
    }

    return (
      <Popover.Root
        open={popoverOpen}
        onOpenChange={(o) => {
          setPopoverOpen(o);
          onHintOpen?.(o);
        }}
      >
        <Popover.Trigger asChild>{button}</Popover.Trigger>
        {content}
      </Popover.Root>
    );
  }

  return button;
}

const HintButtonRoot = styled(IconButton)`
  display: inline-block;
  vertical-align: -5px;
  flex: 0 0 auto;
  height: 20px;
  width: 20px;
  margin-left: 5px;
  border-radius: 50%;
  color: ${(p) => p.theme.fgColor};
  background: rgba(255 255 255 / 10%);
`;

export const HintTitle = styled.h3`
  margin-block: 0 10px;
`;

export const HintSubTitle = styled.h4`
  margin: 30px 0 0;
  font-size: 16px;
  line-height: 20px;
`;

export const HintDescription = styled.p`
  margin-block: 0;
  font-size: 14px;
  line-height: 18px;
`;

const PopoverContent = styled(Popover.Content)`
  color: ${(p) => p.theme.fgColor};
  padding: 20px;
  max-width: 340px;
  border-radius: 14px;
  background: rgb(0 0 0 /20%);
  backdrop-filter: blur(25px);
  z-index: 3;
`;

const PopoverArrow = styled(Popover.Arrow)`
  fill: rgb(0 0 0 /20%);
  backdrop-filter: blur(25px);
`;
