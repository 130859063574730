import guid from 'simple-guid';
import * as A from '../types/actions';
import { ToError, ToRequest, ToSuccess } from '../types/asyncActions';
import { AsyncActionTypes as AAT, ActionTypes as AT } from '../types/enums';
import { Reducer } from '../types/redux';
import { AuthState } from '../types/states';
import applyReducer from '../utils/applyReducer';
import isAuthSuccessResult from '../utils/isAuthSuccessResult';

const DEFAULT_STATE: AuthState = {
  trialPeriodExpired: false,
  sendConfirmationStatus: 'initial',
  sendConfirmationError: undefined,
  deleteAccountStatus: 'initial',
  deleteAccountError: undefined,
  oauthStatus: 'initial',
  oauthError: undefined,
  persist: {
    authToken: undefined,
    userId: undefined,
    deviceId: undefined,
    authType: undefined,
    firebaseToken: undefined,
  },
};

type R<X extends A.AuthAction | A.InitAction> = Reducer<AuthState, X>;
type RRequest<X> = Reducer<AuthState, ToRequest<X>>;
type RError<X> = Reducer<AuthState, ToError<X>>;
type RSuccess<X> = Reducer<AuthState, ToSuccess<X>>;

const checkDeviceId: R<A.CheckDeviceId> = (state) => ({
  ...state,
  persist: {
    ...state.persist,
    deviceId: state.persist.deviceId || guid(),
  },
});

const passwordLoginSuccess: RSuccess<A.PasswordLogin> = (
  state,
  action,
): AuthState => ({
  ...state,
  persist: {
    ...state.persist,
    authToken: action.result.auth_token,
    userId: action.result.user_id,
    authType: 'email',
  },
});

const tokenLoginSuccess: RSuccess<A.TokenLogin> = (
  state,
  action,
): AuthState => ({
  ...state,
  persist: {
    ...state.persist,
    authToken: action.result.auth_token,
    userId: action.result.user_id,
    authType: 'single_use_token',
  },
});

const AuthByOauthSuccess: RSuccess<A.AuthByOauth> = (
  state,
  action,
): AuthState => ({
  ...state,
  oauthStatus: 'initial',
  persist: {
    ...state.persist,
    ...(isAuthSuccessResult(action.result)
      ? {
          authToken: action.result.auth_token,
          userId: action.result.user_id,
          firebaseToken: undefined,
        }
      : {}),
  },
});

const AuthByOauthError: RError<A.AuthByOauth> = (state, action): AuthState => ({
  ...state,
  oauthStatus: 'initial',
});

const resendConfirmationEmail: RRequest<A.ResendConfirmationEmail> = (
  state,
): AuthState => ({
  ...state,
  sendConfirmationStatus: 'sending',
  sendConfirmationError: undefined,
});

const resendConfirmationEmailSuccess: RSuccess<A.ResendConfirmationEmail> = (
  state,
): AuthState => ({
  ...state,
  sendConfirmationStatus: 'sent',
});

const resendConfirmationEmailError: RError<A.ResendConfirmationEmail> = (
  state,
  action,
): AuthState => ({
  ...state,
  sendConfirmationStatus: 'initial',
  sendConfirmationError: action.error.message,
});

const logoutSuccess: RSuccess<A.Logout> = (state) => ({
  ...DEFAULT_STATE,
  persist: {
    ...DEFAULT_STATE.persist,
    deviceId: state.persist.deviceId,
  },
});

const logoutError: RError<A.Logout> = (state) => ({
  ...DEFAULT_STATE,
  persist: {
    ...DEFAULT_STATE.persist,
    deviceId: state.persist.deviceId,
  },
});

const deleteAccountRequest: RRequest<A.DeleteAccount> = (state) => ({
  ...state,
  deleteAccountStatus: 'sending',
  deleteAccountError: undefined,
});

const deleteAccountError: RError<A.DeleteAccount> = (state, action) => ({
  ...state,
  deleteAccountStatus: 'initial',
  deleteAccountError: action.error.message,
});

const deleteAccountSuccess: RSuccess<A.DeleteAccount> = (state) => ({
  ...DEFAULT_STATE,
  persist: {
    ...DEFAULT_STATE.persist,
    deviceId: state.persist.deviceId,
  },
});

const signupSuccess: RSuccess<A.Signup> = (state, action) => ({
  ...state,
  persist: {
    ...state.persist,
    authToken: action.result.auth_token,
    userId: action.result.user_id,
    authType: state.persist.authType ?? 'email',
    firebaseToken: undefined,
  },
});

const initNewUserSuccess: RSuccess<A.InitNewUser> = (state, action) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      authToken: action.result.auth_token,
      userId: action.result.user_id,
    },
  };
};

const setTrialPeriodExpired: R<A.TrialPeriodExpired> = (state) => ({
  ...state,
  trialPeriodExpired: true,
});

const completeSignup: R<A.CompleteSignup> = (state, action) => ({
  ...state,
  persist: {
    ...state.persist,
    authToken: action.authToken,
    userId: action.userId,
  },
});

const OauthSignInRequest: R<A.OauthSignInRequest> = (state) => ({
  ...state,
  oauthStatus: 'submitting',
  oauthError: undefined,
  persist: {
    ...state.persist,
    authType: undefined,
    firebaseToken: undefined,
  },
});

const OauthSignInSuccess: R<A.OauthSignInSuccess> = (state, action) => {
  return {
    ...state,
    oauthStatus: 'success',
    persist: {
      ...state.persist,
      authType: action.authType,
      firebaseToken: action.firebaseToken,
    },
  };
};

const OauthSignInError: R<A.OauthSignInError> = (state, action) => {
  return {
    ...state,
    oauthStatus: 'error',
    oauthError: action.err,
  };
};

// we need to clear authType & firebaseToken if user switches back to email + password
const accountInfoCheckRequest: RRequest<A.CheckAccountInfo> = (
  state,
  action,
) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      authType: action.params.password ? undefined : state.persist.authType,
      firebaseToken: action.params.password
        ? undefined
        : state.persist.firebaseToken,
    },
  };
};

export default function auth(
  state: AuthState = DEFAULT_STATE,
  action: A.AnyAction,
) {
  return applyReducer(
    'auth',
    {
      [AAT.PasswordLogin]: {
        success: passwordLoginSuccess,
      },
      [AAT.TokenLogin]: {
        success: tokenLoginSuccess,
      },
      [AAT.AuthByOauth]: {
        success: AuthByOauthSuccess,
        error: AuthByOauthError,
      },
      [AAT.Logout]: {
        success: logoutSuccess,
        error: logoutError,
      },
      [AT.CheckDeviceId]: checkDeviceId,
      [AAT.ResendConfirmationEmail]: {
        request: resendConfirmationEmail,
        success: resendConfirmationEmailSuccess,
        error: resendConfirmationEmailError,
      },
      [AAT.DeleteAccount]: {
        request: deleteAccountRequest,
        error: deleteAccountError,
        success: deleteAccountSuccess,
      },
      [AAT.Signup]: {
        success: signupSuccess,
      },
      [AAT.PreSignup]: {
        success: signupSuccess,
      },
      [AT.CompleteSignup]: completeSignup,
      [AAT.InitNewUser]: {
        success: initNewUserSuccess,
      },
      [AT.TrialPeriodExpired]: setTrialPeriodExpired,
      [AT.OauthSignInRequest]: OauthSignInRequest,
      [AT.OauthSignInSuccess]: OauthSignInSuccess,
      [AT.OauthSignInError]: OauthSignInError,
      [AAT.CheckAccountInfo]: {
        request: accountInfoCheckRequest,
      },
    },
    state,
    action,
  );
}
