import * as Slider from '@radix-ui/react-slider';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import outlineCss from './outlineCss';

const BaseSliderRoot = styled(Slider.Root)`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;

  transition: opacity 0.15s ease-out;

  &[data-orientation='vertical'] {
    flex-direction: column;
    height: 100%;
  }

  &[data-disabled] {
    opacity: 0.7;
  }
`;

const BaseSliderTrack = styled(Slider.Track)`
  position: relative;
  flex-grow: 1;
`;

const BaseSliderRange = styled(Slider.Range)`
  position: absolute;
  height: 100%;

  &[data-orientation='vertical'] {
    width: 100%;
    height: auto;
  }
`;

const BaseSliderThumb = styled(Slider.Thumb)`
  display: block;
  border-radius: 50%;
  transition:
    box-shadow 0.15s ease-out,
    opacity 0.15s ease-out;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  &:focus-visible:focus {
    ${outlineCss()}
  }

  &[data-disabled],
  &[aria-disabled='true'],
  &[data-disabled]:hover,
  &[aria-disabled='true']:hover {
    cursor: not-allowed;
    box-shadow: none;
  }
`;

//
// LargeSlider
//
export function LargeSlider(props: Slider.SliderProps) {
  return (
    <BaseSliderRoot {...props}>
      <LargeSliderTrack>
        <LargeSliderRange />
      </LargeSliderTrack>
      <LargeSliderThumb />
    </BaseSliderRoot>
  );
}

const LargeSliderTrack = styled(BaseSliderTrack)`
  height: 4px;
  background: rgba(0 0 0 / 10%);
  border-radius: 2px;
  margin: 0 17px;

  &[data-orientation='vertical'] {
    width: 4px;
    margin: 17px auto 17px;
  }
`;

const LargeSliderRange = styled(BaseSliderRange)`
  background: rgba(255 255 255 / 35%);
  border-radius: 2px;
`;

const LargeSliderThumb = styled(BaseSliderThumb)`
  width: 34px;
  height: 34px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 15px 0px rgba(255 255 255 / 90%);

  &:hover,
  &:focus-visible:focus {
    box-shadow: 0px 0px 15px 3px rgba(255 255 255 / 90%);
  }
`;

//
// FormSlider
//
export function FormSlider({
  dots,
  ...rest
}: Slider.SliderProps & { dots?: boolean }) {
  const step = rest.step ?? 1;
  const min = rest.min ?? 0;
  const max = rest.max ?? 100;

  const count = (max - min) / step + 1;

  return (
    <BaseSliderRoot {...rest}>
      <FormSliderTrack>
        <FormSliderRange />
        {dots ? (
          <Dots>
            {Array.from({ length: count }).map((_, idx) => (
              <Dot key={idx} />
            ))}
          </Dots>
        ) : null}
      </FormSliderTrack>
      <FormSliderThumb />
    </BaseSliderRoot>
  );
}

const Dots = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 8px;

  [data-orientation='vertical'] > & {
    flex-direction: column;
    padding-inline: 0;
    padding-block: 8px;
  }
`;

const Dot = styled.div`
  width: 2px;
  height: 2px;
  background: rgba(255 255 255 / 20%);
`;

const FormSliderTrack = styled(BaseSliderTrack)`
  height: 8px;
  background: rgba(0 0 0 / 10%);
  border-radius: 4px;

  &[data-orientation='vertical'] {
    width: 8px;
  }
`;

const FormSliderRange = styled(BaseSliderRange)`
  background: rgba(255 255 255 / 35%);
  border-radius: 4px;
`;

const FormSliderThumb = styled(BaseSliderThumb)`
  width: 22px;
  height: 22px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 15px 0px rgba(255 255 255 / 90%);

  &:hover,
  &:focus-visible:focus {
    box-shadow: 0px 0px 15px 3px rgba(255 255 255 / 90%);
  }
`;

//
// LabeledSlider
//
export function LabeledSlider({
  startLabel,
  endLabel,
  ...rest
}: Slider.SliderProps & { startLabel?: string; endLabel?: string }) {
  const hasLabels =
    rest.orientation !== 'vertical' && (startLabel != null || endLabel != null);

  const [updateLabels, refs, styles] = useUpdateLabels();

  useEffect(() => {
    updateLabels();
  }, [hasLabels, updateLabels]);

  const handleValueChange = (value: number[]) => {
    if (hasLabels) {
      updateLabels();
    }

    rest.onValueChange?.(value);
  };

  const handleValueCommit = () => {
    if (hasLabels) {
      updateLabels();
    }
  };

  return (
    <BaseSliderRoot
      {...rest}
      onValueChange={handleValueChange}
      onValueCommit={handleValueCommit}
    >
      <LabeledSliderTrack>
        {hasLabels ? (
          <LabeledSliderLabels>
            <LabeledSliderLabel style={styles.startLabel} ref={refs.startLabel}>
              {startLabel}
            </LabeledSliderLabel>
            <LabeledSliderLabel style={styles.endLabel} ref={refs.endLabel}>
              {endLabel}
            </LabeledSliderLabel>
          </LabeledSliderLabels>
        ) : null}
      </LabeledSliderTrack>
      <ThumbWrapper>
        <LabeledSliderThumb ref={refs.thumb} />
      </ThumbWrapper>
    </BaseSliderRoot>
  );
}

function useUpdateLabels() {
  const startLabelRef = useRef<HTMLDivElement>(null);
  const endLabelRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);

  const [thumbOverStartLabel, setThumbOverStartLabel] = useState(false);
  const [thumbOverEndLabel, setThumbOverEndLabel] = useState(false);

  const updateLabels = () => {
    if (!startLabelRef.current || !endLabelRef.current || !thumbRef.current) {
      return;
    }

    if (thumbRef.current.style.display === 'none') {
      setThumbOverStartLabel(true);
      setThumbOverEndLabel(false);
      return;
    }

    setThumbOverStartLabel(intersects(thumbRef.current, startLabelRef.current));
    setThumbOverEndLabel(intersects(thumbRef.current, endLabelRef.current));
  };

  useEffect(() => {
    updateLabels();
  }, []);

  return [
    updateLabels,
    {
      startLabel: startLabelRef,
      endLabel: endLabelRef,
      thumb: thumbRef,
    },
    {
      startLabel: { opacity: thumbOverStartLabel ? 0.2 : 1 },
      endLabel: { opacity: thumbOverEndLabel ? 0.2 : 1 },
    },
  ] as const;
}

function intersects(a: HTMLElement, b: HTMLElement) {
  const rectA = a.getBoundingClientRect();
  const rectB = b.getBoundingClientRect();

  return !(
    rectA.top > rectB.bottom ||
    rectA.right < rectB.left ||
    rectA.bottom < rectB.top ||
    rectA.left > rectB.right
  );
}

const ThumbWrapper = styled.div`
  position: absolute;
  left: 5px;
  right: 5px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  [data-orientation='vertical'] > & {
    flex-direction: column;
    left: 0;
    right: 0;
    top: 5px;
    bottom: 5px;
  }
`;

const LabeledSliderTrack = styled(BaseSliderTrack)`
  height: 34px;
  background: rgba(255 255 255 / 35%);
  border-radius: 17px;

  &[data-orientation='vertical'] {
    width: 34px;
  }
`;

const LabeledSliderThumb = styled(BaseSliderThumb)`
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 15px 0px rgba(255 255 255 / 90%);

  &:hover,
  &:focus-visible:focus {
    box-shadow: 0px 0px 15px 3px rgba(255 255 255 / 90%);
  }
`;

const LabeledSliderLabels = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 15px;

  font-family: ${(p) => p.theme.fonts.display};
  font-size: 14px;

  [data-orientation='vertical'] > & {
    flex-direction: column;
    padding-inline: 0;
    padding-block: 15px;
  }
`;

const LabeledSliderLabel = styled.span``;
