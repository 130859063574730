import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import guid from 'simple-guid';
import styled, { withTheme } from 'styled-components/macro';
import { toggleAdvancedAi } from '../../actions/chat';
import { setRelationshipStatusToUpdate } from '../../actions/profile';
import {
  createSubscription,
  getPayPalConfig,
  getSubscriptions,
  sendPayPalPurchaseData,
  setLatestInvoiceId,
  setSubscriptionEventData,
} from '../../actions/subscriptions';
import { ZIndices } from '../../types/enums';
import { SubscriptionListing, SubscriptionUiParams } from '../../types/models';
import { SubsriptionDialogCause } from '../../types/states';
import { Theme } from '../../types/theme';
import getSubscriptionPeriod from '../../utils/getSubscriptionPeriod';
import { dialogMobileMedia } from '../../utils/mobileMedia';
import processStripePayment from '../../utils/processStripePayment';
import retry from '../../utils/retry';
import { TERMS_SUBS_REPLIKA_URL } from '../../utils/uri';
import useApi from '../../utils/useApi';
import useSubscriptionTracking from '../../utils/useSubscriptionTracking';
import AriaStatusText from '../AriaStatusText';
import { AccentButton } from '../Buttons';
import CircleSpinner from '../CircleSpinner';
import { StripeControls, StripeForm } from '../Stripe';
import ActiveSubscription from './ActiveSubscription';
import PayPalPayment from './PayPalPayment';
import PayPalSubscription from './PayPalSubscription';
import PriceList from './PriceList';
import SuccessefulSubscription from './SuccessefulSubscription';

const PAYPAL_PURCHASE_TIMEOUT = 3 * 1000; // 3s;
const PAYPAL_PURCHASE_MAX_TIME = 3 * 60 * 1000; // 3min;

type Props = {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  theme: Theme;
  stripeSubscriptions: SubscriptionListing[] | null;
  uiParams?: SubscriptionUiParams;
  onClose: () => void;
  newRelationshipStatus?: string;
  cause: SubsriptionDialogCause;
  spinnerBgColor?: string;
  className?: string;
  style?: React.CSSProperties;
  header?: React.ReactNode;
  paypalDisabled: boolean;
  onSuccess?: () => void;
};

function SubscriptionContent({
  title,
  subtitle,
  stripeSubscriptions,
  uiParams,
  onClose,
  newRelationshipStatus,
  cause,
  spinnerBgColor,
  className,
  style,
  header,
  paypalDisabled,
  onSuccess,
}: Props) {
  const dispatch = useDispatch();

  const defaultPriceId =
    stripeSubscriptions?.find((s) => s.is_default)?.stripe_subscription_id ??
    null;
  const [priceId, setPriceId] = React.useState<null | string>(defaultPriceId);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [cardComplete, setCardComplete] = React.useState(false);
  const [subscriptionProcessed, setSubscriptionProcessed] =
    React.useState(false);

  React.useEffect(() => {
    setPriceId(defaultPriceId);
  }, [defaultPriceId]);

  const [status, setStatus] = React.useState<
    'init' | 'idle' | 'subscribing' | 'cancelling'
  >('init');

  React.useEffect(() => {
    async function fetchData() {
      await dispatch(getSubscriptions());
      setStatus('idle');
    }

    fetchData();
  }, [dispatch]);

  React.useEffect(() => {
    if (cause === 'advanced ai' && subscriptionProcessed) {
      dispatch(toggleAdvancedAi('active'));
    }
  }, [dispatch, cause, subscriptionProcessed]);

  const subscription = useSelector(
    (state) => state.subscriptions.persist.subscription,
  );
  const userId = useSelector((state) => state.auth.persist.userId);

  const [paypalDisabledOnError, setPaypalDisabledOnError] =
    React.useState(false);

  useSubscriptionTracking({
    cause,
    subscription,
  });

  const paypalConfig = useApi(
    (state) => state.subscriptions.paypalConfig,
    getPayPalConfig,
    { memoDeepEqual: true },
  );
  const paypalClientId = paypalConfig?.client_id;

  const paypalAvailable =
    !paypalDisabled && !paypalDisabledOnError && !!paypalClientId;

  const selectedSubscription = stripeSubscriptions?.find(
    (p) => p.stripe_subscription_id === priceId,
  );

  const paymentRequestOptions = React.useMemo(() => {
    if (!selectedSubscription) return null;

    const periodTitle = selectedSubscription.name;
    return {
      country: 'US',
      currency: selectedSubscription.price.currency.toLowerCase(),
      total: {
        label: `Replika ${periodTitle} subscription`,
        amount: selectedSubscription.price.amount * 100,
      },
    };
  }, [selectedSubscription]);

  const latestInvoiceId = useSelector(
    (state) => state.subscriptions.persist.stripeLatestInvoiceId,
  );

  const handleSuccess = React.useCallback(() => {
    setSubscriptionProcessed(true);
    onSuccess?.();
  }, [setSubscriptionProcessed, onSuccess]);

  const processPayment = React.useCallback(
    async (paymentMethodId, stripe) => {
      const invoiceId = await processStripePayment({
        paymentMethodId,
        stripe,
        latestInvoiceId,
        priceId,
        dispatch,
        onCreate: (priceId) => {
          const eventId = guid();

          if (newRelationshipStatus) {
            dispatch(setRelationshipStatusToUpdate(newRelationshipStatus));
          }

          if (selectedSubscription) {
            dispatch(
              setSubscriptionEventData({
                eventId,
                value: selectedSubscription.price.amount,
                currency: selectedSubscription.price.currency,
                payment_type: 'stripe',
                product_id: priceId,
                period: getSubscriptionPeriod(selectedSubscription.period),
              }),
            );
          }

          return dispatch(
            createSubscription({
              paymentMethodId,
              priceId,
              fbEventId: eventId,
            }),
          );
        },
      });

      dispatch(setLatestInvoiceId(invoiceId));

      if (!invoiceId) {
        dispatch(getSubscriptions());
      }

      handleSuccess();

      return invoiceId;
    },
    [
      latestInvoiceId,
      dispatch,
      priceId,
      selectedSubscription,
      newRelationshipStatus,
      handleSuccess,
    ],
  );

  const dispatchLatestInvoiceId = React.useCallback(
    (lii) => dispatch(setLatestInvoiceId(lii)),
    [dispatch],
  );

  const subscribeInProgress = status === 'subscribing';
  const subscribeDisabled =
    !priceId || status === 'init' || status === 'subscribing' || !cardComplete;

  const processPayPalPurchase = (processPurchase: () => Promise<any>) => {
    setErrorMessage(null);
    setStatus('subscribing');

    if (newRelationshipStatus) {
      dispatch(setRelationshipStatusToUpdate(newRelationshipStatus));
    }

    retry(processPurchase, PAYPAL_PURCHASE_TIMEOUT, PAYPAL_PURCHASE_MAX_TIME)
      .then(() => {
        setStatus('idle');
        setErrorMessage(null);
        dispatch(getSubscriptions());

        handleSuccess();
      })
      .catch((err) => {
        setStatus('idle');

        setErrorMessage(
          (err.message as string) ?? 'Error while sending request',
        );
      });
  };

  const handlePayPalSubscriptionSuccess = async (
    subscriptionId: string,
    planId: string,
  ) => {
    const eventId = guid();

    if (selectedSubscription) {
      dispatch(
        setSubscriptionEventData({
          eventId,
          value: selectedSubscription.price.amount,
          currency: selectedSubscription.price.currency,
          payment_type: 'paypal',
          product_id: planId,
          period: getSubscriptionPeriod(selectedSubscription.period),
        }),
      );
    }

    processPayPalPurchase(async () => {
      return dispatch(
        sendPayPalPurchaseData({
          id: subscriptionId,
          productId: planId,
          fbEventId: eventId,
        }),
      );
    });
  };

  const handlePayPalOrderSuccess = async (
    orderId: string,
    productId: string,
  ) => {
    const eventId = guid();

    if (selectedSubscription) {
      dispatch(
        setSubscriptionEventData({
          eventId,
          value: selectedSubscription.price.amount,
          currency: selectedSubscription.price.currency,
          payment_type: 'paypal',
          product_id: productId,
          period: getSubscriptionPeriod(selectedSubscription.period),
        }),
      );
    }

    processPayPalPurchase(async () => {
      return dispatch(
        sendPayPalPurchaseData({
          id: orderId,
          productId,
          fbEventId: eventId,
        }),
      );
    });
  };

  const handleError = function (message: string) {
    if (message.indexOf('USER_ACCOUNT_RESTRICTED') !== -1) {
      setErrorMessage('PayPal payments are temporarily unavailable');
      setPaypalDisabledOnError(true);
    } else {
      setErrorMessage(message);
    }
  };

  const isPayPalSubscriptionButtonVisible =
    paypalAvailable &&
    selectedSubscription &&
    selectedSubscription.subscription_type?.toLowerCase() === 'subs' &&
    userId;

  const isPayPalPaymentButtonVisible =
    paypalAvailable &&
    selectedSubscription &&
    selectedSubscription.subscription_type?.toLowerCase() === 'in_app' &&
    userId;

  const isPaypalTextVisible =
    isPayPalSubscriptionButtonVisible || isPayPalPaymentButtonVisible;

  return (
    <SubscriptionContentRoot className={className} style={style}>
      {subscriptionProcessed ? (
        <SuccessefulSubscription onClose={onClose} header={header} />
      ) : subscription ? (
        <ActiveSubscription subscription={subscription} header={header} />
      ) : (
        <SubscribeToReplikaForm
          onStatusChange={setStatus}
          onError={setErrorMessage}
          processPayment={processPayment}
          setLatestInvoiceId={dispatchLatestInvoiceId}
        >
          {header}

          {title && (
            <Title data-initialfocus tabIndex={-1}>
              {title}
            </Title>
          )}

          {subtitle && <Subtitle>{subtitle}</Subtitle>}

          <ProcessableFields>
            <StyledStripePriceList
              priceId={priceId}
              subscriptions={stripeSubscriptions ?? []}
              onChange={setPriceId}
            />

            {isPayPalSubscriptionButtonVisible ? (
              <PayPalSubscriptionWrapper>
                <PayPalSubscription
                  paypalClientId={paypalClientId}
                  selectedSubscription={selectedSubscription}
                  userId={userId}
                  onSuccess={handlePayPalSubscriptionSuccess}
                  onStatusChange={setStatus}
                  onError={handleError}
                />
              </PayPalSubscriptionWrapper>
            ) : null}

            {isPayPalPaymentButtonVisible ? (
              <>
                <PayPalSubscriptionWrapper>
                  <PayPalPayment
                    paypalClientId={paypalClientId}
                    selectedSubscription={selectedSubscription}
                    userId={userId}
                    onSuccess={handlePayPalOrderSuccess}
                    onStatusChange={setStatus}
                    onError={setErrorMessage}
                  />
                </PayPalSubscriptionWrapper>
              </>
            ) : null}

            {isPaypalTextVisible && <CardCTA>or use a card</CardCTA>}

            <StyledStripeControls
              onError={setErrorMessage}
              onCardComplete={setCardComplete}
              paymentRequestOptions={paymentRequestOptions}
              processPayment={processPayment}
            />

            {subscribeInProgress && (
              <SpinnerContainer $bgColor={spinnerBgColor}>
                <StyledCircleSpinner />
              </SpinnerContainer>
            )}
          </ProcessableFields>

          {errorMessage && <Error>{errorMessage}</Error>}

          <StyledAccentButton type="submit" disabled={subscribeDisabled}>
            {uiParams?.purchase_button_text}
          </StyledAccentButton>

          <Actions>
            <StyledLink
              href={TERMS_SUBS_REPLIKA_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              View subscription terms
            </StyledLink>
          </Actions>
        </SubscribeToReplikaForm>
      )}
    </SubscriptionContentRoot>
  );
}

export default withTheme(SubscriptionContent);

const SubscriptionContentRoot = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
`;

export const SubscribeToReplikaForm = styled(StripeForm)`
  flex: 0 0 auto;
  width: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  ${dialogMobileMedia`
    width: auto;
    max-width: 100vw;
    position: initial;
    height: auto;
    padding: 0;
  `}
`;

const Title = styled.h2`
  flex: 0 0 auto;
  margin: 0;
  min-height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.008px;
  color: ${(p) => p.theme.fgColor};

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  overflow-wrap: break-word;
  white-space: pre;
  width: 100%;

  @media ${(p) => p.theme.breakpoints.tablet} {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    width: auto;
    margin: 0 -13px;
  }
`;

const Subtitle = styled.div`
  color: ${(p) => p.theme.fgColor};
  margin: 10px 0 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 112.5% */
  letter-spacing: -0.16px;
  text-align: center;
`;

const StyledStripePriceList = styled(PriceList)`
  align-self: stretch;
  flex: 0 0 auto;
  margin: 25px -5px 5px;
  /* To prevent hidding the part of item's outline. */
  padding: 5px;
  z-index: 1;
`;

const PayPalSubscriptionWrapper = styled.div`
  width: 100%;
  height: 45px;
  margin: 10px 0 0;
`;

const CardCTA = styled.p`
  color: ${(p) => p.theme.dimmedFgColor};
  font-size: 14px;
  line-height: 18px;
  margin: 10px 0 0;
`;

const Error = styled(AriaStatusText)`
  color: ${(p) => p.theme.errorFgColor};
  margin: 0 0 16px;
  padding: 0 36px;
  font-size: 16px;
  line-height: 18px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledStripeControls = styled(StripeControls)`
  width: 100%;
  margin: 10px 0 0;
`;

const StyledAccentButton = styled(AccentButton)`
  margin: 20px 0 0;
  width: 100%;
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: ${(p) => p.theme.fonts.display};
  line-height: 16px;
  letter-spacing: 0.12px;
  opacity: 0.7;
  margin: 20px 0 0;

  & > * ~ * {
    margin-left: 32px;

    ${dialogMobileMedia`
      margin-left: 24px;
    `}
  }
`;

const StyledLink = styled.a`
  color: ${(p) => p.theme.fgColor};
`;

const ProcessableFields = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledCircleSpinner = styled(CircleSpinner)`
  width: 100px;
  height: 100px;
`;

const SpinnerContainer = styled.div<{ $bgColor?: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => (p.$bgColor ? p.$bgColor : 'transparent')};
  @supports (backdrop-filter: blur(5px)) {
    backdrop-filter: blur(5px);
  }
  z-index: ${ZIndices.Popupbox};
`;
