import { push } from 'connected-react-router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { MetricsEvents, Routes } from '../../../types/enums';
import {
  NavigationPopup,
  NavigationPopupDestinations,
} from '../../../types/models';
import { AnalyticsEventProperties } from '../../../utils/metrics';
import useLogEvent from '../../../utils/useLogEvent';
import {
  Dialog,
  DialogBody,
  DialogButton,
  DialogFooter,
  DialogHeader,
} from '../../DialogLayout/legacy/DialogLayout';

type Props = {
  onClose: () => void;
  navigationPopup?: NavigationPopup;
};

type AnalyticsMapParams = {
  eventName: MetricsEvents;
  eventParams?: AnalyticsEventProperties;
};

export const NavigationPopupKnownDestinationsList = [
  NavigationPopupDestinations.relationship_status,
];

const navigationPopupAnalyticsMap: Record<
  string,
  {
    onMount?: AnalyticsMapParams;
    onButtonClick?: AnalyticsMapParams;
  }
> = {
  [NavigationPopupDestinations.prompts_library_category]: {
    onMount: {
      eventName: MetricsEvents.PromptsPromoOpened,
    },
    onButtonClick: {
      eventName: MetricsEvents.PromptsPromoButtonTapped,
    },
  },
};

const NavigationPopupDialog = ({ onClose, navigationPopup }: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const logEvent = useLogEvent();
  const analyticsParams =
    navigationPopup?.button_destination &&
    navigationPopupAnalyticsMap[navigationPopup.button_destination];

  const logEventIfExists = (params?: AnalyticsMapParams) => {
    if (params?.eventName) {
      logEvent(params.eventName, params.eventParams);
    }
  };

  useEffect(() => {
    logEventIfExists(analyticsParams?.onMount);
    // eslint-disable-next-line local-rules/exhaustive-deps
  }, []);

  const handleButtonClick = () => {
    switch (navigationPopup?.button_destination) {
      case NavigationPopupDestinations.relationship_status:
        dispatch(
          push(Routes.ReplikaProfileEditRelationshipStatus, {
            source: 'main screen',
            from: location.pathname + location.search,
          }),
        );
        break;
    }

    logEventIfExists(analyticsParams?.onButtonClick);
  };

  const knownDestination =
    navigationPopup?.button_destination &&
    NavigationPopupKnownDestinationsList.includes(
      navigationPopup?.button_destination,
    );

  return (
    <StyledDialog data-testid="navigation-dialog" mobileLayout="action-sheet">
      <DialogHeader onClose={onClose} title={navigationPopup?.header_text} />
      <DialogBody>
        <Description>{navigationPopup?.body_text}</Description>
        <DialogFooter>
          {knownDestination && (
            <DialogButton onClick={handleButtonClick}>
              {navigationPopup?.button_text}
            </DialogButton>
          )}
        </DialogFooter>
      </DialogBody>
    </StyledDialog>
  );
};

export default NavigationPopupDialog;

const StyledDialog = styled(Dialog)`
  width: 400px;
`;

const Description = styled.p`
  text-align: center;
  margin: 20px 0 0;
  font-size: 16px;
  line-height: 21px;
`;
