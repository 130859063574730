import { uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { MetricsEvents } from '../../types/enums';
import {
  CategoryTreeNode,
  StoreItem,
  StoreVariationItem,
  isColorVariation,
  isStoreVoiceItems,
} from '../../types/models';
import { filterIncompatibleVariations } from '../../utils/storeVariations';
import useLogEvent from '../../utils/useLogEvent';
import usePlayAudio from '../AudioController/usePlayAudio';
import { CustomizationGridContainer } from '../CustomizationGrid';
import { Desktop } from '../responsive';
import AccordionMenu, { MenuRefHandle } from './AccordionMenu';
import ChangeVoiceGrid from './ChangeVoiceGrid';
import StoreCategoryGridHeader from './StoreCategoryGridHeader';
import StoreCategoryGridSection, {
  ItemTuple,
} from './StoreCategoryGridSection';
import { StoreRangeGrid } from './StoreRangeGrid';
import VariationSelector from './VariationSelector';
import usePaginatedItems from './usePaginatedItems';
import {
  buildMenuItems,
  buildTitlePathMap,
  flattenSections,
  isSectionWithRange,
} from './utils';

type Props = {
  className?: string;
  category: CategoryTreeNode;
  avatarId: string | null;
  selectedVariations: StoreVariationItem[];
  onVariationsChange: (
    variations: StoreVariationItem[],
    itemMap: Record<string, StoreItem>,
    variationType: StoreItem['variation_type'],
  ) => void;
  onItemClick?: (
    item: StoreItem,
    selected: boolean,
    section?: CategoryTreeNode,
  ) => boolean | void;
  grouped?: boolean;
  noPriceTag?: boolean;
  onlyFreeItems?: boolean;
  filters?: ('new' | 'purchased')[];
  itemCount?: number;
  onMenuClick?: (categoryId: string, subCategoryId: string) => void;
};

type RequestedItem = {
  id: string;
  variationId?: string;
};

function StoreCategoryGrid({
  className,
  category,
  avatarId,
  selectedVariations,
  onVariationsChange,
  onItemClick,
  grouped,
  noPriceTag,
  onlyFreeItems,
  filters = [],
  itemCount,
  onMenuClick,
}: Props) {
  const [storeItems] = usePaginatedItems(
    category.id,
    avatarId ?? undefined,
    999,
    () => {
      return false;
    },
    onlyFreeItems,
  );

  const [activeFilter, setActiveFilter] = useState<'new' | 'purchased' | null>(
    null,
  );

  const filteredItems = useMemo(() => {
    let items = storeItems;
    if (activeFilter === 'new') {
      items = items.filter((item) => item.is_new);
    }
    if (activeFilter === 'purchased') {
      items = items.filter((item) =>
        item.variations.some((variation) => variation.bought_count > 0),
      );
    }
    return items;
  }, [storeItems, activeFilter]);

  const hasNewItems = !!storeItems.find((item) => item.is_new);
  const shownFilters = hasNewItems
    ? filters
    : filters.filter((f) => f !== 'new');

  // FIXME: this grouping is not relevant anymore (probably)
  // Sections should be shown regardless of the items loading status
  // Also items should be loaded in sections independently, not all in once
  const groupedCategoryItems = useMemo(() => {
    const groupedCategoryItems = filteredItems.reduce<
      Record<string, StoreItem[]>
    >((acc, item) => {
      const ids = item.category_ids ?? [];
      const mainId = item.category_id;
      if (!acc[mainId]?.push(item)) {
        acc[mainId] = [item];
      }

      if (ids.length > 0) {
        for (let id of ids) {
          if (!acc[id]?.push(item)) {
            acc[id] = [item];
          }
        }
      }
      return acc;
    }, {});
    for (let categoryId in groupedCategoryItems) {
      groupedCategoryItems[categoryId] = uniqBy(
        groupedCategoryItems[categoryId],
        'id',
      );
    }
    return groupedCategoryItems;
  }, [filteredItems]);

  const variationItemMap = useMemo(
    () =>
      filteredItems.reduce<Record<string, StoreItem>>((acc, item) => {
        for (let variation of item.variations) {
          acc[variation.id] = item;
        }
        return acc;
      }, {}),
    [filteredItems],
  );

  const sectionIds = useMemo(
    () => Object.keys(groupedCategoryItems),
    [groupedCategoryItems],
  );

  const includeRangeSections = activeFilter == null && sectionIds.length > 0;

  const shownSections = useMemo(() => {
    let flatten = category ? flattenSections(category) : [];
    return flatten.filter(
      (s) =>
        (includeRangeSections && isSectionWithRange(s)) ||
        sectionIds.includes(s.id),
    );
  }, [category, sectionIds, includeRangeSections]);

  const selectedItems = useMemo(() => {
    let items: Record<string, ItemTuple> = {};
    if (!selectedVariations.length) return items;

    for (let item of filteredItems) {
      const variationIndex = item.variations.findIndex(
        (v) => !!selectedVariations.find((sv) => sv.id === v.id),
      );
      if (variationIndex !== -1) {
        items[item.id] = [item, item.variations[variationIndex]!];
      }
    }

    return items;
  }, [selectedVariations, filteredItems]);

  // current item defines the variations that are shown in the variation selector
  const [currentItem, setCurrentItem] = useState<ItemTuple | null>(() => {
    if (Object.entries(selectedItems).length === 1) {
      return Object.values(selectedItems)[0] ?? null;
    }
    return null;
  });

  const titlePathMap = useMemo(() => {
    return category ? buildTitlePathMap(category) : {};
  }, [category]);

  const handleSelectItem = useCallback(
    (item: StoreItem, section?: CategoryTreeNode, variationId?: string) => {
      const selectedItem = selectedItems[item.id];

      if (onItemClick?.(item, !!selectedItem, section) === false) {
        return;
      }

      if (selectedItem) {
        const [, variation] = selectedItem;

        onVariationsChange(
          selectedVariations.filter((v) => v.id !== variation.id),
          variationItemMap,
          item.variation_type,
        );
        return;
      }

      let variation;

      if (activeFilter === 'purchased') {
        variation = (item.variations as StoreVariationItem[])
          .filter(purchasedVariationsPredicate)
          .find((v) => v.bought_count > 0);
      } else if (variationId) {
        variation = item.variations.find((v) => v.id === variationId);
      } else if (
        item.root_category_key === 'customization' &&
        item.variations[0] &&
        isColorVariation(item.variations[0])
      ) {
        // if we already have colored item of this type, select the same color
        const selectedOfType = Object.values(selectedItems).find(
          ([i, v]) =>
            'unity_category' in v &&
            'unity_category' in item.variations[0]! &&
            i.root_category_key === item.root_category_key &&
            v.unity_category === item.variations[0].unity_category,
        );

        if (selectedOfType) {
          variation = item.variations.find(
            (v) =>
              isColorVariation(v) &&
              isColorVariation(selectedOfType[1]) &&
              v.color.toLowerCase() === selectedOfType[1].color.toLowerCase(),
          );
        }
      }

      variation = variation || item.variations[0];

      if (variation) {
        setCurrentItem([item, variation]);

        const [newVariations] = filterIncompatibleVariations(
          variation,
          selectedVariations,
        );

        onVariationsChange(
          [...newVariations, variation],
          variationItemMap,
          item.variation_type,
        );
      }
    },
    [
      selectedItems,
      onItemClick,
      activeFilter,
      onVariationsChange,
      selectedVariations,
      variationItemMap,
    ],
  );

  const [requestedItem, setRequestedItem] = useState<RequestedItem | null>(
    null,
  );

  useEffect(() => {
    if (!requestedItem) return;
    const item = filteredItems.find((i) => i.id === requestedItem.id);
    if (!item) return;
    setRequestedItem(null);
    handleSelectItem(item, undefined, requestedItem.variationId);
  }, [requestedItem, filteredItems, handleSelectItem]);

  const visibleSections = useRef<Set<number>>(new Set());
  const menuRef = useRef<MenuRefHandle>(null);

  const [isScrolling, setIsScrolling] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (isScrolling) return;
    const s = visibleSections.current;
    const el = containerRef.current;
    if (!el) return;

    const indices = Array.from(s);
    let sections = indices.map((idx) => shownSections[idx]).filter(Boolean);

    const containerRect = el.getBoundingClientRect();
    const containerCenter = (containerRect.top + containerRect.bottom) / 2;

    if (el.scrollTop === 0) {
      const firstId = sections[0]?.id;
      if (firstId) {
        menuRef.current?.selectItem(firstId);
      }
      return;
    }

    for (let section of sections) {
      const rect = document
        .querySelector('#grid-section-' + section.id)
        ?.getBoundingClientRect();
      if (!rect) continue;
      const top = rect.top;
      const bottom = rect.bottom;

      if (top < containerCenter && bottom > containerCenter) {
        menuRef.current?.selectItem(section.id);
        break;
      }
    }
  }, [shownSections, isScrolling]);

  const handleVisible = useCallback(
    (index: number, visible: boolean) => {
      const s = visibleSections.current;
      const el = containerRef.current;
      if (!el) return;

      if (visible) {
        s.add(index);
      } else s.delete(index);

      visibleSections.current = new Set(Array.from(s).sort());

      handleScroll();
    },
    [handleScroll],
  );

  const menuItems = buildMenuItems(category, sectionIds, includeRangeSections);

  let to;

  const filteredVariations = useMemo(() => {
    if (!currentItem || currentItem[0].variations.length < 1) {
      return [];
    }

    let items: StoreVariationItem[] = currentItem[0].variations;

    if (activeFilter === 'purchased') {
      items = items.filter(purchasedVariationsPredicate);

      // We don't need to show the only available variation for a purchased item.
      if (items.length <= 1) {
        items = [];
      }
    }

    return items;
  }, [currentItem, activeFilter]);

  const hasVariationSelector = currentItem && filteredVariations.length > 1;

  const voiceId = useSelector((state) => state.profile.persist.bot?.voice_id);
  const [selectedVoiceId, setSelectedVoiceId] = useState(voiceId || '');
  const logEvent = useLogEvent();
  const audioInterface = usePlayAudio();
  const handleSelectVoice = useCallback(
    (item) => {
      const { internal_voice_id = '', voice_sample_url = '' } =
        item.variations[0] || {};
      setSelectedVoiceId(internal_voice_id);
      audioInterface.play(voice_sample_url);
      logEvent(MetricsEvents.VoiceSamplePlayed);
    },
    [audioInterface, logEvent],
  );
  const sections = grouped ? (
    shownSections.map((section, idx) => {
      const sectionItems = groupedCategoryItems[section.id] ?? [];

      const titlePath = titlePathMap[section.id] ?? [];
      const title = titlePath[titlePath.length - 1];
      const isRange = isSectionWithRange(section);

      if (isStoreVoiceItems(sectionItems)) {
        return (
          <ChangeVoiceGrid
            id={'grid-section-' + section.id}
            key={idx}
            index={idx}
            title={title}
            items={sectionItems}
            onVisible={handleVisible}
            onSelectItem={handleSelectVoice}
            cameraSlot={section.camera_slot}
            voiceId={selectedVoiceId}
          />
        );
      }

      if (isRange) {
        return (
          <StoreRangeGrid
            key={idx}
            title={title}
            section={section}
            avatarId={avatarId || ''}
            index={idx}
            onVisible={handleVisible}
          />
        );
      }
      return (
        <StoreCategoryGridSection
          id={'grid-section-' + section.id}
          key={idx}
          title={title}
          items={sectionItems}
          selectedItems={selectedItems}
          onSelectItem={(item, variationId) =>
            handleSelectItem(item, section, variationId)
          }
          index={idx}
          onVisible={handleVisible}
          noPriceBadge={noPriceTag}
          itemCount={section.num_items}
          withOffsets
        />
      );
    })
  ) : (
    <StoreCategoryGridSection
      id="grid-section"
      items={filteredItems}
      selectedItems={selectedItems}
      onSelectItem={(item) => handleSelectItem(item)}
      noPriceBadge={noPriceTag}
      itemCount={itemCount}
    />
  );

  const handleVariationSelect = (id: string) => {
    if (!currentItem) return;

    const variation = filteredVariations.find((v) => v.id === id);
    if (!variation) return;

    setCurrentItem([currentItem[0], variation]);

    const [newVariations] = filterIncompatibleVariations(
      variation,
      selectedVariations,
    );

    onVariationsChange(
      [...newVariations, variation],
      variationItemMap,
      currentItem[0].variation_type,
    );
  };

  const noVariationPriceBadge = currentItem
    ? noPriceTag || currentItem[0].price.amount === 0
    : false;

  const header = (
    <StoreCategoryGridHeader
      enabledFilters={shownFilters}
      activeFilter={activeFilter}
      onFilterChange={setActiveFilter}
      variationSelector={
        hasVariationSelector && (
          <VariationSelector
            direction="horizontal"
            variations={filteredVariations}
            selectedVariationId={currentItem[1].id}
            onSelect={handleVariationSelect}
            itemSize={34}
            noPriceBadge={noVariationPriceBadge}
          />
        )
      }
    />
  );

  return (
    <StoreCategoryGridRoot className={className}>
      <CustomizationGridContainer
        innerRef={containerRef}
        header={header}
        onScroll={handleScroll}
      >
        {sections}
      </CustomizationGridContainer>
      {grouped && menuItems.length > 1 ? (
        <StyledAccordionMenu
          ref={menuRef}
          items={menuItems}
          onSelect={(item, subitem) => {
            clearTimeout(to);
            setIsScrolling(true);
            const el = document.getElementById('grid-section-' + subitem);
            if (!el) return;
            el.scrollIntoView({ behavior: 'smooth', block: 'start' });
            to = setTimeout(() => setIsScrolling(false), 1000);
            onMenuClick?.(item, subitem);
          }}
        />
      ) : null}
      {hasVariationSelector && (
        <Desktop>
          <VariationSelectorWrapper>
            <DesktopVariationSelector
              direction="vertical"
              variations={filteredVariations}
              selectedVariationId={currentItem[1].id}
              onSelect={handleVariationSelect}
              noPriceBadge={noVariationPriceBadge}
            />
          </VariationSelectorWrapper>
        </Desktop>
      )}
    </StoreCategoryGridRoot>
  );
}

export default StoreCategoryGrid;

const purchasedVariationsPredicate = (v: StoreVariationItem) =>
  v.bought_count > 0;

const VariationSelectorWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  left: -65px;
  top: 0px;
`;

const DesktopVariationSelector = styled(VariationSelector)`
  background: rgba(0 0 0 / 8%);
  backdrop-filter: blur(25px);
  border-radius: 24px;
`;

const StyledAccordionMenu = styled(AccordionMenu)`
  margin-top: 10px;
  flex: 0 0 auto;
  max-width: 100vw;
`;

const StoreCategoryGridRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 0; /* this is needed to prevent shrinking issues and I hate this */
  flex: 0 1 45svh;
  padding-inline: 15px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    flex: 1 1 auto;
  }
`;
