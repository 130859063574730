import { replace } from 'connected-react-router';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { authByOauth, oauthSignIn } from '../../actions/auth';
import { AccentButton } from '../../components/Buttons';
import { ReactComponent as AppleIcon } from '../../icons/Oauth/Apple.svg';
import { ReactComponent as GoogleIcon } from '../../icons/Oauth/Google.svg';
import { Routes } from '../../types/enums';
import { OauthSignInMethod } from '../../types/models';
import { isAuthError } from '../../utils/firebase';
import isAuthSuccessResult from '../../utils/isAuthSuccessResult';
import { SubmitError } from './AuthLayout';

function OAuth() {
  const dispatch = useDispatch();
  const oauthStatus = useSelector((state) => state.auth.oauthStatus);
  const oauthError = useSelector((state) => state.auth.oauthError);
  const oauthInProgress =
    oauthStatus === 'submitting' || oauthStatus === 'success';

  const ignoredError =
    isAuthError(oauthError) && oauthError.code === 'auth/popup-closed-by-user';

  const [submitError, setSubmitError] = React.useState<null | string>(null);
  const [activeOauthButton, setActiveOauthButton] =
    React.useState<null | OauthSignInMethod>(null);

  const showError = oauthStatus === 'error' && !ignoredError && submitError;

  const handleSignIn = async (oauthSignInMethod: OauthSignInMethod) => {
    setActiveOauthButton(oauthSignInMethod);

    try {
      // get Firebase token
      await dispatch(oauthSignIn(oauthSignInMethod));
      // try to sign in using it
      const result = await dispatch(authByOauth());
      const isNewOauthUser = !isAuthSuccessResult(result);
      // otherwise proceed to Sign Up
      if (isNewOauthUser) {
        dispatch(replace(Routes.SignupYourName));
      }
    } catch (error) {
      if (error instanceof Error) {
        setSubmitError(error.message);
      }
      console.error(error);
    }
  };

  return (
    <OAuthRoot>
      <OauthButton
        type="button"
        onClick={() => handleSignIn('google')}
        disabled={oauthInProgress}
        showSpinner={oauthInProgress && activeOauthButton === 'google'}
      >
        <GoogleIcon />
        Continue with Google
      </OauthButton>
      <OauthButton
        type="button"
        onClick={() => handleSignIn('apple')}
        disabled={oauthInProgress}
        showSpinner={oauthInProgress && activeOauthButton === 'apple'}
      >
        <AppleIcon />
        Continue with Apple
      </OauthButton>
      {showError && <StyledSubmitError error={submitError} />}
      <StyledOption>or</StyledOption>
    </OAuthRoot>
  );
}

export default OAuth;

const OAuthRoot = styled.div`
  display: flex;
  flex-direction: column;
`;

const OauthButton = styled(AccentButton)`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  padding: 0;

  & > svg {
    margin-right: 5px;
  }

  &:hover,
  &:focus-visible:focus {
    box-shadow: rgb(209 209 209 / 50%) 0px 0px 20px 3px;
    color: #4a4a4a;
  }

  &[aria-disabled='true'] {
    color: #5e5e5e;
    box-shadow: none;
  }
`;

const StyledSubmitError = styled(SubmitError)`
  margin-left: 20px;
`;

const StyledOption = styled.p`
  margin: 5px 0 10px;
  text-align: center;
`;
