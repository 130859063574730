import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { setHintStatus } from '../../../actions/ui';
import HintButton, {
  HintDescription,
  HintSubTitle,
} from '../../../components/HintButton';
import { useMobileQuery } from '../../../components/responsive';
import { MetricsEvents } from '../../../types/enums';
import useBotName from '../../../utils/useBotName';
import useGoBack from '../../../utils/useGoBack';
import useLogEvent from '../../../utils/useLogEvent';
import { ModalLayout } from '../ModalScreenLayout';
import BackstoryEditForm from './BackstoryEditForm';
import { useFormCloseConfirmation } from './hooks';

const BackstoryModal = () => {
  const dispatch = useDispatch();
  const logEvent = useLogEvent();

  const [isFormDirty, setFormDirty] = useState(false);

  const { goBack } = useGoBack({
    closeOnEscape: true,
  });
  const handleFormClose = useFormCloseConfirmation(goBack);

  const backstory = useSelector(
    (state) => state.profile.persist.coreDescription?.backstory,
  );

  const backstoryHintStatus = useSelector(
    (state) => state.ui.persist.hints.backstory,
  );

  const botName = useBotName();

  const formHint = {
    title: 'How to write a backstory',
    description: (
      <>
        <HintDescription>
          You can edit or delete it later in {botName}'s profile settings.
        </HintDescription>

        <HintSubTitle>Stay safe and respectful</HintSubTitle>
        <HintDescription>
          Avoid offensive language, references to abuse, or sexually explicit
          and harmful material.
        </HintDescription>

        <HintSubTitle>Keep it short & detailed</HintSubTitle>
        <HintDescription>
          Add details about preferences, personality, etc. Use short sentences
          and mention {botName} for best results.
        </HintDescription>
      </>
    ),
    primaryText: 'Got it',
  };

  const hintVisible =
    backstoryHintStatus === 'needed' || (!backstory && !backstoryHintStatus);

  // show form hint only once
  useEffect(() => {
    if (!hintVisible) {
      return;
    }

    dispatch(setHintStatus('backstory', 'shown'));
    logEvent(MetricsEvents.BackstoryInfoPopUpShown, {
      source: 'first time',
    });
  }, [hintVisible, logEvent]);

  const isMobile = useMobileQuery();

  return (
    <>
      <ModalLayout.Popups />
      <ModalLayout.Root layout="right-sidebar">
        <ModalLayout.Header>
          <ModalLayout.HeaderLeft />

          <ModalLayoutHeaderTitle>Backstory</ModalLayoutHeaderTitle>
          <HintButton
            hintType={isMobile ? 'dialog' : 'popover'}
            hintContent={formHint}
            hintVisible={hintVisible}
            onClick={() => {
              logEvent(MetricsEvents.BackstoryInfoPopUpShown, {
                source: 'tap',
              });
            }}
          />

          <ModalLayout.HeaderRight>
            <ModalLayout.SidebarCloseButton
              onClick={isFormDirty ? handleFormClose : goBack}
            />
          </ModalLayout.HeaderRight>
        </ModalLayout.Header>
        <ModalLayout.Content>
          <BackstoryEditForm
            backstory={backstory}
            setFormDirty={setFormDirty}
          />
        </ModalLayout.Content>
      </ModalLayout.Root>
    </>
  );
};

export default BackstoryModal;

const ModalLayoutHeaderTitle = styled(ModalLayout.HeaderTitle)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;
