import { useState } from 'react';
import styled from 'styled-components/macro';
import { StoreVariationItem, isColorVariation } from '../../types/models';
import { IconButton } from '../Buttons';
import Scrollable from '../Scrollable';

type VariationSelectorProps = {
  variations: StoreVariationItem[];
  selectedVariationId: string;
  onSelect: (id: string) => void;
  direction: 'horizontal' | 'vertical';
  className?: string;
  itemSize?: number;
  noPriceBadge?: boolean;
};

function VariationSelector({
  variations,
  selectedVariationId,
  onSelect,
  direction,
  className,
  noPriceBadge,
  itemSize = 44,
}: VariationSelectorProps) {
  const [maskedSides, setMaskedSides] = useState<('start' | 'end')[]>([]);

  return (
    <VariationSelectorRoot
      data-hidescrollbar
      direction={direction}
      className={className}
      role="list"
      noScrollbar
      onMaskedSidesChange={setMaskedSides}
    >
      {maskedSides.includes('start') && null /* TODO */}
      {variations.map((variation) => (
        <VariationSelectorItem
          tabIndex={variation.id === selectedVariationId ? 0 : -1}
          key={variation.id}
          type="button"
          role="listitem"
          active={variation.id === selectedVariationId}
          onClick={() => onSelect(variation.id)}
          data-color={
            isColorVariation(variation) ? '#' + variation.color : null
          }
          $size={itemSize}
        >
          {variation.bought_count > 0 && !noPriceBadge && <ItemBoughtBadge />}
        </VariationSelectorItem>
      ))}
      {maskedSides.includes('end') && null /* TODO */}
    </VariationSelectorRoot>
  );
}

export default VariationSelector;

const VariationSelectorRoot = styled(Scrollable)`
  display: flex;
  gap: 10px;
  flex-direction: ${(p) => (p.direction === 'vertical' ? 'column' : 'row')};
  max-height: 460px;
  overflow-y: auto;
  padding: ${(p) => (p.direction === 'vertical' ? '5px' : '8px 5px')};
  width: 100%;
`;

const VariationSelectorItem = styled(IconButton)<{ $size: number }>`
  position: relative;
  flex: 0 0 auto;
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
  background-color: ${(p) => p['data-color']};
  border-radius: 22px;

  &:hover {
    box-shadow:
      0px 0px 2px #ffffff,
      inset 0px 0px 2px #ffffff;
  }

  &[data-active='true'] {
    box-shadow:
      0px 0px 5px #ffffff,
      inset 0px 0px 5px #ffffff;
  }
`;

const ItemBoughtBadge = styled.div`
  position: absolute;
  pointer-events: none;
  width: 10px;
  height: 10px;
  left: 0px;
  bottom: 0px;

  background: linear-gradient(180.01deg, #e4faa5 0.01%, #0fbf1a 99.99%);
  box-shadow: 0px 0px 5px #acea90;
  border-radius: 50%;
`;
