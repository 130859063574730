import { useAtomValue, useSetAtom } from 'jotai';
import { ComponentPropsWithRef, ReactNode, useEffect, useRef } from 'react';
import { TransitionStatus } from 'react-transition-state';
import styled, { css, keyframes } from 'styled-components/macro';
import {
  ButtonWithSpinnerProps,
  TranslusentIconButton,
} from '../../../components/Buttons';
import { useAllowedDialogs } from '../../../components/Dialogs/hooks';
import FocusTrap from '../../../components/FocusTrap';
import { UnityFullscreenPopups } from '../../../components/FullscreenLayout';
import { useMobileQuery } from '../../../components/responsive';
import { ReactComponent as BackIcon } from '../../../icons/ArrowBack.svg';
import { ReactComponent as CloseIcon } from '../../../icons/Close.svg';
import { Dialogs, ZIndices } from '../../../types/enums';
import useGoBack from '../../../utils/useGoBack';
import { getTimeout } from '../TransitionRoutes/TransitionRoute';
import useRouteTransition from '../TransitionRoutes/useRouteTransition';
import { modalBackgroundAtom, modalLayoutAtom } from '../atoms';

type ModalLayoutType = 'centered' | 'right-sidebar';

export default function ModalScreenLayout({
  children,
  status,
}: {
  children: ReactNode;
  status: TransitionStatus;
}) {
  const instant = useMobileQuery();
  const timeout = getTimeout(status);

  const ref = useRef<HTMLDivElement>(null);

  const layout = useAtomValue(modalLayoutAtom);

  const { location } = useRouteTransition();
  const { goBack: closeGoBack } = useGoBack({
    anchor: 'modal',
    inTransition: true,
    closeOnEscape: false,
    location,
  });

  return (
    <FocusTrap
      focusTrapOptions={{
        fallbackFocus: () => ref.current ?? document.body,
        allowOutsideClick: (e) => {
          if (e.target instanceof Element) {
            return e.target.closest('#iubenda-iframe') != null;
          }
          return false;
        },
        // we are handling navigation by escape key ourselves, so we don't need this
        // it can also break escape key handling in components like radix-select
        escapeDeactivates: false,
      }}
    >
      <ModalScreenLayoutRoot tabIndex={-1}>
        <ModalBackground
          onClick={layout === 'right-sidebar' ? closeGoBack : undefined}
          $layout={layout}
          $status={instant ? 'entered' : status}
          $timeout={instant ? 0 : timeout}
        />
        {children}
      </ModalScreenLayoutRoot>
    </FocusTrap>
  );
}

const SCREEN_TRANSITIONS = {
  entering: keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `,
  exiting: keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `,
} as const;

const ROOT_TRANSITIONS = {
  'right-sidebar': {
    entering: keyframes`
      from {
        transform: translate(80px, 0);
      }
      to {
        transform: translate(0, 0);
      }
    `,
    exiting: keyframes`
      from {
        transform: translate(0, 0);
      }
      to {
        transform: translate(80px, 0);
      }
    `,
  },
} as const;

const CONTENT_TRANSITIONS = {
  centered: {
    entering: keyframes`
    from {
      opacity: 0;
      transform: translate(0, 30px);
    }
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  `,
    exiting: keyframes`
    from {
      opacity: 1;
      transform: translate(0, 0);
    }
    to {
      opacity: 0;
      transform: translate(0, 30px);
    }
  `,
  },
  'right-sidebar': {
    entering: keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `,
    exiting: keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `,
  },
} as const;

type LayoutProps = {
  $layout?: ModalLayoutType;
  $status: TransitionStatus;
  $timeout: number;
};

function getScreenRootAnimation({ $status, $timeout }: LayoutProps) {
  const anim = SCREEN_TRANSITIONS[$status];

  return anim && $timeout > 0
    ? css`
        animation: ${anim} ${$timeout}ms ease-in-out both;
      `
    : 'animation: none;';
}

// had to move backdrop filter to a pseudo element,
// otherwise weird glitches happening when changing opacity on children

const ModalScreenLayoutRoot = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${ZIndices.ModalScreen};

  & input {
    backdrop-filter: blur(0px);
  }
`;

function ModalLayoutRoot(
  props: ComponentPropsWithRef<'div'> & {
    layout?: ModalLayoutType;
    background?: string | null;
    allowedDialogs?: Dialogs[];
  },
) {
  const { layout = 'centered', background, allowedDialogs, ...rest } = props;
  const { status, timeout } = useRouteTransition();

  useAllowedDialogs(
    allowedDialogs ?? [Dialogs.Confirmation, Dialogs.Hint, Dialogs.ClaimItem],
  );

  const setLayout = useSetAtom(modalLayoutAtom);
  const setBackground = useSetAtom(modalBackgroundAtom);

  useEffect(() => {
    setLayout(layout);
    return () => setLayout(undefined);
  }, [layout]);

  useEffect(() => {
    if (background === undefined) return;
    setBackground(background);
    return () => setBackground(null);
  }, [background]);

  const Root =
    layout === 'right-sidebar' ? ModalLayoutRootRightRoot : ModalLayoutRootRoot;

  return (
    <Root
      data-translucentbox
      $layout={layout}
      $status={status}
      $timeout={timeout}
      {...rest}
    />
  );
}

function getRootAnimation({ $layout, $status, $timeout }: LayoutProps) {
  const anim = ROOT_TRANSITIONS[$layout ?? 'centered']?.[$status];

  return anim && $timeout > 0
    ? css`
        animation: ${anim} ${$timeout}ms ease-in-out both;
      `
    : 'animation: none;';
}

const ModalLayoutRootRoot = styled.div<LayoutProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  opacity: ${(p) => (p.$status === 'preEnter' ? 0 : 1)};

  ${getRootAnimation}
`;

// FIXME: review backgrounds
const ModalLayoutRootRightRoot = styled(ModalLayoutRootRoot)`
  @media ${(p) => p.theme.breakpoints.tablet} {
    position: fixed;
    top: 0;
    right: 0;
    margin: 10px;
    height: calc(100% - 20px);
    width: 400px;
    align-items: flex-end;
    border-radius: 24px;

    &:before {
      backdrop-filter: blur(25px);
      border-radius: 24px;
      background: rgba(255, 255, 255, 0.15);
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(50px);
    z-index: -1;
    opacity: ${(p) => (p.$status === 'preEnter' ? 0 : 1)};
    ${getScreenRootAnimation}
  }
`;

const ModalBackground = styled.div<LayoutProps>`
  z-index: -1;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${(p) => p.theme.fullscreenBgColors.modal};
  opacity: ${(p) => (p.$status === 'preEnter' ? 0 : 1)};

  transition: background ${(p) => p.$timeout}ms ease-in-out;

  ${getScreenRootAnimation}

  @supports (backdrop-filter: blur(50px)) {
    backdrop-filter: ${(p) =>
      p.$layout === 'centered' ? 'blur(50px)' : 'none'};
    background: ${(p) =>
      p.$layout === 'centered'
        ? p.theme.fullscreenBgColors.modalBlurred
        : p.theme.fullscreenBgColors.modal};
  }
`;

export function ModalLayoutContent(
  props: ComponentPropsWithRef<'div'> & { as?: any },
) {
  const layout = useAtomValue(modalLayoutAtom);
  const { status, timeout } = useRouteTransition();

  return (
    <ModalLayoutContentRoot
      $layout={layout}
      $status={status}
      $timeout={timeout}
      {...props}
    />
  );
}

function getContentAnimation({ $layout, $status, $timeout }: LayoutProps) {
  const anim = CONTENT_TRANSITIONS[$layout ?? 'centered']?.[$status];

  return anim && $timeout > 0
    ? css`
        animation: ${anim} ${$timeout}ms ease-in-out;
      `
    : 'animation: none;';
}

const ModalLayoutContentRoot = styled.div<LayoutProps>`
  flex: 1;
  min-height: 0;
  opacity: ${(p) => (p.$status === 'preEnter' ? 0 : 1)};
  transform: ${(p) =>
    p.$status === 'preEnter' ? 'translate(0, 30px)' : 'translate(0, 0)'};
  ${getContentAnimation}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(p) =>
    p.$layout === 'right-sidebar' ? 'flex-start' : 'center'};
  width: ${(p) => (p.$layout === 'right-sidebar' ? '100%' : 'auto')};
  color: ${(p) => p.theme.fgColor};

  @media ${(p) => p.theme.breakpoints.tablet} {
    &:after {
      /* compensate header, but collapse if content is long enough */
      content: '';
      display: block;
      flex: 1 1 auto;
      max-height: 80px;
    }
  }
`;

export function ModalLayoutHeader(
  props: ComponentPropsWithRef<'div'> & { primary?: boolean },
) {
  const { primary, ...rest } = props;
  const layout = useAtomValue(modalLayoutAtom);
  const { status, timeout } = useRouteTransition();

  return (
    <ModalLayoutHeaderRoot
      $status={status}
      $timeout={timeout}
      $layout={layout}
      $primary={primary}
      {...rest}
    />
  );
}

export const ModalLayoutHeaderRoot = styled.div.attrs<{ $primary?: boolean }>(
  (p) => ({ 'data-primary': p.$primary }),
)<LayoutProps & { $primary?: boolean }>`
  width: 100%;
  flex: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 15px 15px 0;

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding: 20px 20px 0;
  }

  opacity: ${(p) => (p.$status === 'preEnter' ? 0 : 1)};
  animation: ${(p) => SCREEN_TRANSITIONS[p.$status] ?? 'none'}
    ${(p) => p.$timeout}ms ease-in-out;
`;

export const ModalLayoutHeaderLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  [data-primary='true'] > & {
    flex: 0;
    width: 0;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    [data-primary='true'] > & {
      flex: 1;
      width: auto;
    }
  }
`;

export const ModalLayoutHeaderRight = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
`;

export function ModalLayoutHeaderTitle(props: ComponentPropsWithRef<'h1'>) {
  const layout = useAtomValue(modalLayoutAtom);
  const { status, timeout } = useRouteTransition();

  return (
    <ModalLayoutHeaderTitleRoot
      $status={status}
      $timeout={timeout}
      $layout={layout}
      {...props}
    />
  );
}

const ModalLayoutHeaderTitleRoot = styled.h1<LayoutProps>`
  margin: 0;
  flex: ${(p) => (p.$layout === 'right-sidebar' ? '0 1 auto' : 1)};
  text-align: center;
  color: ${(p) => p.theme.fgColor};
  white-space: nowrap;
  font-size: ${(p) => (p.$layout === 'right-sidebar' ? 20 : 16)}px;
  line-height: ${(p) => (p.$layout === 'right-sidebar' ? '22px' : '1em')};

  [data-primary='true'] & {
    font-size: 36px;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    font-size: 28px;

    [data-primary='true'] & {
      font-size: 28px;
    }
  }
`;

export function ModalLayoutCloseButton(props: ButtonWithSpinnerProps) {
  return (
    <TranslusentIconButton {...props}>
      <StyledCloseIcon />
    </TranslusentIconButton>
  );
}

const ModalLayoutSidebarCloseButton = styled(ModalLayoutCloseButton)`
  background: rgba(255 255 255 / 10%);

  &:hover {
    background: rgba(255 255 255 / 20%);
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 34px;
    height: 34px;
    border-radius: 14px;

    & > svg {
      width: 26px;
    }
  }
`;

const ModalLayoutSidebarBackButton = styled(ModalLayoutBackButton)`
  background: rgba(255 255 255 / 10%);

  &:hover {
    background: rgba(255 255 255 / 20%);
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 34px;
    height: 34px;
    border-radius: 14px;

    & > svg {
      width: 26px;
    }
  }
`;

const StyledCloseIcon = styled(CloseIcon)`
  width: 26px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 36px;
  }
`;

export function ModalLayoutBackButton(props: ButtonWithSpinnerProps) {
  return (
    <TranslusentIconButton {...props}>
      <BackIcon />
    </TranslusentIconButton>
  );
}

const ModalLayoutError = styled.p`
  color: ${(p) => p.theme.errorFgColor};
`;

export const ModalLayout = {
  Root: ModalLayoutRoot,
  Header: ModalLayoutHeader,
  HeaderTitle: ModalLayoutHeaderTitle,
  Content: ModalLayoutContent,
  HeaderLeft: ModalLayoutHeaderLeft,
  HeaderRight: ModalLayoutHeaderRight,
  BackButton: ModalLayoutBackButton,
  CloseButton: ModalLayoutCloseButton,
  SidebarBackButton: ModalLayoutSidebarBackButton,
  SidebarCloseButton: ModalLayoutSidebarCloseButton,
  Error: ModalLayoutError,
  Popups: UnityFullscreenPopups,
};
