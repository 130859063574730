import styled from 'styled-components/macro';
import { SubscriptionListing } from '../../types/models';
import { dialogMobileMedia } from '../../utils/mobileMedia';
import NoWrap from '../NoWrap';
import { UpperBadge, UpperBadgeText } from '../UpperBadge';

type Props = {
  subscription: SubscriptionListing;
  selected: boolean;
  focusable: boolean;
  onClick: () => void;
  hideDetails?: boolean;
};

function formatPrice(amount: number, currency: string) {
  if (currency.toUpperCase() === 'USD') {
    return '$' + amount.toFixed(2);
  }

  return amount.toFixed(2) + ' ' + currency.toUpperCase();
}

function formatDetails(
  subscription: SubscriptionListing,
  format: 'details_middle' | 'details_up' | 'details_low',
) {
  const priceAmount = subscription.price.amount;
  const wholePrice = formatPrice(priceAmount, subscription.price.currency);
  const monthlyPrice = formatPrice(
    priceAmount / 12,
    subscription.price.currency,
  ); // assuming that's a yearly price
  const freePeriod = subscription.trial_period_days
    ? String(subscription.trial_period_days)
    : '0';

  return subscription[format]
    ?.replace(/\{WHOLE_PRICE\}/g, wholePrice)
    .replace(/\{MONTHLY_PRICE\}/g, monthlyPrice)
    .replace(/\{FREE_PERIOD\}/g, freePeriod);
}

function PriceCard(props: Props) {
  const {
    subscription,
    selected,
    onClick,
    focusable,
    hideDetails = false,
  } = props;

  const periodTitle = subscription.name; //getSubscriptionPeriodTitle(subscription);

  const detailsMiddle = formatDetails(subscription, 'details_middle');
  const detailsUp = formatDetails(subscription, 'details_up');
  const detailsLow = formatDetails(subscription, 'details_low');

  return (
    <PriceCardRoot role="presentation">
      <PriceCardButton
        type="button"
        role="radio"
        tabIndex={focusable ? 0 : -1}
        aria-checked={selected}
        onClick={onClick}
        onFocus={(e) => {
          e.target.scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
            block: 'nearest',
          });
        }}
      >
        {subscription.badge && (
          <UpperBadge>
            <UpperBadgeText>{subscription.badge.title}</UpperBadgeText>
          </UpperBadge>
        )}
        <SubscriptionPeriod $hideDetails={hideDetails}>
          {periodTitle}
          {detailsLow && (
            <SubscriptionSubInfo>{detailsLow}</SubscriptionSubInfo>
          )}
        </SubscriptionPeriod>

        <SubscriptionPrice $maxWidth="100%">
          {!hideDetails && <DetailsUp>{detailsUp}</DetailsUp>}
          {detailsMiddle}
        </SubscriptionPrice>
      </PriceCardButton>
    </PriceCardRoot>
  );
}

const PriceCardRoot = styled.li`
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PriceCardButton = styled.button`
  position: relative;
  border: 1px solid
    ${(p) => (p['aria-checked'] ? 'rgba(255, 255, 255, 0.5)' : `transparent`)};
  background: ${(p) =>
    p['aria-checked']
      ? ' linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), rgba(0, 0, 0, 0.08)'
      : `rgba(0, 0, 0, 0.08)`};
  color: ${(p) => p.theme.fgColor};
  box-shadow: ${(p) =>
    p['aria-checked'] ? '0px 0px 1.5px 2px rgba(255, 255, 255, 0.5)' : `none`};
  font-size: 24px;
  min-width: 116px;
  width: 100%;
  max-width: 180px;
  height: 130px;
  border-radius: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ${dialogMobileMedia`
    height: 64px;
    max-width: 100%;
    flex-direction: row;
  `}
`;

const DetailsUp = styled.span`
  display: block;
  font-size: 12px;
  color: ${(p) => p.theme.dimmedFgColor};
  height: 20px;
`;

const SubscriptionPeriod = styled.span<{ $hideDetails: boolean }>`
  margin-top: ${(p) => (p.$hideDetails ? '32px' : '16px')};
  letter-spacing: 0.02em;
  color: ${(p) => p.theme.fgColor};
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  margin-bottom: 5px;
  font-family: ${(p) => p.theme.fonts.display};

  ${dialogMobileMedia`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    margin: 0 0 0 15px;
  `}
`;

const SubscriptionPrice = styled(NoWrap)`
  color: ${(p) => p.theme.dimmedFgColor};
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 15px;

  ${dialogMobileMedia`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 15px 0 0;
  `}
`;

const SubscriptionSubInfo = styled.span`
  color: ${(p) => p.theme.superDimmedFgColor};
  display: block;
  font-size: 12px;
  line-height: 16px;
  font-family: ${(p) => p.theme.fonts.body};
`;

export default PriceCard;
