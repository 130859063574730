import md5 from 'md5';
import { Themes } from '../../types/enums';
import { CustomizationVariationItem, MorphType } from '../../types/models';

export default function avatarKeyHash({
  type,
  age,
  variations,
  bodyType,
  theme,
  cameraSlot,
}: {
  type: string;
  age: number;
  variations: CustomizationVariationItem[];
  bodyType: Partial<Record<MorphType, number>> | undefined;
  theme: Themes;
  cameraSlot: string | null;
}) {
  return md5(
    JSON.stringify([
      type.replace(/^avatar_/, ''),
      age,
      ...variations.map((v) => v.id).sort(),
      theme,
      cameraSlot,
      bodyType,
    ]),
  );
}
