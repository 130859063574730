import * as A from '../types/actions';
import {
  AsyncActionTypes as AAT,
  ActionTypes as AT,
  WsEventNames,
} from '../types/enums';
import { AudioMessage } from '../types/models';
import { DA } from '../types/redux';
import { VoiceModeAction } from '../types/websocket';

import { ApiError } from '../utils/apiError';
import {
  apiAction,
  errorAction,
  requestAction,
  successAction,
  wsAction,
} from '../utils/asyncAction';
import fetchOptions from '../utils/fetchOptions';
import { API_BASE_URL } from '../utils/uri';
import { sendMessage } from '../utils/websocket';

export const changeVoiceMode =
  (voiceMode: VoiceModeAction): DA<{}> =>
  async (dispatch, getState) => {
    const state = getState();
    const { chatId } = state.ws.persist;

    if (!chatId) {
      throw Error('no chat id');
    }

    const params = { chatId, voiceMode };

    return wsAction<A.ChangeVoiceMode>(
      AAT.WsChangeVoiceMode,
      dispatch,
      params,
      {
        onRequest: () =>
          sendMessage(
            {
              event_name: WsEventNames.ChangeVoiceMode,
              payload: {
                chat_id: chatId,
                action: voiceMode,
                ar_mode: false, // no AR on web for now
              },
            },
            state,
          ),
      },
    );
  };

export const startVoiceStreaming =
  (clientToken: string): DA<{}> =>
  async (dispatch, getState) => {
    const state = getState();
    const { chatId } = state.ws.persist;
    const { bot } = state.profile.persist;

    if (!bot) {
      throw Error('no bot');
    }
    if (!chatId) {
      throw Error('no chat id');
    }

    const meta = {
      bot_id: bot.id,
      client_token: clientToken,
      chat_id: chatId,
    };

    const params = { chatId, message: { meta } };

    return wsAction<A.StartVoiceStreaming>(
      AAT.WsStartVoiceStreaming,
      dispatch,
      params,
      {
        onRequest: () =>
          sendMessage(
            {
              event_name: WsEventNames.StartVoiceStreaming,
              payload: {
                meta,
              },
            },
            state,
          ),
      },
    );
  };

export const streamVoiceChunks =
  (chunk: any, clientToken: string): DA<{}> =>
  async (dispatch, getState) => {
    const state = getState();
    const { chatId } = state.ws.persist;
    const { bot } = state.profile.persist;

    if (!bot) {
      throw Error('no bot');
    }
    if (!chatId) {
      throw Error('no chat id');
    }

    const meta = {
      bot_id: bot.id,
      client_token: clientToken,
      chat_id: chatId,
    };

    const params = { chatId, message: { meta } };

    return wsAction<A.StreamVoiceChunks>(
      AAT.WsStreamVoiceChunks,
      dispatch,
      params,
      {
        onRequest: () =>
          sendMessage(
            {
              event_name: WsEventNames.StreamVoiceChunks,
              //@ts-ignore
              payload: {
                meta,
                chunk_data: {
                  chunk: chunk, // String - bytes in base64 string
                  chunk_sample_rate: 16000, // Int - 16000
                  chunk_channels: 1, // Int - 1
                  chunk_samples_num: 1600, // Int - 100ms x 16000 x 1 = 1600
                  chunk_format: 'audio/l16', // String - "audio/l16" (PCM Linear16), in MIME-Type format
                },
              },
            },
            state,
          ),
      },
    );
  };

export const endVoiceStreaming =
  (clientToken: string): DA<{}> =>
  async (dispatch, getState) => {
    const state = getState();
    const { chatId } = state.ws.persist;
    const { bot } = state.profile.persist;

    if (!bot) {
      throw Error('no bot');
    }
    if (!chatId) {
      throw Error('no chat id');
    }

    const meta = {
      bot_id: bot.id,
      client_token: clientToken,
      chat_id: chatId,
    };

    const params = { chatId, message: { meta } };

    const timestamp = Date.now();

    return wsAction<A.EndVoiceStreaming>(
      AAT.WsEndVoiceStreaming,
      dispatch,
      params,
      {
        onRequest: () =>
          sendMessage(
            {
              event_name: WsEventNames.EndVoiceStreaming,
              payload: {
                meta,
              },
            },
            state,
          ),
        onSuccess: () => dispatch(saveVoiceMessageTime(timestamp)),
      },
    );
  };

export const updateVoiceCallAudioQueue = (
  audioQueue: AudioMessage[],
): A.UpdateVoiceCallAudioQueue => {
  return {
    type: AT.UpdateVoiceCallAudioQueue,
    audioQueue,
  };
};

export const saveVoiceCallStartTime = (
  timestamp: number,
): A.SaveVoiceCallStartTime => {
  return {
    type: AT.SaveVoiceCallStartTime,
    timestamp,
  };
};

export const saveVoiceMessageTime = (
  timestamp: number,
): A.SaveVoiceMessageTime => {
  return {
    type: AT.SaveVoiceMessageTime,
    timestamp,
  };
};

export const clearVoiceMessageTime = (): A.ClearVoiceMessageTime => {
  return { type: AT.ClearVoiceMessageTime };
};

export const sendVoiceCallFeedback =
  ({
    feedbackScore,
    feedbackText,
  }: {
    feedbackScore: number;
    feedbackText?: string;
  }): DA<{}> =>
  async (dispatch, getState) => {
    const state = getState();

    return wsAction<A.SendVoiceCallFeedback>(
      AAT.WsSendVoiceCallFeedback,
      dispatch,
      { feedbackScore, feedbackText },
      {
        onRequest: () =>
          sendMessage(
            {
              event_name: WsEventNames.SendVoiceCallFeedback,
              payload: {
                feedback_score: feedbackScore,
                feedback_text: feedbackText ?? '',
              },
            },
            state,
          ),
      },
    );
  };

export const getVoiceMessage =
  (url: string): DA<Blob> =>
  async (dispatch, getState) => {
    const params = { url };

    try {
      dispatch<A.GetVoiceMessage>(requestAction(AAT.GetVoiceMessage, params));

      const res = await fetch(
        `${API_BASE_URL}/voice_messages?voice_message_url=${url}`,
        fetchOptions(
          getState(),
          'GET',
          {},
          {
            Accept: 'audio/wav',
          },
        ),
      );

      if (res.status !== 200) {
        throw await res.text();
      }

      const result = await res.blob();

      dispatch<A.GetVoiceMessage>(
        successAction(
          AAT.GetVoiceMessage,
          {
            url,
            content: result,
          },
          params,
        ),
      );

      return result;
    } catch (error) {
      if (error instanceof ApiError) {
        dispatch<A.GetVoiceMessage>(
          errorAction(AAT.GetVoiceMessage, error, params),
        );
      }
      throw error;
    }
  };

export const uploadVoiceMessage =
  (voice_message: Blob): DA<{ voice_message_url: string }> =>
  async (dispatch, getState) => {
    const fetchOpts = fetchOptions(getState(), 'POST', voice_message);

    return apiAction<A.UploadVoiceMessage>(
      AAT.UploadVoiceMessage,
      dispatch,
      {},
      {
        onRequest: () => fetch(`${API_BASE_URL}/voice_messages`, fetchOpts),
      },
    );
  };

export const updateGlobalVoiceMessageStatus = (
  status: 'idle' | 'playing' | 'recording',
): A.UpdateGlobalVoiceMessageState => {
  return {
    type: AT.UpdateGlobalVoiceMessageState,
    status,
  };
};

export const updateLastPlayedVoiceMessage = (
  id?: string,
): A.UpdateLastPlayedVoiceMessage => {
  return {
    type: AT.UpdateLastPlayedVoiceMessage,
    id,
  };
};
