import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { setActiveSidePanel } from 'src/actions/ui';
import { TNavigationLink } from 'src/features/NavigationPanel/NavigationLinks';
import { isCurrentRoute } from 'src/features/NavigationPanel/utils';
import styled from 'styled-components/macro';
import { IconButton, TranslusentIconButton } from '../../components/Buttons';
import Picture from '../../components/Picture';
import UnreadBadge from '../../components/UnreadBadge';
import { useNavigationLinks } from '../../features/NavigationPanel';
import NavigationPopover from '../../features/NavigationPanel/NavigationPopover';
import { useUnreadCounters } from '../../features/NavigationPanel/queries';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { ZIndices } from '../../types/enums';
import useFocusGroup from '../../utils/useFocusGroup';

export default function MainMenu({
  className,
  onClose,
}: {
  className?: string;
  onClose: () => void;
}) {
  const links = useNavigationLinks();
  const dispatch = useDispatch();
  const location = useLocation();
  const openedSidePanel = useSelector((state) => state.ui.sidePanel.panel);

  const unreadCounters = useUnreadCounters();

  const ref = useRef<HTMLUListElement>(null);

  useFocusGroup(
    {
      getGroupElements: () => {
        return ref.current?.querySelectorAll('a,button') ?? [];
      },
      options: {
        keybindings: 'left-right',
        wrap: true,
      },
    },
    [],
  );

  const handleClick = (link: TNavigationLink) => {
    if ('sidePanel' in link) {
      dispatch(setActiveSidePanel(link.sidePanel));
    }

    onClose?.();
  };

  return (
    <MainMenuRoot className={className}>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <MainMenuList ref={ref}>
        {links.map((link) => {
          const isActive = isCurrentRoute(
            link,
            openedSidePanel,
            location.pathname,
          );

          const menuItem = (
            <MainMenuItem key={link.name} $active={isActive}>
              {unreadCounters[link.name] ? <StyledUnreadBadge /> : null}
              <MainMenuItemButton
                to={link.route}
                onClick={() => handleClick(link)}
              >
                {'imgSrc' in link && (
                  <MainMenuItemIcon data-adjustbrightness src={link.imgSrc} />
                )}
                <MainMenuItemLabel>{link.text}</MainMenuItemLabel>
              </MainMenuItemButton>
            </MainMenuItem>
          );

          return unreadCounters[link.name] ? (
            <NavigationPopover
              link={link}
              offset={10}
              side="top"
              key={link.name}
              count={unreadCounters[link.name]}
            >
              {menuItem}
            </NavigationPopover>
          ) : (
            menuItem
          );
        })}
      </MainMenuList>
    </MainMenuRoot>
  );
}

const MainMenuList = styled.ul`
  display: grid;
  grid-template-columns: 100px 100px;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  margin: 0;
  padding: 0;
  grid-gap: 40px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px 90px;
    padding: 0 30px;
    gap: 30px;
  }
`;

const MainMenuItem = styled.li<{ $active: boolean }>`
  background: ${(p) => (p.$active ? 'rgb(255 255 255 / .15)' : 'transparent')};
  border-radius: 24px;
  padding: 10px;
  position: relative;
  margin: 0;
`;

const MainMenuItemButton = styled(IconButton)`
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  &:hover {
    text-decoration: none;
  }
`;

const MainMenuItemIcon = styled(Picture)`
  display: block;
  width: 60px;
  height: 60px;
`;

const MainMenuItemLabel = styled.span`
  font-family: ${(p) => p.theme.fonts.display};
  font-size: 14px;
  line-height: 18px;
  text-shadow: 0 0 5px rgb(0 0 0 / 30%);
`;

const StyledUnreadBadge = styled(UnreadBadge)`
  position: absolute;
  top: 0px;
  right: 0px;
`;

const MainMenuRoot = styled.nav`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.13);
  backdrop-filter: blur(50px);
  z-index: ${ZIndices.FullscreenNavigation};
`;

const CloseButton = styled(TranslusentIconButton)`
  position: absolute;
  top: 15px;
  right: 15px;

  svg {
    width: 24px;
    height: 24px;
  }
`;
