import { useRef } from 'react';
import styled from 'styled-components/macro';
import { formatShortDate } from '../../core/locale';
import { MetricsEvents, ModalRoutes } from '../../types/enums';
import { MemoryFactWithType } from '../../types/models';
import toModalRoute from '../../utils/toModalRoute';
import useFocusGroup from '../../utils/useFocusGroup';
import useLogEvent from '../../utils/useLogEvent';
import { Checkbox } from '../Checkbox';
import AriaButton from '../legacy/AriaButton';
import outlineCss from '../outlineCss';

type Props = {
  temporary: boolean;
  facts: MemoryFactWithType[];
  isProfilePreview?: boolean;
  selectableMode?: boolean;
  factsSelected?: string[];
  setFactsSelected?: (factsSelected: string[]) => void;
};

export default function FactList({
  temporary,
  facts,
  isProfilePreview,
  selectableMode,
  factsSelected = [],
  setFactsSelected,
}: Props) {
  const logEvent = useLogEvent();

  const rootElRef = useRef<HTMLUListElement>(null);

  useFocusGroup(
    {
      getGroupElements: () => {
        return (
          rootElRef.current?.getElementsByTagName(
            selectableMode ? 'button' : 'a',
          ) ?? []
        );
      },
      options: {
        wrap: true,
      },
    },
    [facts],
  );

  return (
    <FactListRoot ref={rootElRef}>
      {facts.map((fact, idx) => {
        const id = fact.id;
        const isSelected = factsSelected?.includes(id);

        return (
          <Fact key={id} $temporary={temporary}>
            <FactLink
              {...(!selectableMode
                ? {
                    to: toModalRoute<{
                      memoryType: 'persons' | 'facts';
                      memoryId: string;
                      source?: string;
                    }>(ModalRoutes.MemoryRecordEdit, {
                      memoryType: 'facts',
                      memoryId: id,
                      // FIXME: should we route to Routes.Memory from profile?
                      ...(isProfilePreview ? { source: 'profile' } : {}),
                    }),
                  }
                : {})}
              onClick={(e) => {
                if (selectableMode && setFactsSelected) {
                  isSelected
                    ? setFactsSelected(
                        factsSelected.filter((factId) => factId !== id),
                      )
                    : setFactsSelected([...factsSelected, id]);
                } else {
                  logEvent(MetricsEvents.MemoryItemTapped);
                }
              }}
              tabIndex={idx === 0 ? 0 : -1}
              role={selectableMode ? 'checkbox' : undefined}
              aria-checked={selectableMode ? isSelected : undefined}
            >
              <FactInfo>
                {temporary && (
                  <FactDate>
                    {formatShortDate(new Date(fact.creation_timestamp))}
                  </FactDate>
                )}
                {fact.text}
              </FactInfo>
              {selectableMode ? (
                <FactCheckbox data-active={isSelected} />
              ) : null}
            </FactLink>
          </Fact>
        );
      })}
    </FactListRoot>
  );
}

const FactListRoot = styled.ul`
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const Fact = styled.li<{ $temporary: boolean }>`
  position: relative;
  border-radius: 14px;
  background: ${(p) =>
    p.$temporary ? 'rgb(255 240 107 / 20%)' : 'rgb(255 255 255 / 10%)'};
`;

const FactDate = styled.time`
  color: rgb(255 255 255 / 70%);
  display: block;
  font-size: 12px;
  font-family: ${(p) => p.theme.fonts.display};
`;

const FactCheckbox = styled(Checkbox)`
  position: relative;
  flex: 0 0 auto;
  bottom: 0;
  top: 0;
  margin-right: -5px;
`;

const FactLink = styled(AriaButton)`
  display: flex;
  gap: 5px;
  align-items: center;
  width: 100%;
  min-height: 50px;
  padding: 13px 15px;
  color: ${(p) => p.theme.fgColor};
  border-radius: 14px;
  border: none;
  background: transparent;

  &:hover {
    background: rgba(255 255 255 / 15%);
    text-decoration: none;
  }

  &:focus {
    background: rgba(255 255 255 / 15%);
  }

  &.focus-visible:focus,
  &:focus-visible:focus {
    box-shadow: none;
    ${outlineCss({ shadowSize: 'small' })}
  }
`;

const FactInfo = styled.p`
  width: 100%;
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  padding: 2px 0;
  max-width: 100%;
  font-weight: 400;
  text-align: left;
`;
