import { LocationDescriptor } from 'history';
import { useMobileQuery } from '../../components/responsive';
import {
  MetricsEvents,
  ModalRoutes,
  Routes,
  SidePanel,
} from '../../types/enums';
import toModalRoute from '../../utils/toModalRoute';
import { MAIN_PAGE_URL_BASE } from '../../utils/uri';

export type AppNavigationLinkName =
  | 'chat'
  | 'activities'
  | 'store'
  | 'profile'
  | 'memory'
  | 'diary'
  | 'settings'
  | 'room';

type AppNavigationLink = {
  name: AppNavigationLinkName;
  text: string;
  route: LocationDescriptor<Record<string, any>>;
  routeState?: Record<string, string>;
  event?: MetricsEvents;
};

export type AppNavigationLinkWithImg = AppNavigationLink & { imgSrc: string };
export type AppNavigationLinkWithSidePanel = AppNavigationLinkWithImg & {
  sidePanel: SidePanel;
  sidePanelActiveLinks?: SidePanel[];
};
export type AppNavigationLinkWithElement = AppNavigationLink & {
  element: React.ReactElement;
};

export type TNavigationLink =
  | AppNavigationLinkWithImg
  | AppNavigationLinkWithElement
  | AppNavigationLinkWithSidePanel;

export function useNavigationLinks(): Array<TNavigationLink> {
  const isMobile = useMobileQuery();

  let navLinks: Array<TNavigationLink> = [
    {
      name: 'chat',
      text: 'Chat',
      imgSrc: `${MAIN_PAGE_URL_BASE}/nav_icons/v2/chat.png`,
      route: Routes.Chat,
    },
    {
      name: 'activities',
      text: 'Activities',
      imgSrc: `${MAIN_PAGE_URL_BASE}/ask_replika_icon.png`,
      route: Routes.Chat,
      sidePanel: SidePanel.AskReplika,
      sidePanelActiveLinks: [
        SidePanel.AskReplika,
        SidePanel.AISelfie,
        SidePanel.ImagesGenerator,
      ],
    },
    {
      name: 'memory',
      text: 'Memory',
      imgSrc: `${MAIN_PAGE_URL_BASE}/nav_icons/v2/memory.png`,
      route: {
        pathname: Routes.Memory,
        state: { source: 'main screen', showBackButton: false },
      },
    },
    {
      name: 'diary',
      text: 'Diary',
      imgSrc: `${MAIN_PAGE_URL_BASE}/nav_icons/v2/diary.png`,
      route: {
        pathname: Routes.Diary,
        state: { showBackButton: false },
      },
    },
    {
      name: 'profile',
      text: 'Profile',
      imgSrc: `${MAIN_PAGE_URL_BASE}/nav_icons/v2/profile.png`,
      route: {
        pathname: Routes.ReplikaProfile,
        state: { source: 'main screen', showBackButton: false },
      },
    },
    {
      name: 'store',
      text: 'Store',
      imgSrc: `${MAIN_PAGE_URL_BASE}/nav_icons/v2/store.png`,
      route: { pathname: Routes.Store, state: { showBackButton: false } },
    },
    {
      name: 'room',
      text: 'Room',
      imgSrc: `${MAIN_PAGE_URL_BASE}/nav_icons/v2/room.png`,
      route: {
        pathname: Routes.Room,
        state: { showBackButton: false },
      },
    },
  ];

  if (isMobile) {
    navLinks.push({
      name: 'settings',
      text: 'Settings',
      imgSrc: `${MAIN_PAGE_URL_BASE}/nav_icons/v2/settings.png`,
      route: toModalRoute(ModalRoutes.Settings),
    });
  }
  return navLinks;
}
