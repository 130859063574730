import { ComponentPropsWithRef, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { AriaButton, AriaButtonProps } from '../../../components/Buttons';
import Picture from '../../../components/Picture';
import Scrollable from '../../../components/Scrollable';
import { ReactComponent as ArrowIcon } from '../../../icons/LinkRightArrow.svg';
import { ReactComponent as LinkArrowIcon } from '../../../icons/LinkUpRightArrow.svg';
import { MAIN_PAGE_URL_BASE } from '../../../utils/uri';
import useGoBackModal from '../ModalRoutes/useGoBackModal';
import { ModalLayout } from '../ModalScreenLayout';

function SettingsLayoutRoot({ children }) {
  return (
    <>
      <ModalLayout.Popups />
      <ModalLayout.Root layout="right-sidebar">{children}</ModalLayout.Root>
    </>
  );
}

function SettingsLayoutHeader({ title }) {
  const { goBack, closeGoBack, navLevel } = useGoBackModal();

  const hasBack = navLevel > 1;

  return (
    <ModalLayout.Header>
      {hasBack ? (
        <ModalLayout.HeaderLeft>
          <ModalLayout.SidebarBackButton onClick={goBack} />
        </ModalLayout.HeaderLeft>
      ) : null}
      <SettingsHeaderTitle>{title}</SettingsHeaderTitle>

      <ModalLayout.HeaderRight>
        <ModalLayout.SidebarCloseButton onClick={closeGoBack} />
      </ModalLayout.HeaderRight>
    </ModalLayout.Header>
  );
}

const SettingsHeaderTitle = styled(ModalLayout.HeaderTitle)`
  text-align: center;
  font-size: 16px;

  &:first-child {
    text-align: left;
    font-size: 24px;
  }
`;

function SettingsLayoutContent({
  fill,
  children,
  ...rest
}: { fill?: boolean } & ComponentPropsWithRef<'div'>) {
  return (
    <SettingsLayoutContentRoot $fill={fill}>
      <SettingsContentScrollable>{children}</SettingsContentScrollable>
    </SettingsLayoutContentRoot>
  );
}

const SettingsLayoutContentRoot = styled(ModalLayout.Content)<{
  $fill?: boolean;
}>`
  flex: ${(p) => (p.$fill ? '1 1 auto' : '0 1 auto')};

  &:after {
    display: none;
  }
`;

const SettingsContentScrollable = styled(Scrollable)`
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-block: 20px;
`;

const SettingsLayoutGroup = styled.div`
  flex: 1 0 0%;
  width: 100%;
  padding-inline: 20px;
  padding-block: 10px;
  display: flex;
  flex-direction: column;
`;

const SettingsLayoutSeparator = styled.div`
  height: 1px;
  background: rgb(255 255 255 / 10%);
  margin-block: 10px;
  flex: 0 0 auto;
`;

const SettingsLayoutGap = styled.div`
  height: 5px;
  flex: 0 0 auto;
`;

const SettingsLayoutLinkGroup = styled(SettingsLayoutGroup)`
  padding-inline: 20px;
  padding-block: 0px;

  & > ${SettingsLayoutSeparator} {
    margin-inline: 10px;
  }
`;

const SettingsLayoutGroupTitle = styled.h3`
  font-family: ${(p) => p.theme.fonts.display};
  font-size: 16px;
  color: ${(p) => p.theme.fgColor};
  margin-block: 0 15px;
  padding-inline: 10px;
`;

function SettingsItem({
  title,
  subtitle,
  danger,
  actionIcon,
  ...rest
}: {
  title: ReactNode;
  subtitle?: ReactNode;
  danger?: boolean;
  actionIcon?: 'arrow' | 'link' | 'subscription-pro';
} & Omit<AriaButtonProps, 'title'>) {
  let actionEl: ReactNode = null;
  if (actionIcon === 'arrow') {
    actionEl = <ArrowIcon />;
  } else if (actionIcon === 'link') {
    actionEl = <LinkArrowIcon />;
  } else if (actionIcon === 'subscription-pro') {
    actionEl = (
      <Picture
        src={`${MAIN_PAGE_URL_BASE}/ProLabel.png`}
        style={{ width: '60px', height: 'auto', display: 'block' }}
        alt="PRO"
      />
    );
  }

  return (
    <SettingsItemRoot $danger={danger} {...rest}>
      {subtitle ? (
        <div>
          <SettingsItemTitle>{title}</SettingsItemTitle>
          <SettingsItemSubtitle>{subtitle}</SettingsItemSubtitle>
        </div>
      ) : (
        <SettingsItemTitle>{title}</SettingsItemTitle>
      )}
      {actionEl}
    </SettingsItemRoot>
  );
}

const SettingsItemRoot = styled(AriaButton)<{ $danger?: boolean }>`
  all: unset;
  width: 100%;
  flex: 0 0 auto;
  box-sizing: border-box;
  color: ${(p) => (p.$danger ? '#FE9E98' : p.theme.fgColor)};
  padding-inline: 10px;
  font-family: ${(p) => p.theme.fonts.display};
  cursor: pointer;
  border-radius: 19px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 54px;

  &:focus {
    box-shadow: none;
    outline: none;
    background: rgb(255 255 255 / 15%);
  }

  &:hover {
    text-decoration: none;
    background: rgb(255 255 255 / 10%);
  }

  & picture {
    line-height: 0;
  }
`;

const SettingsItemTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SettingsItemSubtitle = styled.p`
  margin-block: 3px 0;
  color: ${(p) => p.theme.superDimmedFgColor};
`;

export {
  SettingsLayoutContent as Content,
  SettingsLayoutGap as Gap,
  SettingsLayoutGroup as Group,
  SettingsLayoutGroupTitle as GroupTitle,
  SettingsLayoutHeader as Header,
  SettingsItem as Item,
  SettingsLayoutLinkGroup as LinkGroup,
  SettingsLayoutRoot as Root,
  SettingsLayoutSeparator as Separator,
};
