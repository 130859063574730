import styled from 'styled-components/macro';
import { AriaButton } from './Buttons';
import outlineCss from './outlineCss';

export const Checkbox = styled(AriaButton)`
  all: unset;
  display: flex;
  height: 24px;
  width: 24px;
  background: transparent;
  border: 1px solid rgb(255 255 255 / 30%);
  border-radius: 9px;
  color: #222;
  cursor: pointer;

  &:focus-visible:focus {
    ${outlineCss({ shadowSize: 'small', offset: '0' })}
  }

  &[data-active='true'] {
    border: 1px solid rgb(255 255 255);
    background: #fff url('/images/CheckIcon.svg');
    background-size: 8px 8px;
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
  }

  &[data-active='true']:focus-visible:focus {
    ${outlineCss({ shadowSize: 'small' })}
  }
`;
