import * as React from 'react';
import styled from 'styled-components/macro';
import { DayReward } from '../../../types/models';
import { VIDEO_URL_BASE } from '../../../utils/uri';
import { AccentButton } from '../../Buttons';
import Video from '../../Video';
import { CoinCounter, GemCounter } from './WalletIcons';

type Props = {
  className?: string;
  day: DayReward;
  current: boolean;
  claimed: boolean;
  onClaimClick: () => void;
  placeholder: boolean;
  subscribed: boolean;
  active: boolean;
  special: boolean;
  autoClaim?: boolean;
  claimStatus: 'idle' | 'claiming' | 'skipping' | 'claimed';
};

function DayRewardCard({
  className,
  day,
  current,
  onClaimClick,
  claimed,
  placeholder,
  subscribed,
  active,
  special,
  autoClaim,
  claimStatus,
}: Props) {
  const coinsBaseCount = day.base_bonus.coins_count;
  const coinsSubsCount = day.subscription_bonus.coins_count;
  const gemsBaseCount = day.base_bonus.gems_count;
  const gemSubsCount = day.subscription_bonus.gems_count;

  const canClaim = current && autoClaim;

  React.useEffect(() => {
    if (canClaim) {
      onClaimClick();
    }
  }, [canClaim, onClaimClick]);

  if (placeholder) {
    return (
      <DayRewardCardRoot
        className={className}
        $current={current}
        $active={active}
        $claimed={claimed}
      />
    );
  } else if (!day) {
    return null;
  }

  if (special) {
    return (
      <SpecialDayRewardCardRoot className={className} role="card">
        <SpecialRewardBgVideo
          src={
            VIDEO_URL_BASE +
            '/daily_reward_gift/daily_reward_gift.mp4#formats=h265,webm'
          }
          placeholder={
            VIDEO_URL_BASE +
            '/daily_reward_gift/daily_reward_gift_placeholder.jpg'
          }
          preload="true"
          playsInline
          autoPlay
          muted
          loop
          tabIndex={-1}
        />
        <SpecialRewardLeft>
          <SecretDayTitle>{`Day ${day.day}`}</SecretDayTitle>
          <Secret>Secret gift</Secret>
        </SpecialRewardLeft>

        <SpecialRewardRight>
          {current && !claimed ? (
            <SpecialDayClaimButton
              onClick={onClaimClick}
              disabled={claimStatus === 'claiming'}
            >
              Claim
            </SpecialDayClaimButton>
          ) : (
            <Bottom>{claimed && 'Claimed'}</Bottom>
          )}
        </SpecialRewardRight>
      </SpecialDayRewardCardRoot>
    );
  }

  return (
    <DayRewardCardRoot
      className={className}
      role="card"
      $current={current}
      $active={active}
      $claimed={claimed}
    >
      <DayTitle>{`Day ${day.day}`}</DayTitle>
      <Counters>
        {!!coinsBaseCount && (
          <StyledCoinCounter count={claimed ? 'check' : coinsBaseCount} />
        )}
        {!!coinsSubsCount && (
          <StyledCoinCounter
            count={claimed ? 'check' : coinsSubsCount}
            locked={!subscribed}
          />
        )}

        {!!gemsBaseCount && (
          <StyledGemCounter count={claimed ? 'check' : gemsBaseCount} />
        )}
        {!!gemSubsCount && (
          <StyledGemCounter
            count={subscribed && claimed ? 'check' : gemSubsCount}
            locked={!subscribed}
          />
        )}
      </Counters>

      {current && !claimed ? (
        <ClaimButton
          onClick={onClaimClick}
          disabled={claimStatus === 'claiming'}
        >
          Claim
        </ClaimButton>
      ) : (
        <Bottom>{claimed && 'Claimed'}</Bottom>
      )}
    </DayRewardCardRoot>
  );
}

export default DayRewardCard;

const DayRewardCardRoot = styled.li<{
  $current: boolean;
  $active: boolean;
  $claimed: boolean;
}>`
  position: relative;
  border-radius: 14px;
  min-height: 105px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${(p) =>
    p.$current ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255, 255, 255, 0.1)'};

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: ${(p) =>
      p.$claimed
        ? 'radial-gradient(140.83% 100% at 50% 0%, rgba(119, 220, 123, 0) 75%, rgba(119, 220, 123, 0.8) 100%)'
        : 'none'};
    border-radius: 0 0 14px 14px;
    z-index: 1;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 2px 2px ${(p) => p.theme.outlineColor};
  }
`;

const SpecialDayRewardCardRoot = styled.li`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 105px;
  border-radius: 14px;
  overflow: hidden;
  color: ${(p) => p.theme.fgColor};
`;

const DayTitle = styled.h4`
  margin-block: 3px 0;
  font-size: 12px;
`;

const ClaimButton = styled(AccentButton)`
  position: relative;
  width: 100%;
  height: 24px;
  border-radius: 9px;
  color: #5e5e5e;
  font-size: 12px;
  z-index: 2;
`;

const SpecialDayClaimButton = styled(ClaimButton)`
  font-size: 14px;
  border-radius: 14px;
  height: 34px;
  padding-inline: 15px;
`;

const Counters = styled.div`
  display: flex;
  flex: 1 0 auto;
  gap: 5px;
  justify-content: center;
  padding-top: 5%;
`;

const StyledGemCounter = styled(GemCounter)`
  width: 32px;
  height: 32px;
`;

const Bottom = styled.div`
  color: ${(p) => p.theme.fgColor};
  font-family: ${(p) => p.theme.fonts.display};
  padding-bottom: 7px;
  font-size: 12px;
  line-height: 12px;
  z-index: 2;
`;

const StyledCoinCounter = StyledGemCounter.withComponent(CoinCounter);

const SpecialRewardLeft = styled.div`
  text-align: center;
  margin-left: 27px;
  z-index: 2;
`;

const SpecialRewardRight = styled.div`
  z-index: 2;
  margin-right: 50px;
`;

const SpecialRewardBgVideo = styled(Video)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 14px;
`;

const SecretDayTitle = styled(DayTitle)`
  margin: 0;
`;

const Secret = styled.p`
  display: block;
  height: 20px;
  font-size: 12px;
  line-height: 12px;
  padding: 3px 9px;
  margin-block: 8px 0;
  background: rgba(255, 255, 255, 0.2);
  box-shadow:
    0px 0px 5px rgba(255, 255, 255, 0.2),
    inset 0px 0px 5px #ffffff;
  border-radius: 100vh;
  backdrop-filter: blur(4px);
`;
