import { push } from 'connected-react-router';
import { formAtom, useForm, useInputField } from 'form-atoms';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { checkAccountInfo } from '../../actions/signup';
import { Routes } from '../../components/Buttons';
import { LabeledTextInput } from '../../components/Inputs';
import PronounsSelector from '../../components/PronounsSelector';
import { DarkRadioButton } from '../../components/RadioButtonSelector';
import useAvatarSettings from '../../core/useAvatarSettings';
import { MetricsEvents, PronounsValues } from '../../types/enums';
import { ApiError } from '../../utils/apiError';
import { captureError } from '../../utils/initSentry';
import { setUserProperties } from '../../utils/metrics';
import { RecaptchaLegal, getRecaptchaToken } from '../../utils/recaptcha';
import useLogEvent from '../../utils/useLogEvent';
import useLogEventFirstRender from '../../utils/useLogEventFirstRender';
import useSubmitStatus from '../../utils/useSubmitStatus';
import {
  AuthForm,
  DesktopTitle,
  SubmitButton,
  SubmitError,
} from './AuthLayout';
import { authAtom, isSubmitDisabled, nameAtom, pronounsAtom } from './atoms';
import catchServerErrors from './catchServerErrors';
import usePreSignup from './usePreSignup';
import useUpdateDefaultModel from './useUpdateDefaultModel';

const PRONOUNS_ANALYTICS_MAP = {
  [PronounsValues.They]: 'they',
  [PronounsValues.He]: 'he/him',
  [PronounsValues.She]: 'she/her',
};

const nameFormAtom = formAtom({
  name: nameAtom,
});

export default function SignupYourNameAndPronouns() {
  const dispatch = useDispatch();
  const preSignup = usePreSignup();
  const submitStatus = useSubmitStatus();
  const authType = useAtomValue(authAtom);

  const [pronouns, setPronouns] = useAtom(pronounsAtom);

  const { fieldAtoms, submit } = useForm(nameFormAtom);
  const nameField = useInputField<'text'>(fieldAtoms.name);

  const logEvent = useLogEvent();

  useLogEventFirstRender(MetricsEvents.UserNameScreenOpened);

  const handleSubmit = submit(async (values) => {
    try {
      submitStatus.setStatus('submitting');
      await dispatch(checkAccountInfo({ name: values.name }));

      const recaptchaToken = await getRecaptchaToken();
      await preSignup(recaptchaToken);

      dispatch(push(Routes.SignupDateOfBirth));
      submitStatus.setStatus('success');

      logEvent(MetricsEvents.PronounSet, {
        pronouns: PRONOUNS_ANALYTICS_MAP[pronouns],
      });

      logEvent(MetricsEvents.UserAccountSet, {
        'first name': values.name,
        auth: authType ?? 'email',
      });

      setUserProperties({
        pronoun: pronouns,
      });
    } catch (e) {
      let error: string | null = 'Something happened. Please try again later';

      if (e instanceof ApiError) {
        logEvent(MetricsEvents.SignupError, { error: e.technicalMessage });

        error = catchServerErrors(e, { user_first_name: nameField });
      }

      submitStatus.setStatus('error', error);
      captureError(e);
    }
  });

  useAvatarSettings({
    hidden: true,
    cameraSlot: 'desktop_onboarding',
    baseBundleSet: 'onboarding',
  });

  const updateDefaultModel = useUpdateDefaultModel();

  useEffect(() => {
    updateDefaultModel();
  }, [updateDefaultModel]);

  const submitDisabled = isSubmitDisabled(nameField) || !pronouns;
  const nameError = nameField.state.errors?.[0] || null;

  return (
    <StyledAuthForm onSubmit={handleSubmit}>
      <DesktopTitle>Your name & pronouns</DesktopTitle>
      <LabeledTextInput
        id="signup-name"
        placeholder="Name"
        autoComplete="name"
        errors={nameField.state.errors}
        autoFocus
        {...nameField.props}
      />

      <StyledPronounsSelector
        value={pronouns}
        id="signup-pronouns"
        onChange={setPronouns}
        buttonTheme="darkSolid"
      />
      <SubmitError
        error={!nameError ? submitStatus.getError() : null}
        placeholder={<></>}
      />

      <OnboardingSubmitButton
        showSpinner={submitStatus.isSubmitting()}
        disabled={submitDisabled}
      >
        Continue
      </OnboardingSubmitButton>

      <StyledRecaptchaLegal />
    </StyledAuthForm>
  );
}

const StyledAuthForm = styled(AuthForm)`
  align-items: center;
`;

const StyledPronounsSelector = styled(PronounsSelector)`
  padding: 0;
  margin: 0 auto;
  width: 100%;
  flex-direction: row;
  gap: 5px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 350px;
    & > button {
      flex-basis: 0%;
    }
  }

  ${DarkRadioButton} {
    border-radius: 19px;
    font-size: 14px;
    line-height: 18px;
    height: 44px;
    padding: 0 10px;
  }
`;

const StyledRecaptchaLegal = styled(RecaptchaLegal)`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 15px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    left: 50%;
    right: auto;
    bottom: 0;
    transform: translateX(-50%);
  }
`;

export const OnboardingSubmitButton = styled(SubmitButton)`
  margin: 30px auto 0;
`;
