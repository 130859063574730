import * as RadixSelect from '@radix-ui/react-select';
import { ReactNode, forwardRef, useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as ArrowDownIcon } from '../icons/ArrowDownSmall.svg';
import outlineCss, { errorOutlineCss } from './outlineCss';

type SelectProps = {
  id: string;
  label?: string;
  placeholder?: string;
  onChange: (value: string) => void;
  invalid?: boolean;
  disabled?: boolean;
  className?: string;
  showArrow?: boolean;
  dropdown: ReactNode;
  renderedValue?: ReactNode;
  beforeValue?: ReactNode;
  onFocus?: (e: React.FocusEvent<HTMLElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
  'aria-label'?: string;
} & RadixSelect.SelectProps;

function Select(
  {
    id,
    'aria-label': ariaLabel,
    placeholder,
    onChange,
    invalid,
    disabled,
    className,
    showArrow,
    dropdown,
    renderedValue,
    beforeValue,
    onFocus,
    onBlur,
    ...rest
  }: SelectProps,
  forwardedRef,
) {
  return (
    <SelectRoot onValueChange={onChange} disabled={disabled} {...rest}>
      <SelectTrigger
        id={id}
        ref={forwardedRef}
        className={className}
        aria-invalid={invalid}
        aria-disabled={disabled}
        aria-label={ariaLabel}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        {beforeValue}
        <RadixSelect.Value
          placeholder={<SelectPlaceholder>{placeholder}</SelectPlaceholder>}
        >
          {renderedValue}
        </RadixSelect.Value>
        {showArrow && (
          <RadixSelect.Icon>
            <SelectArrowDown />
          </RadixSelect.Icon>
        )}
      </SelectTrigger>

      {dropdown}
    </SelectRoot>
  );
}

function SelectRoot(props: RadixSelect.SelectProps) {
  const [open, setOpen] = useState(false);
  return (
    <RadixSelect.Root
      open={open}
      onOpenChange={(open) => {
        // preventing a bug (click triggered in underlying element)
        // @see https://github.com/radix-ui/primitives/issues/1658
        if ('ontouchstart' in window) {
          if (open) {
            setOpen(true);
          } else {
            setTimeout(() => {
              setOpen(false);
            }, 10);
          }
        } else {
          setOpen(open);
        }
      }}
      {...props}
    />
  );
}

const SelectDefault = forwardRef(Select);

const SelectTrigger = styled(RadixSelect.Trigger)`
  position: relative;
  width: fit-content;
  box-sizing: border-box;
  transition: box-shadow 0.15s ease-out;
  color: ${(p) => p.theme.fgColor};
  border: 0;
  background-color: rgba(255 255 255 / 10%);
  backdrop-filter: blur(50px);
  font-family: ${(p) => p.theme.fonts.display};
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 34px;
  border-radius: 14px;

  &[aria-invalid='true'] {
    ${errorOutlineCss()}
  }

  &[data-disabled] {
    color: rgba(255 255 255 / 20%);
    cursor: not-allowed;
  }

  &[data-state='open'],
  &:focus-visible:focus {
    box-shadow: 0px 0px 5px ${(p) => p.theme.outlineColor};
    position: relative;
    ${outlineCss()}
  }
`;

const SelectPlaceholder = styled.span`
  color: rgba(255 255 255 / 20%);
`;

export const SelectDropdown = styled(RadixSelect.Content)`
  background: rgb(0 0 0 / 0.1);
  backdrop-filter: blur(25px);
  border: 0;
  border-radius: 24px;
  color: #fff;
  width: var(--radix-select-trigger-width);
  z-index: 2;
`;

export const SelectItem = forwardRef<RadixSelect.SelectItemProps, any>(
  ({ children, ...props }, forwardedRef) => {
    return (
      <SelectItemRoot {...props} ref={forwardedRef}>
        <RadixSelect.ItemText>{children}</RadixSelect.ItemText>
      </SelectItemRoot>
    );
  },
);

const SelectItemRoot = styled(RadixSelect.Item)`
  user-select: none;

  position: relative;
  font-family: ${(p) => p.theme.fonts.display};
  color: rgba(255 255 255 / 60%);
  text-wrap: nowrap;

  height: 34px;
  padding: 10px;
  border-radius: 14px;
  font-size: 14px;

  &[data-disabled] {
    color: #eee;
    pointer-events: none;
  }

  &[data-highlighted] {
    outline: none;
    background-color: rgba(255 255 255 / 15%);
    color: #fff;
  }
`;

const SelectScrollDownButton = styled(RadixSelect.ScrollDownButton)`
  height: 24px;
  border-radius: 14px;
  text-align: center;
  color: rgba(255 255 255 / 60%);

  &:hover {
    background-color: rgba(255 255 255 / 15%);
    color: #fff;
  }
`;

const SelectScrollUpButton = styled(RadixSelect.ScrollUpButton)`
  height: 24px;
  border-radius: 14px;
  text-align: center;
  color: rgba(255 255 255 / 60%);

  &:hover {
    background-color: rgba(255 255 255 / 15%);
    color: #fff;
  }
`;

const SelectArrowDown = styled(ArrowDownIcon)`
  display: block;
`;

const SelectArrowUp = styled(ArrowDownIcon)`
  display: block;
  transform: scaleY(-1);
`;

const SelectIcon = RadixSelect.Icon;

const SelectValue = RadixSelect.Value;

const SelectPortal = RadixSelect.Portal;

export {
  SelectArrowDown as ArrowDown,
  SelectArrowUp as ArrowUp,
  SelectIcon as Icon,
  SelectPlaceholder as Placeholder,
  SelectPortal as Portal,
  SelectRoot as Root,
  SelectScrollDownButton as ScrollDownButton,
  SelectScrollUpButton as ScrollUpButton,
  SelectTrigger as Trigger,
  SelectValue as Value,
  SelectDefault as default,
};
