import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useAtom, useAtomValue } from 'jotai';
import { updateBot } from '../../../actions/profile';
import { buyUserItems } from '../../../actions/store';
import { AccentButton } from '../../../components/Buttons';
import PriceTag from '../../../components/PriceTag';
import { avatarPreviewAtom, basketAtom } from '../../../core/atoms';
import { Routes } from '../../../types/enums';
import { Credit, isStoreVoiceItem } from '../../../types/models';

type SumPrice = {
  gems_count: number;
  coins_count: number;
};

function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

type PurchaseBasketFooterProps = {
  sumPrice: SumPrice;
  onError: (message: string | null) => void;
  onClose: () => void;
};

function PurchaseBasketFooter({
  sumPrice,
  onError,
  onClose,
}: PurchaseBasketFooterProps) {
  const dispatch = useDispatch();
  const [basket, setBasket] = useAtom(basketAtom);
  const avatarV2 = useSelector((state) => state.profile.persist.bot?.avatar_v2);
  const { variations, roomItems, pets } = useAtomValue(avatarPreviewAtom);
  const selectedVariations = variations ?? [];
  const selectedRoomVariations = roomItems ?? [];
  const selectedPets = pets ?? [];

  const [purchaseState, setPurchaseState] = React.useState<
    'idle' | 'purchasing'
  >('idle');

  const handleBuyItemClick = async () => {
    onError(null);
    if (basket.length > 0 && purchaseState === 'idle') {
      setPurchaseState('purchasing');
      try {
        const variations = basket
          .map(
            (item) => item.storeItem.variations[item.variationIndex ?? 0]?.id,
          )
          .filter(nonNullable);
        const voiceToBuy = basket
          .map((item) =>
            isStoreVoiceItem(item.storeItem)
              ? item.storeItem.variations[0]?.internal_voice_id
              : null,
          )
          .filter(nonNullable)?.[0];
        const itemsToBuy = variations.map((id) => ({
          id,
          count: 1,
        }));
        if (voiceToBuy) {
          variations.push(voiceToBuy);
        }
        await dispatch(buyUserItems(itemsToBuy));

        if (voiceToBuy) {
          await dispatch(
            updateBot({
              voice_id: voiceToBuy,
            }),
          );
        }
        if (avatarV2) {
          await dispatch(
            updateBot({
              avatar_v2: {
                ...avatarV2,
                active_variations: selectedVariations,
              },
              room_items: selectedRoomVariations,
              pets: selectedPets,
            }),
          );
        }

        onClose();
        setBasket([]);
      } catch (e) {
        if (e instanceof Error) {
          onError(e.message);
        }
      } finally {
        setPurchaseState('idle');
      }
    }
  };

  return sumPrice.coins_count + sumPrice.gems_count > 0 ? (
    <AccentButton
      showSpinner={purchaseState !== 'idle'}
      onClick={handleBuyItemClick}
    >
      Buy for <PriceTag price={sumPrice} />
    </AccentButton>
  ) : null;
}

type ExtraCoinsNeededFooterProps = {
  currencyNeeded: SumPrice;
  onClose: () => void;
};

function ExtraCoinsNeededFooter({
  currencyNeeded,
  onClose,
}: ExtraCoinsNeededFooterProps) {
  return (
    <>
      <ExtraNeeded>
        You need <PriceTag price={currencyNeeded} /> extra
      </ExtraNeeded>
      <AccentButton
        to={{
          pathname: Routes.Wallet,
          state: {
            source: 'store',
          },
        }}
      >
        Get more coins and gems
      </AccentButton>
    </>
  );
}

type Props = {
  credit: null | Credit;
  sumPrice: SumPrice;
  onError: (message: string | null) => void;
  onClose: (status: 'success' | 'goToWallet') => void;
  className?: string;
};

function getCurrencyNeeded(price: SumPrice, credit: Credit | null) {
  if (!credit) return null;

  const coinsNeeded = price.coins_count - credit.coins_count;
  const gemsNeeded = price.gems_count - credit.gems_count;

  if (coinsNeeded > 0 || gemsNeeded > 0) {
    return {
      coins_count: Math.max(0, coinsNeeded),
      gems_count: Math.max(0, gemsNeeded),
    } as const;
  }

  return null;
}

function BasketFooter({
  credit,
  sumPrice,
  onError,
  onClose,
  className,
}: Props) {
  const currencyNeeded = getCurrencyNeeded(sumPrice, credit);

  if (currencyNeeded) {
    return (
      <BasketFooterRoot className={className}>
        <ExtraCoinsNeededFooter
          currencyNeeded={currencyNeeded}
          onClose={() => onClose('goToWallet')}
        />
      </BasketFooterRoot>
    );
  } else {
    return (
      <BasketFooterRoot className={className}>
        <PurchaseBasketFooter
          sumPrice={sumPrice}
          onError={onError}
          onClose={() => onClose('success')}
        />
      </BasketFooterRoot>
    );
  }
}

export default BasketFooter;

const ExtraNeeded = styled.div`
  font-size: 12px;
  font-family: ${(p) => p.theme.fonts.display};
  margin: 10px;
`;

const BasketFooterRoot = styled.div`
  flex: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;
`;
