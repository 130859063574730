import ls from 'local-storage';
import shajs from 'sha.js';

import { push } from 'connected-react-router';
import { format } from 'date-fns';
import { matchPath } from 'react-router';
import { getNewMemory } from '../features/Memory/actions';
import * as A from '../types/actions';
import { WsInitReponse } from '../types/actions/init';
import {
  AsyncActionTypes as AAT,
  ActionTypes as AT,
  AgeGateStatus,
  MetricsEvents,
  Routes,
  WsEventNames,
} from '../types/enums';
import { Bot } from '../types/models';
import { DA, TDA } from '../types/redux';
import { RootState } from '../types/states';
import { AB_METRICS_CURRENT_EXPERIMENT } from '../utils/ab';
import { wsAction } from '../utils/asyncAction';
import {
  APP_VERSION,
  CAPABILITIES,
  DEV_ONLY_CAPABILITIES,
} from '../utils/constants';
import getUnityBundleVersion from '../utils/getUnityBundleVersion';
import { captureError, setUser } from '../utils/initSentry';
import {
  logEvent,
  setUserProperties,
  initMetrics as utilsInitMetrics,
} from '../utils/metrics';
import { sendMessage } from '../utils/websocket';
import { sendLogOutRequest } from './auth';
import { getHistory, getPersonalBotChat, getSuggestions } from './chat';
import { getPersonalBot, getRelatioshipStatuses } from './profile';
import { getAdvancedAiInfo, getLoginReward } from './store';
import { connectWebsocket } from './websocket';

export const initPersist =
  () => (dispatch: TDA<A.InitAction>, getState: () => RootState) => {
    const state = getState();
    const persistKeys = Object.keys(state).filter((reducerName) =>
      state[reducerName].hasOwnProperty('persist'),
    );

    dispatch({
      type: AT.InitPersist,
    });

    persistKeys.forEach((reducerName) => {
      ls.on(reducerName, (persist) => {
        dispatch(updatePersist(reducerName, persist));
      });
    });
  };

export const initMetrics =
  () => (dispatch: TDA<A.InitAction>, getState: () => RootState) => {
    const state = getState();
    const userId = state.auth.persist.userId;

    utilsInitMetrics(userId);

    setUserProperties({
      unity_content_version: getUnityBundleVersion(),
      app_version: APP_VERSION,
    });

    /**
     * setUserProperties util for AB example
     */
    if (AB_METRICS_CURRENT_EXPERIMENT) {
      setUserProperties({
        ab_testing_group: AB_METRICS_CURRENT_EXPERIMENT.enabled
          ? 'group_a'
          : 'control_group',
        'Experiment name': AB_METRICS_CURRENT_EXPERIMENT.name,
      });
    }

    logEvent(
      MetricsEvents.Launched,
      {
        authUserId: userId,
      },
      userId,
    );

    dispatch({
      type: AT.InitMetrics,
      userId,
    });
  };

const updatePersist = (reducerName: string, persist: object): A.InitAction => ({
  type: AT.UpdatePersist,
  reducerName,
  persist,
});

export const resetPersist = (ignoredReducers: string[]): A.InitAction => ({
  type: AT.ResetPersist,
  ignoredReducers,
});

export const checkDeviceId = (): A.InitAction => ({
  type: AT.CheckDeviceId,
});

function getDefaultInitPayload(isDevUser: boolean) {
  const capabilities =
    !isDevUser && DEV_ONLY_CAPABILITIES.length > 0
      ? CAPABILITIES.filter((cap) => DEV_ONLY_CAPABILITIES.indexOf(cap) === -1)
      : CAPABILITIES;

  return {
    device: 'web',
    platform: 'web',
    platform_version: window.navigator.userAgent,
    app_version: APP_VERSION,

    capabilities,
  };
}

let firstInit = true;

export const initChatWithHistory = (): DA => async (dispatch, getState) => {
  try {
    // ORDER MATTERS.
    await initChat()(dispatch, getState);

    if (firstInit) {
      await applicationStarted()(dispatch, getState);
      await appForeground()(dispatch, getState);
      firstInit = false;
    }

    // invalidate cached history in case we had some push messages "squashed"
    // (or just some previous messages deleted in general)
    await getHistory(undefined, 30, true)(dispatch, getState);
    await getSuggestions()(dispatch, getState);
    await getAdvancedAiInfo()(dispatch, getState);
    await getNewMemory()(dispatch, getState);
  } catch (err) {
    console.error(err);
    captureError(err);

    if (err instanceof Error || typeof err === 'string') {
      if (
        /(Device .* not found for user|Can't find user|Invalid auth token)/.test(
          err.toString(),
        )
      ) {
        sendLogOutRequest(true)(dispatch, getState);
      } else if (err.toString().indexOf('Trial period expired') !== -1) {
        dispatch<A.InitAction>({
          type: AT.TrialPeriodExpired,
        });
      }
    }
  }
};

export const appForeground = (): DA<{}> => async (dispatch, getState) => {
  const state = getState();

  return wsAction(
    AAT.WsAppForeground,
    dispatch,
    {},
    {
      onRequest: () =>
        sendMessage(
          {
            event_name: WsEventNames.AppForeground,
            payload: {},
          },
          state,
        ),
    },
  );
};

export const mainScreen = (): DA<{}> => async (dispatch, getState) => {
  const state = getState();

  return wsAction(
    AAT.WsMainScreen,
    dispatch,
    {},
    {
      onRequest: () =>
        sendMessage(
          {
            event_name: WsEventNames.MainScreen,
            payload: {},
          },
          state,
        ),
    },
  );
};

export const chatScreen = (): DA<{}> => async (dispatch, getState) => {
  const state = getState();

  return wsAction(
    AAT.WsChatScreen,
    dispatch,
    {},
    {
      onRequest: () =>
        sendMessage(
          {
            event_name: WsEventNames.ChatScreen,
            payload: {},
          },
          state,
        ),
    },
  );
};

export const initChat = (): DA<{ bot: Bot }> => async (dispatch, getState) => {
  const state = getState();
  const {
    ws: {
      persist: { chatId: persistedChatId },
    },
    auth: {
      persist: { userId, deviceId },
    },
  } = state;

  if (userId) {
    setUser({ id: userId, deviceId });
  }

  await connectWebsocket()(dispatch, getState);
  await init()(dispatch, getState);

  // let chatId = persistedChatId;
  // if (!chatId) {
  const chat = await getPersonalBotChat()(dispatch, getState);

  // chatId = chat.id;

  if (persistedChatId !== chat.id) {
    dispatch<A.InitAction>({ type: AT.SetDefaultChat, chatId: chat.id });
  }
  // }

  if (userId) {
    getRelatioshipStatuses()(dispatch, getState);
    getLoginReward()(dispatch, getState);
  }

  const bot = await getPersonalBot()(dispatch, getState);

  dispatch({ type: AT.ChatInitialized });

  return { bot };
};

export const init = (): DA<WsInitReponse> => async (dispatch, getState) => {
  const state = getState();
  const { deviceId, authToken, userId } = state.auth.persist;

  const secToken = btoa(
    shajs('sha512')
      .update(
        'db97531fdb97531eca86420eca86420db97531fdb97531eca86420eca86420db' +
          deviceId,
      )
      .digest('hex'),
  );

  return wsAction<A.Init>(
    AAT.WsInit,
    dispatch,
    {},
    {
      onRequest: () =>
        sendMessage(
          {
            event_name: WsEventNames.Init,
            payload: {
              device_id: deviceId,
              user_id: userId,
              auth_token: authToken,
              security_token: secToken,
              time_zone: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.Sxxx"),
              unity_bundle_version: getUnityBundleVersion(),
              ...getDefaultInitPayload(state.ws.persist.isDevUser),
            },
          },
          state,
        ),
    },
  );
};

export const applicationStarted = (): DA<{}> => async (dispatch, getState) => {
  const state = getState();
  const { chatId } = state.ws.persist;

  if (!chatId) {
    throw Error('no chat id');
  }

  return wsAction(
    AAT.WsApplicationStarted,
    dispatch,
    {},
    {
      onRequest: () =>
        sendMessage(
          {
            event_name: WsEventNames.ApplicationStarted,
            payload: {
              chat_id: chatId,
            },
          },
          state,
        ),
    },
  );
};

export const initRouting =
  () => (dispatch: TDA<any>, getState: () => RootState) => {
    const state = getState();

    const onboardingStatus = state.signup.persist.onboardingStatus;
    const userProfile = state.profile.persist.userProfile;

    const inSignup = matchPath(window.location.pathname, {
      path: Routes.Signup,
    });

    if (userProfile?.birthday_status === AgeGateStatus.Locked && inSignup) {
      dispatch(push(Routes.SignupDateOfBirth));
      return;
    }

    if (onboardingStatus === 'postsignup') {
      dispatch(push(Routes.SignupDateOfBirth));
    } else if (onboardingStatus === 'posttokenlogin') {
      dispatch(push(Routes.SignupYourPassword));
    } else if (onboardingStatus === 'subscription') {
      dispatch(push(Routes.SignupSubscription));
    } else if (inSignup) {
      dispatch(push(Routes.Signup));
    }
  };
