import { ButtonWithSpinnerProps } from 'src/components/Buttons';
import outlineCss from 'src/components/outlineCss';
import { ReactComponent as PenIcon } from 'src/icons/Pen.svg';
import { ReactComponent as TrashIcon } from 'src/icons/Trash.svg';
import { ModalForm } from 'src/routes/modals/ModalForm';
import styled from 'styled-components/macro';

const SmallControlButton = styled(ModalForm.ControlButton)`
  width: 34px;
  height: 34px;
  border-radius: 14px;
`;

const UploadPersonImageButtonRoot = styled.div`
  width: 184px;
  height: 184px;
  margin: 30px 0 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
  flex: 0 0 auto;
`;

const FileInputLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: ${(p) => p.theme.fonts.display};
  font-size: 16px;
  line-height: 24px;
  border-radius: 50%;
`;

const EditFileInputLabel = styled(FileInputLabel)`
  border-radius: 14px;
`;

const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  &:hover + label {
    cursor: pointer;
  }

  &:focus-visible:focus + label {
    ${outlineCss()}
  }
`;

const filterImageFiles = (files: FileList | null) =>
  files ? Array.from(files).filter((f: File) => /^image\//.test(f.type)) : [];

const UploadPersonImageButton = ({
  id,
  className,
  onUpload,
  disabled,
}: {
  id: string;
  onUpload: (files: File[]) => void;
  className?: string;
  disabled?: boolean;
}) => (
  <UploadPersonImageButtonRoot role="button" className={className}>
    <FileInput
      accept="image/*"
      type="file"
      name={id}
      id={id}
      onChange={(e) => onUpload(filterImageFiles(e.target.files))}
      disabled={disabled}
    />
    <FileInputLabel htmlFor={id}>
      Add <br /> photo
    </FileInputLabel>
  </UploadPersonImageButtonRoot>
);

const EditPersonImageButton = ({
  id,
  className,
  onUpload,
  disabled,
}: {
  id: string;
  onUpload: (files: File[]) => void;
  className?: string;
  disabled?: boolean;
}) => (
  <SmallControlButton as="div" className={className}>
    <FileInput
      accept="image/*"
      type="file"
      name={id}
      id={id}
      onChange={(e) => onUpload(filterImageFiles(e.target.files))}
      disabled={disabled}
      aria-label="Edit photo"
    />

    <EditFileInputLabel htmlFor={id}>
      <PenIcon />
    </EditFileInputLabel>
  </SmallControlButton>
);

const DeletePersonImageButton = (props: ButtonWithSpinnerProps) => (
  <SmallControlButton type="button" {...props}>
    <TrashIcon />
  </SmallControlButton>
);

export {
  DeletePersonImageButton,
  EditPersonImageButton,
  UploadPersonImageButton,
};
