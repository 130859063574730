import styled from 'styled-components/macro';

export const UpperBadge = styled.span`
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  margin: 0 auto;
  min-width: 70px;
  max-width: fit-content;
  height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-image: linear-gradient(
    92deg,
    rgba(214, 165, 253, 0.9) 0%,
    rgba(137, 99, 245, 0.9) 72%
  );
  border-radius: 3px 3px 20px 20px;
`;

export const UpperBadgeText = styled.span`
  padding: 2px 15px 0;
  font-size: 12px;
  line-height: 14px;
  font-family: ${(p) => p.theme.fonts.display};
  white-space: nowrap;
  color: ${(p) => p.theme.fgColor};
`;
