import * as A from '../types/actions';
import { ToError, ToRequest, ToSuccess } from '../types/asyncActions';
import { AsyncActionTypes as AAT, ActionTypes as AT } from '../types/enums';
import { VoicesMap } from '../types/models';
import { Reducer } from '../types/redux';
import { ProfileState, Progress } from '../types/states';
import applyReducer from '../utils/applyReducer';
import isMobileClient from '../utils/isMobileClient';

const DEFAULT_STATE: ProfileState = {
  getPersonalBotProgress: Progress.initial,
  isFetchingProfile: false,
  serverError: undefined,
  updateEmailProgress: Progress.initial,
  updatePasswordProgress: Progress.initial,
  updateProfileProgress: Progress.initial,
  updateReplikaProgress: Progress.initial,
  persist: {
    version: 4,
    bot: undefined,
    userProfile: undefined,
    avatarMode: isMobileClient() ? 'static' : 'auto',
    relationshipStatuses: [],
    userInterests: null,
    voices: [],
    coreDescription: undefined,
    voicesMap: null,
  },
  getHelpConfig: undefined,
  getHelpConfigStatus: 'initial',
  getHelpConfigError: undefined,
  relationshipStatusToUpdate: undefined,
};

type R<X extends A.ProfileAction | A.IncomingWsAction> = Reducer<
  ProfileState,
  X
>;
type RRequest<X> = Reducer<ProfileState, ToRequest<X>>;
type RError<X> = Reducer<ProfileState, ToError<X>>;
type RSuccess<X> = Reducer<ProfileState, ToSuccess<X>>;

const getBotRequest: RRequest<A.GetPersonalBot> = (state) => ({
  ...state,
  getPersonalBotProgress: Progress.sending,
});

const getBotError: RError<A.GetPersonalBot> = (state) => ({
  ...state,
  getPersonalBotProgress: Progress.error,
});

const getBotSuccess: RSuccess<A.GetPersonalBot> = (state, { result: bot }) => ({
  ...state,
  getPersonalBotProgress: Progress.success,
  persist: {
    ...state.persist,
    bot,
  },
});

const getUserProfileRequest: RRequest<A.GetUserProfile> = (state) => ({
  ...state,
  isFetchingProfile: true,
});

const getUserProfileError: RError<A.GetUserProfile> = (state) => ({
  ...state,
  isFetchingProfile: false,
});

const getUserProfileSuccess: RSuccess<A.GetUserProfile> = (
  state,
  { result: userProfile },
) => ({
  ...state,
  isFetchingProfile: false,
  persist: {
    ...state.persist,
    userProfile,
  },
});

const updateBotStats: R<A.BotStatsReceived> = (state, payload) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      bot: state.persist.bot && {
        ...state.persist.bot,
        stats: payload.stats,
      },
    },
  };
};

const recieveBotProfile: R<A.BotReceived> = (state, { bot }) => ({
  ...state,
  persist: {
    ...state.persist,
    bot,
  },
});

const updatePasswordRequest: RRequest<A.UpdateUserPassword> = (state) => {
  return {
    ...state,
    updatePasswordProgress: Progress.sending,
    serverError: undefined,
  };
};

const updatePasswordSuccess: RSuccess<A.UpdateUserPassword> = (state) => {
  return {
    ...state,
    updatePasswordProgress: Progress.success,
  };
};

const updatePasswordError: RError<A.UpdateUserPassword> = (
  state,
  { error },
) => {
  return {
    ...state,
    serverError: error.message,
    updatePasswordProgress: Progress.error,
  };
};

const updateBotRequest: RRequest<A.UpdateBot> = (state) => {
  return {
    ...state,
    updateReplikaProgress: Progress.sending,
    serverError: undefined,
  };
};

const updateBotSuccess: RSuccess<A.UpdateBot> = (state, { result: bot }) => {
  return {
    ...state,
    updateReplikaProgress: Progress.success,
    persist: {
      ...state.persist,
      bot,
    },
  };
};

const updateBotError: RError<A.UpdateBot> = (state, { error }) => {
  return {
    ...state,
    serverError: error.message,
    updateReplikaProgress: Progress.error,
  };
};

const updateEmailRequest: RRequest<A.UpdateUserEmail> = (state) => {
  return {
    ...state,
    updateEmailProgress: Progress.sending,
    serverError: undefined,
  };
};

const updateEmailSuccess: RSuccess<A.UpdateUserEmail> = (state, { result }) => {
  return {
    ...state,
    updateEmailProgress: Progress.success,
    serverError: undefined,
  };
};

const updateEmailError: RError<A.UpdateUserEmail> = (state, { error }) => {
  return {
    ...state,
    updateEmailProgress: error ? Progress.error : Progress.success,
    serverError: error.message,
  };
};

const updateProfileRequest: RRequest<A.UpdateUserProfile> = (state) => {
  return {
    ...state,
    updateProfileProgress: Progress.sending,
    serverError: undefined,
  };
};

const updateProfileSuccess: RSuccess<A.UpdateUserProfile> = (
  state,
  { result: userProfile },
) => {
  return {
    ...state,
    updateProfileProgress: Progress.success,
    persist: {
      ...state.persist,
      userProfile,
    },
    relationshipStatusToUpdate: undefined,
  };
};

const updateProfileError: RError<A.UpdateUserProfile> = (state, { error }) => {
  return {
    ...state,
    updateProfileProgress: Progress.error,
    serverError: error.message,
  };
};

const resetProfile = () => ({ ...DEFAULT_STATE });

// TODO: separate method for each page
const resetServerError: R<A.ResetServerError> = (state) => ({
  ...state,
  serverError: undefined,
  updateEmailProgress: Progress.initial,
  updatePasswordProgress: Progress.initial,
  updateProfileProgress: Progress.initial,
  updateReplikaProgress: Progress.initial,
});

const getVoicesSuccess: RSuccess<A.GetVoices> = (state, { result: voices }) => {
  const voicesMap: VoicesMap = {};
  voices.map((voice) => (voicesMap[voice.id] = voice));
  return {
    ...state,
    persist: {
      ...state.persist,
      voicesMap,
    },
  };
};

const setAvatarMode: R<A.SetAvatarMode> = (state, { avatarMode }) => ({
  ...state,
  persist: {
    ...state.persist,
    avatarMode,
  },
});

const resetAvatarCustomization: R<A.ResetAvatarCustomization> = (state) => {
  if (state.persist.bot?.avatar_v2) {
    return {
      ...state,
      persist: {
        ...state.persist,
        bot: {
          ...state.persist.bot,
          avatar_v2: {
            ...state.persist.bot.avatar_v2,
            active_variations: [],
          },
        },
      },
    };
  } else {
    return state;
  }
};

const getRelatioshipStatusesSuccess: RSuccess<A.GetRelatioshipStatuses> = (
  state,
  { result: { statuses: relationshipStatuses } },
) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      relationshipStatuses,
    },
  };
};

const setRelationshipStatusToUpdate: R<A.SetRelationshipStatusToUpdate> = (
  state,
  { relationshipStatusToUpdate },
) => ({
  ...state,
  relationshipStatusToUpdate,
});

const getUserInterestsSuccess: RSuccess<A.GetUserInterests> = (
  state,
  { result: { interests } },
) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      userInterests: interests,
    },
  };
};

const wsProfileUpdateReceived: R<A.ProfileReceived> = (state, { profile }) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      userProfile: profile,
    },
  };
};

const getDialogModelsSuccess: RSuccess<A.GetDialogModels> = (
  state,
  { result },
) => ({
  ...state,
  dialogVersions: result,
});

const setCoreDescription: RSuccess<A.GetCoreDescription> = (
  state,
  { result },
) => ({
  ...state,
  persist: {
    ...state.persist,
    coreDescription: result,
  },
});

const wsBotMoodReceived: R<A.BotMoodReceived> = (state, { botMood }) => {
  return {
    ...state,
    persist: {
      ...state.persist,
      bot: state.persist.bot && {
        ...state.persist.bot,
        mood_v2: botMood,
      },
    },
  };
};

export default function profile(
  state: ProfileState = DEFAULT_STATE,
  action: A.AnyAction,
) {
  return applyReducer(
    'profile',
    {
      [AAT.GetPersonalBot]: {
        request: getBotRequest,
        success: getBotSuccess,
        error: getBotError,
      },
      [AAT.UpdateBot]: {
        request: updateBotRequest,
        success: updateBotSuccess,
        error: updateBotError,
      },
      [AT.BotStatsReceived]: updateBotStats,
      [AT.BotReceived]: recieveBotProfile,
      [AAT.Logout]: {
        success: resetProfile,
        error: resetProfile,
      },
      [AAT.DeleteAccount]: {
        success: resetProfile,
      },
      [AAT.GetUserProfile]: {
        request: getUserProfileRequest,
        success: getUserProfileSuccess,
        error: getUserProfileError,
      },
      [AAT.UpdateUserPassword]: {
        request: updatePasswordRequest,
        success: updatePasswordSuccess,
        error: updatePasswordError,
      },
      [AAT.UpdateUserEmail]: {
        request: updateEmailRequest,
        success: updateEmailSuccess,
        error: updateEmailError,
      },
      [AAT.SetPasswordAndEmail]: {
        request: updateEmailRequest,
        success: updateEmailSuccess,
        error: updateEmailError,
      },
      [AAT.UpdateUserProfile]: {
        request: updateProfileRequest,
        success: updateProfileSuccess,
        error: updateProfileError,
      },
      [AAT.GetVoices]: {
        success: getVoicesSuccess,
      },
      [AT.ResetServerError]: resetServerError,
      [AT.SetAvatarMode]: setAvatarMode,
      [AT.ResetAvatarCustomization]: resetAvatarCustomization,
      [AAT.GetRelatioshipStatuses]: {
        success: getRelatioshipStatusesSuccess,
      },
      [AT.SetRelationshipStatusToUpdate]: setRelationshipStatusToUpdate,
      [AAT.GetUserInterests]: {
        success: getUserInterestsSuccess,
      },
      [AT.WsProfileReceived]: wsProfileUpdateReceived,
      [AAT.GetDialogModels]: {
        success: getDialogModelsSuccess,
      },
      [AAT.GetCoreDescription]: {
        success: setCoreDescription,
      },
      [AAT.UpdateBackstory]: {
        success: setCoreDescription,
      },
      [AAT.UpdateActLike]: {
        success: setCoreDescription,
      },
      [AT.WsBotMoodReceived]: wsBotMoodReceived,
    },
    state,
    action,
  );
}
