import { useStripe } from '@stripe/react-stripe-js';
import { PaymentRequestOptions, Stripe } from '@stripe/stripe-js';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components/macro';
import { ReactComponent as AmericanExpressCardIcon } from '../../icons/Cards/card_american_express.svg';
import { ReactComponent as JCBCardIcon } from '../../icons/Cards/card_jcb.svg';
import { ReactComponent as MasterCardCardIcon } from '../../icons/Cards/card_mastercard.svg';
import { ReactComponent as UnionPayCardIcon } from '../../icons/Cards/card_unionpay.svg';
import { ReactComponent as VisaCardIcon } from '../../icons/Cards/card_visa.svg';
import { ReactComponent as SecureIcon } from '../../icons/Cards/secure.svg';
import { dialogMobileMedia } from '../../utils/mobileMedia';
import StripeBadge from '../StripeBadge';
import StripeCardInput from './StripeCardInput';
import StripePaymentButton from './StripePaymentButton';

type Props = {
  className?: string;
  onError: (message: string | null) => void;
  onHeightChange?: (height: number) => void;
  onCardComplete: (complete: boolean) => void;
  paymentRequestOptions: PaymentRequestOptions | null;
  processPayment: (paymentId: string, stripe: Stripe) => Promise<string | null>;
  onFocusChanged?: (focused: boolean) => void;
  placeholder?: boolean;
};

function StripeControls({
  onError,
  paymentRequestOptions,
  onHeightChange,
  onCardComplete,
  className,
  processPayment,
  onFocusChanged,
  placeholder,
}: Props) {
  const isDevUser = useSelector((state) => state.ws.persist.isDevUser);
  const stripe = useStripe();

  return (
    <StripeControlsRoot className={className}>
      {isDevUser && stripe && (
        <ErrorBoundary fallbackRender={() => null} onError={console.error}>
          <StripePaymentButton
            stripe={stripe}
            onError={onError}
            paymentRequestOptions={paymentRequestOptions}
            onHeightChange={onHeightChange}
            processPayment={processPayment}
          />
        </ErrorBoundary>
      )}
      {stripe ? (
        <ErrorBoundary fallbackRender={() => null} onError={console.error}>
          <StripeCardInput
            onCardComplete={onCardComplete}
            onFocusChanged={onFocusChanged}
          />
        </ErrorBoundary>
      ) : placeholder ? (
        <StripeInputPlaceHolder />
      ) : null}

      <PaySafeAndSecure>
        <StripeBadge />

        <PaySecure>
          <StyledSecureIcon />
          <PaySafe>Pay safe &amp; secure</PaySafe>
          <CardIcons>
            <VisaCardIcon />
            <MasterCardCardIcon />
            <AmericanExpressCardIcon />
            <UnionPayCardIcon />
            <JCBCardIcon />
          </CardIcons>
        </PaySecure>
      </PaySafeAndSecure>
    </StripeControlsRoot>
  );
}

export default StripeControls;

const StripeControlsRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaySafeAndSecure = styled.div`
  width: 100%;
  padding: 0 15px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${dialogMobileMedia`
    width: 100%;
    flex-direction: column-reverse;
    padding: 0;
  `}
`;

const PaySecure = styled.div`
  display: flex;
  height: 100%;
  align-items: center;

  ${dialogMobileMedia`
    height: auto;
    margin-bottom: 10px;
  `}
`;

const StyledSecureIcon = styled(SecureIcon)`
  height: 100%;
  margin: 0 4px;
`;

const PaySafe = styled.p`
  margin: 0 4px 0 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 12px;
  color: #93e486;
`;

const CardIcons = styled.div`
  display: flex;

  & > svg {
    margin-left: 4px;
  }
`;

const blinkAnimation = keyframes`
  0% {
    opacity: 0.7;
  }

  100% {
    opacity: 0.4;
  }
`;

const StripeInputPlaceHolder = styled.div`
  width: 400px;
  background: ${({ theme }) => theme.placeholderColor};
  border-radius: 24px;
  height: 56px;
  animation: ${blinkAnimation} 1.5s ease-in-out infinite;
`;
