import * as React from 'react';
import styled from 'styled-components/macro';

import { useAtom } from 'jotai';
import { requestWallet } from '../../../actions/store';
import CardGrid, { LoadingGridItem } from '../../../components/CardGrid';
import ErrorSuccessMessage from '../../../components/ErrorSuccessMessage';
import { avatarPreviewAtom, basketAtom } from '../../../core/atoms';
import useApi from '../../../utils/useApi';
import LegacyStoreItemCard from '../../Cards/LegacyStoreItemCard';
import {
  Dialog,
  DialogBody,
  DialogHeader,
} from '../../DialogLayout/legacy/DialogLayout';
import BasketDialogFooter from './BasketDialogFooter';

type Props = {
  onClose: () => void;
};

const DESCRIPTION =
  'Buy items in the store and customize your Replika’s look and personality';

function PurchaseBasketDialog({ onClose }: Props) {
  const [error, setError] = React.useState<string | null>(null);

  const credit = useApi((state) => state.store.persist.credit, requestWallet, {
    memoDeepEqual: true,
  });

  const [basket, setBasket] = useAtom(basketAtom);
  const [{ variations, roomItems }, updateAvatarPreview] =
    useAtom(avatarPreviewAtom);
  const selectedVariations = variations ?? [];
  const selectedRoomVariations = roomItems ?? [];

  const sumPrice = basket
    .map((item) => item.storeItem.price)
    .reduce(
      (acc, price) => {
        if (price.currency === 'coin') {
          acc.coins_count += price.amount;
        } else {
          acc.gems_count += price.amount;
        }

        return acc;
      },
      {
        coins_count: 0,
        gems_count: 0,
      },
    );

  return (
    <StyledDialog
      data-testid="purchase-basket-dialog"
      mobileLayout="action-sheet"
    >
      <DialogHeader onClose={onClose} title="Purchase" />
      <DialogBody>
        <Description>{DESCRIPTION}</Description>
        <StoreCardList minItemWidth={100}>
          {basket.map((item, idx) => (
            <LegacyStoreItemCard
              noFocusWithin
              size={100}
              item={item.storeItem}
              key={idx}
              onRemove={() => {
                const newBasket = basket.filter(
                  (i) => i.storeItem.id !== item.storeItem.id,
                );
                setBasket(newBasket);
                updateAvatarPreview({
                  variations: selectedVariations.filter(
                    (v) =>
                      v.id !==
                      item.storeItem.variations[item.variationIndex ?? 0]!.id,
                  ),
                  roomItems: selectedRoomVariations.filter(
                    (v) =>
                      v.id !==
                      item.storeItem.variations[item.variationIndex ?? 0]!.id,
                  ),
                });
                if (newBasket.length === 0) {
                  onClose();
                }
              }}
            />
          ))}
          {basket.length === 0 && <LoadingGridItem size={100} />}
        </StoreCardList>
        {error && <ErrorSuccessMessage hasError>{error}</ErrorSuccessMessage>}
        <BasketDialogFooter
          credit={credit}
          onError={setError}
          sumPrice={sumPrice}
          onClose={onClose}
        />
      </DialogBody>
    </StyledDialog>
  );
}

export default PurchaseBasketDialog;

const StyledDialog = styled(Dialog)`
  min-width: 360px;
`;

const Description = styled.p`
  text-align: center;
  margin: 20px 0 30px;
  font-size: 16px;
  line-height: 21px;
  width: 260px;
`;

const StoreCardList = styled(CardGrid)`
  display: flex;
  overflow-x: scroll;
  justify-content: flex-start;
  max-width: 100%;
  height: 140px;
  margin: 16px auto;
  padding: 10px 0;

  & > li {
    flex: 0 0 auto;
    width: 120px;
  }
`;
