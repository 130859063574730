import styled from 'styled-components/macro';
import AriaAlertText from '../../components/AriaAlertText';
import {
  AccentButton,
  ButtonWithSpinnerProps,
  IconButton,
  solidButtonColorCss,
} from '../../components/Buttons';
import { TextArea } from '../../components/Inputs';
import Scrollable from '../../components/Scrollable';
import { errorOutlineCss } from '../../components/outlineCss';
import { ReactComponent as TrashIcon } from '../../icons/Trash.svg';

const FormBody = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 335px;
  max-width: 100%;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
`;

const FormWrapper = styled(Scrollable)`
  width: 100%;
  padding: 5px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding: 30px 20px 20px;
    height: 100%;
  }

  ${FormBody} {
    height: auto;
    padding-bottom: 80px;
  }
`;

const FormActions = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  margin: 25px 0 30px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    position: absolute;
    bottom: 20px;
    margin: 0;
    padding: 0 20px;
  }
`;

const FormImageActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px 0 0;
`;

const ControlButton = styled(IconButton)`
  height: 54px;
  width: 54px;
  border-radius: 24px;

  ${solidButtonColorCss};
`;

const DeleteButton = (props: ButtonWithSpinnerProps) => (
  <ControlButton {...props}>
    <TrashIcon />
  </ControlButton>
);

const SaveButton = styled(AccentButton)`
  width: 100%;
`;

const SaveEditButton = styled(SaveButton)`
  width: calc(100% - 69px);
`;

export const SubmitErrorRoot = styled(AriaAlertText)`
  max-height: 20px;
  max-width: 300px;
  width: 100%;
  margin-block: 15px 0;
  color: #fe9e98;
  font-size: 14px;
  line-height: 20px;
  transition: max-height 0.5s ease-out;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const FieldError = ({ error }: { error: Error | string | null }) => {
  return (
    <SubmitErrorRoot>
      {error instanceof Error ? error.message : error ?? ''}
    </SubmitErrorRoot>
  );
};

const FieldHint = styled.div`
  max-height: 20px;
  max-width: 300px;
  width: 100%;
  margin-block: 15px 0;
  color: rgba(255 255 255 / 70%);
  font-size: 14px;
  line-height: 20px;
  transition: max-height 0.5s ease-out;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const StyledTextArea = styled(TextArea)`
  min-height: 192px;
  padding: 15px;
  color: ${(p) => p.theme.fgColor};
  border: 0;
  background-color: rgba(255 255 255 / 10%);
  backdrop-filter: blur(50px);
  border-radius: 24px;
  font-family: ${(p) => p.theme.fonts.display};
  resize: none;

  &::-webkit-input-placeholder {
    color: rgba(255 255 255 / 20%);
  }
  &::-moz-placeholder {
    color: rgba(255 255 255 / 20%);
  }
  &::-ms-input-placeholder {
    color: rgba(255 255 255 / 20%);
  }

  &[aria-invalid='true'] {
    ${errorOutlineCss()}
  }
`;

export const ModalForm = {
  Wrapper: FormWrapper,
  Body: FormBody,
  Actions: FormActions,
  ImageActions: FormImageActions,
  ControlButton,
  DeleteButton,
  SaveButton,
  SaveEditButton,
  FieldError,
  FieldHint,
  TextArea: StyledTextArea,
};
