import { Action, Dispatch } from 'redux';

import { getDefaultStore } from 'jotai';
import { isEqual } from 'lodash';
import { NavigationPopupKnownDestinationsList } from '../components/Dialogs/NavigationPopupDialog';
import { VoiceCallBuffer } from '../components/VoiceCall/VoiceCallBuffer';
import { avatarUpdateAtom } from '../core/atoms';
import { AvatarAtom } from '../core/types';
import * as A from '../types/actions';
import {
  ActionTypes as AT,
  AgeGateStatus,
  Dialogs,
  MetricsEvents,
  SubscriptionRequestTypes,
  WsEventNames,
} from '../types/enums';
import {
  NavigationPopupDestinations,
  Notification,
  isRememeberedFact,
  isRememeberedPerson,
} from '../types/models';
import { DA } from '../types/redux';
import { RootState } from '../types/states';
import {
  IncomingWebsocketMessage,
  isAddReaction,
  isRemoveReaction,
} from '../types/websocket';
import { isSaleShouldBeShown } from '../utils/isSaleShouldBeShown';
import { logEvent } from '../utils/metrics';
import { closeConnection, openConnection } from '../utils/websocket';
import { setAvatarMessageEmotion } from './avatars';
import { showAgeGateIfNeeded } from './profile';
import { showChatWidget } from './prompts';
import { queueDialog, setActiveDialog } from './ui';
import { clearVoiceMessageTime } from './voice';

function filterKnownNotifications(
  notifications: Notification[],
  options?: { levels_enabled: boolean },
) {
  return notifications.filter((n) => {
    return (
      (n.type === 'level_up' && options?.levels_enabled) ||
      (n.type === 'reward' &&
        [
          'send_photo',
          'replika_skill',
          'replika_trait',
          'explain_popup',
          'virtual_currency',
        ].indexOf(n.reward.type) !== -1)
    );
  });
}

const logVoiceMessageLatency = (state, text, userId, dispatch) => {
  const lastVoiceMessageTimestamp = state.chat.lastVoiceMessageTimestamp;

  if (lastVoiceMessageTimestamp) {
    const timestamp = new Date();
    const latency = (timestamp.getTime() - lastVoiceMessageTimestamp) / 1000;

    logEvent(
      MetricsEvents.VoiceMessageReceived,
      {
        message: text,
        latency: latency, // seconds
      },
      userId,
    );

    // clear lastVoiceMessageTimestamp to avoid latency calculation
    // for multiple messages from Replika
    dispatch(clearVoiceMessageTime());
  }
};

const handleMessage = (
  dispatch: Dispatch<Action>,
  getState: () => RootState,
  message: IncomingWebsocketMessage,
) => {
  dispatch<A.IncomingWsAction>({ type: AT.WsMessageReceived, message });
  const state = getState();
  const payload = message.payload;
  const { userId } = state.auth.persist;
  const { userProfile } = state.profile.persist;

  const store = getDefaultStore();

  if (userProfile?.birthday_status === AgeGateStatus.Locked) {
    if (message.event_name !== WsEventNames.UserProfile) {
      return;
    }
  }

  switch (message.event_name) {
    case WsEventNames.Message:
      if (message.payload.content.type === 'voice_message') {
        if (message.payload.meta.nature === 'Customer')
          logEvent(MetricsEvents.VoiceMessageSent);

        if (message.payload.meta.nature === 'Robot')
          logEvent(MetricsEvents.ReplikaVoiceMessageReceived);
      }

      if (message.payload.content.type === 'voice_record') {
        logVoiceMessageLatency(
          state,
          message.payload.content.text,
          userId,
          dispatch,
        );

        dispatch<A.IncomingWsAction>({
          type: AT.WsVoiceRecordReceived,
          voiceRecord: {
            id: message.payload.id,
            text: message.payload.content.text,
            permitted_actions: message.payload.meta.permitted_actions ?? [],
            record_resource: message.payload.content.record_link,
          },
        });
      } else {
        setAvatarMessageEmotion(message.payload);
        dispatch<A.IncomingWsAction>({
          type: AT.WsChatMessageReceived,
          message: message.payload,
        });
      }

      break;

    case WsEventNames.PersonalBotStats:
      dispatch<A.IncomingWsAction>({
        type: AT.BotStatsReceived,
        stats: message.payload.stats,
        scoreGranted: message.payload.score_granted,
        notifications: filterKnownNotifications(
          message.payload.notifications,
          state.profile.persist.bot,
        ),
        messageId:
          state.chat.messageTokenIdMap[message.payload.message_client_token],
      });
      break;

    case WsEventNames.Notifications:
      dispatch<A.IncomingWsAction>({
        type: AT.WsNotificationsReceived,
        notifications: filterKnownNotifications(
          message.payload.notifications,
          state.profile.persist.bot,
        ),
      });
      break;

    case WsEventNames.PersonalBotChat:
      dispatch<A.IncomingWsAction>({
        type: AT.BotChatReceived,
        chat: message.payload,
      });
      break;

    case WsEventNames.Bot:
      let bot = message.payload;

      if (!isEqual(bot, state.profile.persist.bot)) {
        dispatch<A.IncomingWsAction>({
          type: AT.BotReceived,
          bot,
        });
      }

      let update: Partial<AvatarAtom> = {};

      if (bot.avatar_v2) {
        update.type = bot.avatar_v2.avatar_type;
        update.variations = bot.avatar_v2.active_variations;
        update.bodyType = bot.avatar_v2.body_type;
        update.age = bot.avatar_v2.age;
      }

      if (bot.room_items) {
        update.roomItems = bot.room_items;
      }

      if (bot.pets) {
        update.pets = bot.pets;
      }

      if (Object.keys(update).length > 0) {
        store.set(avatarUpdateAtom, update);
      }
      break;

    case WsEventNames.MessageEmotion:
      dispatch<A.IncomingWsAction>({
        type: AT.WsMessageEmotionReceived,
        messageId: message.payload.message_id,
        emotion: message.payload.avatarEmotion.name,
      });
      break;
    case WsEventNames.MessageReaction:
      if (isAddReaction(payload)) {
        dispatch<A.IncomingWsAction>({
          type: AT.WsMessageReactionReceived,
          reaction: payload.addReaction.reaction,
          messageId: payload.addReaction.message_id,
        });
      } else if (isRemoveReaction(payload)) {
        dispatch<A.IncomingWsAction>({
          type: AT.WsMessageReactionReceived,
          reaction: undefined,
          messageId: payload.removeReaction,
        });
      }
      break;

    case WsEventNames.ConversationFeedback:
      if (!message.payload.session_id) {
        // This is an empty answer to the feedback we sent; we should ignore it
        return;
      }
      dispatch<A.IncomingWsAction>({
        type: AT.WsFeedbackRequestReceived,
        sessionId: message.payload.session_id,
        feedbackView: message.payload.feedback_view,
      });
      break;

    case WsEventNames.StartTyping:
      dispatch<A.IncomingWsAction>({
        type: AT.WsStartTypingReceived,
      });
      break;

    case WsEventNames.StopTyping:
      dispatch<A.IncomingWsAction>({
        type: AT.WsStopTypingReceived,
      });
      break;

    case WsEventNames.VoiceMode:
      dispatch<A.IncomingWsAction>({
        type: AT.WsVoiceModeReceived,
        voiceModeState: message.payload.state,
      });

      const duration = state.chat.voiceCallStartTime
        ? Math.round((Date.now() - state.chat.voiceCallStartTime) / 1000)
        : 0;

      // voice call ended by Replika for current client
      if (
        message.payload.state === 'disabled' &&
        state.chat.voiceCallInProgress
      ) {
        dispatch(
          setActiveDialog({
            type: Dialogs.VoiceCallFeedback,
          }),
        );

        logEvent(
          MetricsEvents.VoiceCallEnded,
          {
            duration,
            ended_by: 'Replika',
          },
          userId,
        );
      }
      break;

    case WsEventNames.JourneyChanged:
      dispatch<A.IncomingWsAction>({
        type: AT.WsJourneyChanged,
        affectedTrackIds: message.payload.affected_track_ids,
      });
      break;

    case WsEventNames.StatementRemembered:
      const statement = message.payload;
      const isPerson = isRememeberedPerson(statement);
      const isFact = isRememeberedFact(statement);

      dispatch<A.IncomingWsAction>({
        type: AT.WsStatementRemembered,
        messageId: statement.message_id,
        memoryId: isPerson
          ? statement.person_id
          : isFact
            ? statement.fact_id
            : statement.category_id,
        memoryType: isPerson ? 'persons' : 'facts',
        nature: statement.nature,
      });

      break;

    case WsEventNames.NavigationPopup:
      const navigationPopup = message.payload.navigation_popup;

      dispatch<A.IncomingWsAction>({
        type: AT.WsNavigationPopup,
        navigationPopup,
      });

      if (
        NavigationPopupKnownDestinationsList.includes(
          navigationPopup.button_destination,
        )
      ) {
        dispatch(
          queueDialog({ type: Dialogs.NavigationPopup, navigationPopup }),
        );
      } else if (
        navigationPopup.button_destination ===
        NavigationPopupDestinations.prompts_library_category
      ) {
        dispatch(showChatWidget(navigationPopup));
      }

      break;

    case WsEventNames.Wallet:
      const { earned, credit } = message.payload;
      dispatch<A.IncomingWsAction>({
        type: AT.WsWalletReceived,
        credit,
        earned,
      });
      break;

    case WsEventNames.ChatSuggestionList:
      dispatch<A.IncomingWsAction>({
        type: AT.SuggestionListReceived,
        suggestions: message.payload.suggestions,
      });

      break;

    case WsEventNames.SubscriptionRequest:
      const isFreshSignup = state.signup.isFreshSignup;

      if (isFreshSignup) {
        break;
      }

      switch (message.payload.type) {
        case SubscriptionRequestTypes.Sale:
          if (
            isSaleShouldBeShown(
              message.payload.sale.sale_id,
              state.subscriptions.persist.shownSalesMap,
            )
          ) {
            dispatch<A.IncomingWsAction>({
              type: AT.WsSaleScreenReceived,
              saleScreen: message.payload.sale,
            });
          }
          break;
        case SubscriptionRequestTypes.SubscriptionScreen:
          if (
            isSaleShouldBeShown(
              SubscriptionRequestTypes.SubscriptionScreen,
              state.subscriptions.persist.shownSalesMap,
            )
          ) {
            dispatch(
              queueDialog({
                type: Dialogs.PaidFeaturePopup,
                cause: 'subscription screen',
                feature: 'subscription screen',
                onCloseConfirm: () =>
                  dispatch({
                    type: AT.SaleScreenShown,
                    id: SubscriptionRequestTypes.SubscriptionScreen,
                  }),
              }),
            );
          }
          break;
      }
      break;

    case WsEventNames.UserProfile:
      const userProfile = message.payload;

      dispatch<A.IncomingWsAction>({
        type: AT.WsProfileReceived,
        profile: message.payload,
      });

      showAgeGateIfNeeded(userProfile, dispatch);

      break;

    case WsEventNames.AdvancedAiMessages:
      const availableMessages = message.payload.available_messages;

      dispatch<A.IncomingWsAction>({
        type: AT.WsAdvancedAiMessagesReceived,
        availableMessages,
      });

      break;

    case WsEventNames.BotMoodUpdate:
      const botMood = message.payload.mood_v2;

      dispatch<A.IncomingWsAction>({
        type: AT.WsBotMoodReceived,
        botMood,
      });

      break;

    case WsEventNames.MemoryNewFactsUpdate:
      const result = message.payload;

      dispatch<A.IncomingWsAction>({
        type: AT.WsMemoryNewFactsUpdateReceived,
        result,
      });

      break;

    case WsEventNames.VoiceSampleStream:
      VoiceCallBuffer.handleEvent(message.payload, (typedArray) => {
        logVoiceMessageLatency(state, message.payload.text, userId, dispatch);

        dispatch<A.IncomingWsAction>({
          type: AT.WsVoiceRecordReceived,
          voiceRecord: {
            id: message.payload.id,
            text: message.payload.text,
            permitted_actions: message.payload.meta.permitted_actions ?? [],
            record_resource: typedArray.buffer,
            phonemes: message.payload.lipsync_data,
          },
        });
      });

      break;
  }
};

export const connectWebsocket = (): DA => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch<A.InitAction>({ type: AT.WsStart });

    openConnection({
      onOpen: () => {
        dispatch<A.InitAction>({ type: AT.WsOpen });
        resolve();
      },
      onClose: (e) => {
        const state = getState();
        const needReconnect =
          state.ws.status === 'connected' && !!state.auth.persist.userId;

        dispatch<A.InitAction>({
          type: AT.WsClose,
          code: e.code,
          reason: e.reason,
          needReconnect,
        });
      },
      onWsMessage: handleMessage.bind(null, dispatch, getState),
      onError: (errorType, error) => {
        console.error(error);
        dispatch<A.InitAction>({ type: AT.WsError, error });
        reject(error);
      },
    });
  });

export const disconnectWebsocket = () => (dispatch: Dispatch<A.InitAction>) => {
  closeConnection();

  dispatch<A.InitAction>({
    type: AT.WsDisconnect,
  });
};
