import { useDispatch } from 'react-redux';
import { setAvatarMode } from '../../../actions/profile';
import {
  ConfirmDialogBody,
  Dialog,
} from '../../DialogLayout/legacy/DialogLayout';

type Props = {
  onClose: () => void;
  backTo: string;
};

const Enable3dDialog = (props: Props) => {
  const dispatch = useDispatch();
  const { onClose } = props;

  return (
    <Dialog data-testid="enable-3d-dialog" mobileLayout="action-sheet">
      <ConfirmDialogBody
        title="3D mode is required"
        description="Replika customization requires 3D to be enabled. Enable it now?"
        confirmText="Enable 3D"
        cancelTo={props.backTo}
        onConfirm={() => {
          dispatch(setAvatarMode('auto'));
          onClose();
        }}
        onCancel={onClose}
      />
    </Dialog>
  );
};

export default Enable3dDialog;
