import { useDispatch } from 'react-redux';
import { setActiveDialog } from '../../../actions/ui';
import { HintDescription, HintSubTitle } from '../../../components/HintButton';
import { Dialogs, MetricsEvents } from '../../../types/enums';
import useLogEvent from '../../../utils/useLogEvent';

export const useFormCloseConfirmation = (onConfirm: () => void) => {
  const dispatch = useDispatch();
  const logEvent = useLogEvent();

  return () =>
    dispatch(
      setActiveDialog({
        type: Dialogs.Confirmation,
        content: {
          title: 'Discard changes',
          description: "If you exit now, your changes won't be saved.",
          secondaryText: 'Discard & exit',
          primaryText: 'Back to editing',
        },
        onSecondaryClick: () => {
          onConfirm();
          logEvent(MetricsEvents.BackstoryChangesDiscarded, {
            action: 'exit',
          });
        },
        onPrimaryClick: () => {
          logEvent(MetricsEvents.BackstoryChangesDiscarded, {
            action: 'back to editing',
          });
        },
      }),
    );
};

export const useBackstoryError = () => {
  const dispatch = useDispatch();

  return () =>
    dispatch(
      setActiveDialog({
        type: Dialogs.Confirmation,
        content: {
          title: 'There seems to be an issue with your backstory',
          description: (
            <>
              <HintDescription>
                Make sure that your text doesn’t have any of the following.
              </HintDescription>

              <HintSubTitle>Offensive language</HintSubTitle>
              <HintDescription>
                Profane, racist, or sexist language, and any form of
                discriminatory remarks.
              </HintDescription>

              <HintSubTitle>Harmful & sexual content</HintSubTitle>
              <HintDescription>
                Any content that can be damaging, explicit in a sexual manner,
                or alludes to abuse.
              </HintDescription>
            </>
          ),
          primaryText: 'Got it',
        },
        onPrimaryClick: () => {},
      }),
    );
};
