import * as React from 'react';
import styled from 'styled-components/macro';

import { dialogMobileMedia } from '../../utils/mobileMedia';
import Picture, { ModernImageFormat } from '../Picture';
import { Dialog, DialogCloseButton } from './legacy/DialogLayout';

type Props = {
  className?: string;
  children: React.ReactNode;
  'data-testid': string;
  dialogBgColor?: string;
  backgroundImageUrl?: string;
  showcaseContent?: React.ReactNode;
  showcaseBgColors?: string[];
  showcaseGradient?: string;
  backgroundImageFormats?: ModernImageFormat[];
  backgroundAlt?: string;
  hideShowcase?: boolean;
};

function ShowcaseDialogLayout({
  className,
  children,
  'data-testid': dataTestId,
  dialogBgColor,
  backgroundImageUrl,
  backgroundImageFormats = [],
  showcaseContent,
  showcaseBgColors = [],
  showcaseGradient,
  backgroundAlt = '',
  hideShowcase = false,
}: Props) {
  return (
    <LayoutRoot className={className} data-testid={dataTestId}>
      <DialogBody $dialogBgColor={dialogBgColor}>
        {!hideShowcase && (
          <Showcase
            $showcaseBgColors={showcaseBgColors}
            $showcaseGradient={showcaseGradient}
          >
            {showcaseContent}
            {backgroundImageUrl ? (
              <BgImg
                src={backgroundImageUrl}
                alt={backgroundAlt}
                formats={backgroundImageFormats}
              />
            ) : null}
          </Showcase>
        )}
        {children}
      </DialogBody>
    </LayoutRoot>
  );
}

export default ShowcaseDialogLayout;

const LayoutRoot = styled(Dialog)`
  & *:focus:not(.focus-visible):not(:focus-visible) {
    outline: none;
  }

  & .focus-visible:focus,
  & :focus-visible:focus {
    outline-color: ${(p) => p.theme.outlineColor};
  }

  & a.focus-visible:focus,
  & button.focus-visible:focus,
  & a:focus-visible:focus,
  & button:focus-visible:focus {
    outline: none;
    box-shadow: 0 0 2px 2px ${(p) => p.theme.outlineColor};
  }

  ${dialogMobileMedia`
    && {
      width: 100%;
      margin: 0;
    }
  `}
`;

const BgImg = styled(Picture)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${dialogMobileMedia`
    object-position: 50% 40%;
  `}
`;

const DialogBody = styled.div<{ $dialogBgColor?: string }>`
  position: relative;
  display: flex;
  overflow: auto hidden;
  align-items: stretch;
  height: 100%;
  border-radius: 24px;
  background: ${(p) => p.$dialogBgColor};

  ${dialogMobileMedia`
    flex-direction: column;
    height: 100vh;
    overflow: hidden scroll;
    border-radius: 0;
  `}
`;

const Showcase = styled.div<{
  $showcaseBgColors: string[];
  $showcaseGradient?: string;
}>`
  font-family: ${(p) => p.theme.fonts.display};
  flex: 0 1 auto;
  padding: 64px 0;
  position: relative;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, ${(p) => p.$showcaseBgColors.join(',')});
  color: #fff;

  & > * {
    position: relative;
    z-index: 1;
  }

  & > picture {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-position: 50% 30%;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: 100%;
    height: 50%;

    background-image: ${(p) => p.$showcaseGradient ?? 'none'};
    z-index: 2;
  }

  @media ${(p) => p.theme.breakpoints.laptop} {
    flex: 0 0 auto;
  }

  ${dialogMobileMedia`
    width: 100%;
    flex: 0 0 auto;
    min-height: 110vw;
    padding: 30px 0;
  `}
`;

export const CloseButton = styled(DialogCloseButton)`
  z-index: 2;
  ${dialogMobileMedia`
    color: #FFF;
  `}
`;
