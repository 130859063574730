import { getDefaultStore } from 'jotai';
import guid from 'simple-guid';
import { chatPromptButtonsVisibleAtom } from 'src/features/AskReplika/atoms';
import * as A from '../types/actions';
import {
  AsyncActionTypes as AAT,
  ActionTypes as AT,
  MetricsEvents,
  WsEventNames,
} from '../types/enums';
import {
  Chat,
  MediaPreview,
  MediaPreviewMap,
  MessageActionType,
  MessageOutcomingPayload,
  OutcomingMessageOptions,
  Suggestion,
} from '../types/models';
import { DA } from '../types/redux';
import { AdvancedAiStatus, RootState } from '../types/states';
import {
  HistoryIncomingPayload,
  MessageIncomingPayload,
} from '../types/websocket';
import { ApiError } from '../utils/apiError';
import {
  apiAction,
  errorAction,
  requestAction,
  successAction,
  wsAction,
} from '../utils/asyncAction';
import { IMAGE_MAX_PIXEL_AREA } from '../utils/constants';
import fetchOptions from '../utils/fetchOptions';
import { logEvent } from '../utils/metrics';
import { API_BASE_URL } from '../utils/uri';
import { sendMessage } from '../utils/websocket';
import { uploadImage } from './image';
import { uploadVoiceMessage } from './voice';

export const getHistory =
  (
    lastMessageId: string | undefined,
    limit: number,
    invalidate: boolean,
  ): DA<HistoryIncomingPayload> =>
  async (dispatch, getState) => {
    const state = getState();
    const { chatId } = state.ws.persist;

    if (!chatId) {
      throw Error('no chat id');
    }

    const params = { chatId, lastMessageId, limit, invalidate };

    return wsAction<A.GetHistory>(AAT.WsHistory, dispatch, params, {
      onRequest: () =>
        sendMessage(
          {
            event_name: WsEventNames.History,
            payload: {
              chat_id: chatId,
              limit,
              last_message_id: lastMessageId,
            },
          },
          state,
        ),
    });
  };

export const getSuggestions = (): DA<{}> => async (dispatch, getState) => {
  const state = getState();

  return wsAction<A.GetChatSuggestionList>(
    AAT.WsGetChatSuggestionList,
    dispatch,
    {},
    {
      onRequest: () =>
        sendMessage(
          {
            event_name: WsEventNames.ChatSuggestionList,
            payload: {},
          },
          state,
        ),
    },
  );
};

export const startChatSuggestion =
  (suggestion: Suggestion): DA<{}> =>
  async (dispatch, getState) => {
    const state = getState();
    const { chatId } = state.ws.persist;

    if (!chatId) {
      throw Error('no chat id');
    }

    const { id, scenario_id, name } = suggestion;

    const params = { ...suggestion, chat_id: chatId };

    return wsAction<A.StartChatSuggestion>(
      AAT.WsStartChatSuggestion,
      dispatch,
      params,
      {
        onRequest: () =>
          sendMessage(
            {
              event_name: WsEventNames.StartChatSuggestion,
              payload: {
                id,
                scenario_id,
                name,
                chat_id: chatId,
              },
            },
            state,
          ),
      },
    );
  };

export const setMessageReaction =
  (messageId: string, reaction: MessageActionType | undefined): DA<{}> =>
  async (dispatch, getState) => {
    const state = getState();
    const { chatId } = state.ws.persist;
    const { userId } = state.auth.persist;

    if (!chatId) {
      throw Error('no chat id');
    }

    const params = { messageId, reaction, chatId };

    return wsAction<A.SetMessageReaction>(
      AAT.WsSetMessageReaction,
      dispatch,
      params,
      {
        onRequest: () =>
          sendMessage(
            {
              event_name: WsEventNames.MessageReaction,
              payload: reaction
                ? {
                    addReaction: {
                      message_id: messageId,
                      chat_id: chatId,
                      reaction,
                    },
                  }
                : {
                    removeReaction: messageId,
                  },
            },
            state,
          ),
        onSuccess: () => {
          if (reaction === 'Upvote') {
            logEvent(
              MetricsEvents.Upvote,
              {
                mode: 'text',
              },
              userId,
            );
          } else if (reaction === 'Downvote') {
            logEvent(
              MetricsEvents.Downvote,
              {
                mode: 'text',
              },
              userId,
            );
          }
        },
      },
    );
  };

export const getPersonalBotChat =
  (): DA<Chat> => async (dispatch, getState) => {
    const endpoint = `${API_BASE_URL}/personal_bot_chat`;
    const fetchOpts = fetchOptions(getState(), 'GET');

    return apiAction<A.GetPersonalBotChat>(
      AAT.GetPersonalBotChat,
      dispatch,
      {},
      {
        onRequest: () => fetch(endpoint, fetchOpts),
        onSuccess: (chat) => {
          const state = getState();
          if (
            !state.prompts.promptInProgress &&
            chat.prompt_state.state === 'prompt_in_progress'
          ) {
            dispatch(toggleAdvancedAi('active'));
          }
        },
      },
    );
  };

export const getRequestAdvancedAiParamValue = ({
  chat,
  prompts,
}: RootState) => {
  const { promptInProgress } = prompts;

  if (promptInProgress) {
    return false;
  }

  return chat.persist.advancedAiStatus === 'active';
};

export const sendChatMessage =
  (
    message: OutcomingMessageOptions,
    clientToken?: string,
  ): DA<MessageIncomingPayload> =>
  async (dispatch, getState) => {
    const state = getState();
    const { chatId } = state.ws.persist;
    const { bot } = state.profile.persist;
    const { skippedWidgetId } = state.chat;

    if (!bot) {
      throw Error('no bot');
    }
    if (!chatId) {
      throw Error('no chat id');
    }
    const meta = {
      bot_id: bot.id,
      client_token: clientToken || guid(),
      chat_id: chatId,
      timestamp: new Date().toISOString(),
    };

    const widget = skippedWidgetId
      ? { ...message.widget, widget_id: skippedWidgetId, skipped: true }
      : message.widget;

    let payload: MessageOutcomingPayload;

    switch (message.type) {
      case 'images': {
        payload = {
          content: {
            type: 'images',
            text: message.text,
            images: message.images,
          },
          meta,
          widget_response: message.widget,
          request_advanced_ai: getRequestAdvancedAiParamValue(state),
        };

        break;
      }

      case 'voice_message': {
        payload = {
          content: {
            type: 'voice_message',
            text: message.text,
            voice_message_url: message.voice_message_url,
            duration: message.duration,
          },
          meta,
          widget_response: message.widget,
        };

        break;
      }

      default: {
        payload = {
          content: {
            type: 'text',
            text: message.text,
          },
          meta,
          widget_response: widget,
          request_advanced_ai: getRequestAdvancedAiParamValue(state),
        };
      }
    }

    const store = getDefaultStore();
    store.set(chatPromptButtonsVisibleAtom, false);

    const params = { chatId, message: payload };
    return wsAction<A.SendMessage>(AAT.WsSendMessage, dispatch, params, {
      onRequest: () =>
        sendMessage(
          {
            event_name: WsEventNames.Message,
            payload,
          },
          state,
        ),
    });
  };

export const uploadChatImage =
  (file: File): DA<{}> =>
  async (dispatch, getState) => {
    try {
      const { deviceId } = getState().auth.persist;
      const clientToken = guid();

      if (!deviceId) {
        return Promise.reject(new Error('No device id'));
      }

      dispatch(requestAction(AAT.UploadChatImage, {}));

      const { image_url: imageUrl } = await uploadImage(
        file,
        IMAGE_MAX_PIXEL_AREA,
        (image, orientation) => {
          dispatch({
            type: AT.UploadChatImagePreview,
            src: image.src,
            width: image.width,
            height: image.height,
            orientation,
            clientToken,
          });
        },
      )(dispatch, getState);

      dispatch(successAction(AAT.UploadChatImage, { imageUrl }, {}));

      return sendChatMessage(
        {
          type: 'images',
          text: '',
          images: [imageUrl],
        },
        clientToken,
      )(dispatch, getState);
    } catch (err) {
      const error = err as ApiError;
      dispatch(errorAction(AAT.UploadChatImage, error, {}));
      throw err;
    }
  };

export const uploadChatVoiceMessage =
  (voiceMessage: Blob, voiceMessageDuration: number): DA<{}> =>
  async (dispatch, getState) => {
    try {
      const { deviceId } = getState().auth.persist;
      const clientToken = guid();

      if (!deviceId) {
        return Promise.reject(new Error('No device id'));
      }

      dispatch(requestAction(AAT.UploadVoiceMessage, {}));

      const { voice_message_url } = await uploadVoiceMessage(voiceMessage)(
        dispatch,
        getState,
      );

      dispatch(
        successAction(AAT.UploadVoiceMessage, { voice_message_url }, {}),
      );

      dispatch<A.GetVoiceMessage>(
        successAction(
          AAT.GetVoiceMessage,
          {
            url: voice_message_url,
            content: voiceMessage,
          },
          {
            url: voice_message_url,
          },
        ),
      );

      return sendChatMessage(
        {
          type: 'voice_message',
          text: '',
          voice_message_url: voice_message_url,
          duration: voiceMessageDuration,
        },
        clientToken,
      )(dispatch, getState);
    } catch (err) {
      const error = err as ApiError;
      dispatch(errorAction(AAT.UploadVoiceMessage, error, {}));
      throw err;
    }
  };

function isMediaPreview(m: unknown): m is MediaPreview {
  return typeof m === 'object' && m !== null && 'type' in m && 'content' in m;
}

export const getMediaPreviewRequest =
  (urls: string[]): DA<MediaPreviewMap> =>
  async (dispatch, getState) => {
    const params = { urls };

    try {
      dispatch<A.ChatAction>(requestAction(AAT.GetMediaPreview, params));

      const urlPreviewPairs = await Promise.all(
        urls.map(async (url) => {
          const res = await fetch(
            `${API_BASE_URL}/media_preview/?url=${encodeURIComponent(url)}`,
            fetchOptions(getState(), 'GET'),
          );

          if (res.status !== 200) {
            return [url, null] as const;
          }

          const mediaPreview = await res.json();

          if (!isMediaPreview(mediaPreview)) {
            return [url, null] as const;
          }

          return [url, mediaPreview] as const;
        }),
      );

      const mediaPreviews = urlPreviewPairs.reduce(
        (acc: MediaPreviewMap, [url, m]) => {
          if (url) {
            acc[url] = m;
          }
          return acc;
        },
        {},
      );

      dispatch<A.ChatAction>(
        successAction(AAT.GetMediaPreview, mediaPreviews, params),
      );

      return mediaPreviews;
    } catch (err) {
      const error = err as ApiError;
      dispatch<A.ChatAction>(errorAction(AAT.GetMediaPreview, error, params));
      throw err;
    }
  };

export const textInputDetected = (): DA<{}> => async (dispatch, getState) => {
  const state = getState();
  const {
    ws: {
      persist: { chatId },
    },
  } = state;

  if (!chatId) {
    throw Error('no chat id');
  }

  const params = { chatId };

  return wsAction(AAT.WsTextInputDetected, dispatch, params, {
    onRequest: () =>
      sendMessage(
        {
          event_name: WsEventNames.TextInputDetected,
          payload: {
            chat_id: chatId,
          },
        },
        state,
      ),
  });
};

export const updateChatMessageText = (messageText: string): A.ChatAction => {
  return {
    type: AT.UpdateChatMessageText,
    messageText,
  };
};

export const skipWidget = (id: string): A.SkipWidget => {
  return {
    type: AT.SkipWidget,
    id,
  };
};

export const clearChatMessageAlert = (
  alertType: 'score' | 'memory',
  messageId: string,
): A.ClearChatMessageAlert => {
  return {
    type: AT.ClearChatMessageAlert,
    messageId,
    alertType,
  };
};

export const toggleAdvancedAi = (
  status: AdvancedAiStatus,
): A.ToggleAdvancedAi => {
  return {
    type: AT.ToggleAdvancedAi,
    status,
  };
};

export const toogleAdvancedAiBoostWidget = (
  show: boolean,
): A.ToogleAdvancedAiBoostWidget => {
  return {
    type: AT.ToogleAdvancedAiBoostWidget,
    show,
  };
};
