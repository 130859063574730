import * as A from '../types/actions';
import { ToSuccess } from '../types/asyncActions';
import { AsyncActionTypes as AAT, ActionTypes as AT } from '../types/enums';
import { Reducer } from '../types/redux';
import { WsState } from '../types/states';
import applyReducer from '../utils/applyReducer';

const DEFAULT_STATE: WsState = {
  status: 'disconnected',
  wsReady: false,
  persist: {
    isDevUser: false,
    chatId: undefined,
  },
  capabilities: [],
};

type R<X extends A.InitAction> = Reducer<WsState, X>;
type RSuccess<X> = Reducer<WsState, ToSuccess<X>>;

const setStatusConnected: R<A.WebsocketOpen> = (state) => ({
  ...state,
  status: 'connected',
});

const setStatusConnecting: R<A.WebsocketStart> = (state) => ({
  ...state,
  status: 'connecting',
});

const setStatusDisconnected: R<A.WebsocketDisconnect> = (state) => ({
  ...state,
  status: 'disconnected',
});

const closeWsConnection: R<A.WebsocketClose> = (state, { needReconnect }) => ({
  ...state,
  status: needReconnect ? 'needReconnect' : 'disconnected',
});

const setDefaultChat: R<A.SetDefaultChat> = (state, { chatId }) => ({
  ...state,
  persist: {
    ...state.persist,
    chatId,
  },
});

const resetWs = () => ({ ...DEFAULT_STATE });

const setWsReady: RSuccess<A.Init> = (state, { result }) => ({
  ...state,
  wsReady: true,
  capabilities: result.capabilities,
  persist: {
    ...state.persist,
    isDevUser: result.is_dev_user,
  },
});

export default function ws(
  state: WsState = DEFAULT_STATE,
  action: A.InitAction | A.Logout | A.DeleteAccount,
) {
  return applyReducer(
    'ws',
    {
      [AT.WsStart]: setStatusConnecting,
      [AT.WsOpen]: setStatusConnected,
      [AT.WsClose]: closeWsConnection,
      [AT.WsDisconnect]: setStatusDisconnected,
      [AT.SetDefaultChat]: setDefaultChat,
      [AAT.WsInit]: {
        success: setWsReady,
      },
      [AAT.Logout]: {
        success: resetWs,
        error: resetWs,
      },
      [AAT.DeleteAccount]: {
        success: resetWs,
      },
    },
    state,
    action,
  );
}
