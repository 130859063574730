import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { getStoreItemsByIds, requestWallet } from '../../../actions/store';
import { Routes } from '../../../types/enums';
import getCurrencyNeeded from '../../../utils/getCurrencyNeeded';
import hex2rgba from '../../../utils/hex2rgba';
import useApi from '../../../utils/useApi';
import useGoBack from '../../../utils/useGoBack';
import { ModalLayout } from '../ModalScreenLayout';
import useRouteTransition from '../TransitionRoutes/useRouteTransition';
import DialogStoreItemFooter from './DialogStoreItemFooter';

export default function StoreDialogItemModal() {
  const history = useHistory();
  const { params, location } = useRouteTransition<{
    category: string;
    tab: string;
    item: string;
  }>();

  const searchParams = new URLSearchParams(location.search);

  const getItem = useCallback(() => {
    return getStoreItemsByIds([params.item]);
  }, [params.item]);

  const itemMap = useApi((state) => state.store.storeItemsMap, getItem);

  const item = itemMap[params.item];

  const background = useMemo(() => {
    if (!item) return;
    if (!item.background_hex) {
      return undefined;
    }
    let background = '';
    if (item.background_hex.length === 2) {
      background = `linear-gradient(180deg,
        ${hex2rgba(item.background_hex[0], 1)} 0%,
        ${hex2rgba(item.background_hex[1], 0)} 100%);`;
    } else if (item.background_hex.length === 3) {
      background = `linear-gradient(180deg,
        ${hex2rgba(item.background_hex[0], 1)} 0%,
        ${hex2rgba(item.background_hex[1], 0.5)} 50%,
        ${hex2rgba(item.background_hex[2], 0)} 100%);`;
    }
    return background;
  }, [item]);

  const credit = useApi((state) => state.store.persist.credit, requestWallet, {
    memoDeepEqual: true,
  });

  const variation = item?.variations[0];
  const itemBought = (variation?.bought_count ?? 0) > 0;

  const currencyNeeded =
    itemBought || !item ? null : getCurrencyNeeded(item.price, credit);

  const [error, setError] = useState<string | null>(null);

  const { goBack } = useGoBack({
    fallbackRoute: Routes.Store,
    closeOnEscape: true,
  });

  return (
    <ModalLayout.Root background={background}>
      <ModalLayout.Header>
        <ModalLayout.HeaderRight>
          <ModalLayout.CloseButton onClick={goBack} />
        </ModalLayout.HeaderRight>
      </ModalLayout.Header>
      <ModalLayout.Content>
        {item && variation && (
          <>
            <ItemImg
              width={250}
              height={250}
              src={item.preview_url}
              alt={item.title}
            />
            <VariationType>
              {searchParams.get('type') ?? item.variation_type}
            </VariationType>
            <Title>{item.title}</Title>
            <Desc>{item.description}</Desc>
            <StyledDialogStoreItemFooter
              currencyNeeded={currencyNeeded}
              item={item}
              onClose={() => history.goBack()}
              itemTypeName={params.category}
              onError={setError}
            />
            {error && <ModalLayout.Error>{error}</ModalLayout.Error>}
          </>
        )}
      </ModalLayout.Content>
    </ModalLayout.Root>
  );
}

const Title = styled.h1`
  margin: 0;
`;

const VariationType = styled.p`
  font-family: ${(p) => p.theme.fonts.display};
  color: ${(p) => p.theme.dimmedFgColor};
  margin: 0 0 10px;
`;

const Desc = styled.p`
  margin-block: 10px;
  max-width: 320px;
  text-align: center;
`;

const ItemImg = styled.img`
  width: 250px;
  height: auto;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 360px;
  }
`;

const StyledDialogStoreItemFooter = styled(DialogStoreItemFooter)`
  margin-top: 10px;
`;
