import { AriaButton } from 'src/components/Buttons';
import { Checkbox } from 'src/components/Checkbox';
import CroppedPhoto from 'src/components/CroppedPhoto';
import outlineCss from 'src/components/outlineCss';
import { ReactComponent as PersonIcon } from 'src/icons/Person.svg';
import { ModalRoutes } from 'src/types/enums';
import { MemoryPerson } from 'src/types/models';
import toModalRoute from 'src/utils/toModalRoute';
import styled from 'styled-components/macro';
import { editablePersonPredicate } from '../../utils';
import personMaskSvg from './PersonMask.svg';

export default function PersonListItem({
  person,
  selected,
  selectableMode,
  onSelect,
  relationName,
  tabIndex,
}: {
  person: MemoryPerson;
  selected: boolean;
  selectableMode: boolean;
  onSelect: (id: string) => void;
  relationName: string;
  tabIndex: number;
}) {
  const id = person.id;

  const photoUrl = person.photo?.photo_url;
  const photoFrame = person.photo?.frame;
  const isSelectablePerson = selectableMode && editablePersonPredicate(person);

  return (
    <Person>
      <PersonLink
        onClick={(e) => {
          if (!selectableMode) {
            return;
          }

          e.preventDefault();

          if (isSelectablePerson) {
            onSelect(id);
          }
        }}
        to={toModalRoute<{
          memoryType: 'persons' | 'facts';
          memoryId: string;
          source?: string;
        }>(ModalRoutes.MemoryRecordEdit, {
          memoryType: 'persons',
          memoryId: id,
        })}
        tabIndex={tabIndex}
        role={selectableMode ? 'checkbox' : undefined}
        aria-checked={selectableMode ? selected : undefined}
        disabled={selectableMode && !isSelectablePerson}
      >
        {isSelectablePerson && (
          <PersonCheckbox
            active={selected}
            onClick={(e) => {
              onSelect(id);
            }}
          />
        )}
        <MaskedPhoto $maskEnabled={isSelectablePerson}>
          {photoUrl ? (
            <PersonPhoto
              key={photoUrl}
              height={64}
              width={64}
              label={`${person.name ?? relationName}'s photo`}
              photoUrl={photoUrl}
              photoFrame={photoFrame}
            />
          ) : (
            <PersonPhotoPlaceholder />
          )}
        </MaskedPhoto>
        <PersonName>{person.name ?? 'Add name'}</PersonName>
        <PersonRelation>{relationName}</PersonRelation>
      </PersonLink>
    </Person>
  );
}

const PersonName = styled.p`
  margin: 10px 0 0;
  font-size: 12px;
  line-height: 16px;
  max-height: 24px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-family: ${(p) => p.theme.fonts.display};
  letter-spacing: 0.12px;
`;

const PersonRelation = styled.p`
  margin: 2px 0 0;
  max-width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.5);
  font-family: ${(p) => p.theme.fonts.body};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.12px;
`;

const Person = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 84px;
  cursor: pointer;
`;

const PersonPhoto = styled(CroppedPhoto)`
  display: block;
`;

const PersonPhotoPlaceholder = styled(PersonIcon)`
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
`;

// TODO: use inline svg for check icon?
const PersonCheckbox = styled(Checkbox)`
  position: absolute;
  right: 5px;
  top: 40px;
`;

const PersonLink = styled(AriaButton)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${(p) => p.theme.fgColor};
  padding: 0;
  border: none;
  background: none;

  &:hover {
    text-decoration: none;
  }

  &[aria-disabled='true'] {
    cursor: not-allowed;
  }

  &.focus-visible:focus,
  &:focus-visible:focus {
    outline: none;
    box-shadow: none;

    ${PersonPhoto}, ${PersonPhotoPlaceholder} {
      ${outlineCss({ shadowSize: 'small' })}
    }
  }
`;

const MaskedPhoto = styled.div<{ $maskEnabled: boolean }>`
  mask-image: ${(p) => (p.$maskEnabled ? `url(${personMaskSvg})` : 'none')};
  -webkit-mask-image: ${(p) =>
    p.$maskEnabled ? `url(${personMaskSvg})` : 'none'};
`;
