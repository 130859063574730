import { getDefaultStore } from 'jotai/vanilla';
import { showLoaderAtom } from '../core/atoms';
import * as A from '../types/actions';
import {
  AsyncActionTypes as AAT,
  ActionTypes as AT,
  GaActions,
  GaLabels,
  GenderValues,
} from '../types/enums';
import {
  CustomizationVariationItem,
  UserProfileAgeRange,
} from '../types/models';
import { DA } from '../types/redux';
import { OnboardingStatus, SignupInput } from '../types/states';
import { ApiResult, apiAction } from '../utils/asyncAction';
import { CAPABILITIES } from '../utils/constants';
import fetchOptions from '../utils/fetchOptions';
import getUnityBundleVersion from '../utils/getUnityBundleVersion';
import { trackGaEvent } from '../utils/metrics';
import { getSessionSetting } from '../utils/session';
import { API_BASE_URL } from '../utils/uri';
import { getPersonalBot, updateBot, updateUserInterests } from './profile';
import {
  setAllHints,
  setHintStatus,
  setShowRelationshipStatusButton,
} from './ui';

export const checkAccountInfo = ({
  email,
  name,
  password,
}: {
  email?: string;
  name?: string;
  password?: string;
}): DA<ApiResult<A.CheckAccountInfo>> => {
  return async (dispatch, getState) => {
    const endpoint = `${API_BASE_URL}/auth/verification/actions/check_account_info`;

    const params = { email, user_first_name: name, password };

    const fetchOpts = fetchOptions(getState(), 'POST', params);

    return apiAction<A.CheckAccountInfo>(
      AAT.CheckAccountInfo,
      dispatch,
      params,
      {
        onRequest: () => fetch(endpoint, fetchOpts),
      },
    );
  };
};

export const updateSignupState = (signupInput: SignupInput) => {
  return {
    type: AT.UpdateSignupState,
    signupInput,
  };
};

type PreSignupOptions = {
  captcha_token: string;
  user_first_name: string;
  email: string;
  password: string;
  replika_name: string;
  replika_gender: string;
  pronoun?: string;
  age_range?: UserProfileAgeRange;
};

const USE_CAPTCHA = true;

export const preSignup = (
  opts: PreSignupOptions,
): DA<ApiResult<A.PreSignup>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const firebaseToken = state.auth.persist.firebaseToken;
    const endpoint = firebaseToken
      ? `${API_BASE_URL}/auth/actions/sign_up/firebase_sign_up`
      : USE_CAPTCHA
        ? `${API_BASE_URL}/auth/actions/sign_up_with_captcha`
        : `${API_BASE_URL}/auth/actions/sign_up`;

    const reqObject = {
      ...opts,
      ...(firebaseToken
        ? { firebase_token: firebaseToken, firebase_project_id: 'replika-auth' }
        : {}),
      pronoun: opts.pronoun,
      ad_campaign: getSessionSetting('utm_campaign'),
      capabilities: CAPABILITIES.join(','),
      unity_bundle_version: getUnityBundleVersion(),
    };

    const formData = new FormData();

    for (const name in reqObject) {
      if (reqObject[name]) {
        formData.append(name, reqObject[name]);
      }
    }

    const fetchOpts = fetchOptions(state, 'POST', formData);

    return apiAction<A.PreSignup>(
      AAT.PreSignup,
      dispatch,
      {},
      {
        onRequest: () => fetch(endpoint, fetchOpts),
      },
    );
  };
};

type PostSignupOpts = {
  replikaName?: string;
  replikaGender?: GenderValues;
  avatarId: string;
  activeVariations: CustomizationVariationItem[];
};

function postSignup(dispatch) {
  fbq?.('track', 'CompleteRegistration');
  trackGaEvent(GaActions.SignUp, {
    label: GaLabels.SignUpCompleted,
  });
  setAllHints(undefined)(dispatch);
  dispatch(setHintStatus('messageReaction', 'needed'));
  dispatch(setHintStatus('backstory', 'needed'));

  dispatch(setShowRelationshipStatusButton(true));

  const store = getDefaultStore();
  store.set(showLoaderAtom, false);

  dispatch(setFreshSignup());
}

export const setFreshSignup = () => ({
  type: AT.SetFreshSignup,
});

export const completeSignup = (
  signupOpts: PostSignupOpts,
  interests: string[],
) => {
  return async (dispatch, getState) => {
    const bot = getState().profile.persist.bot;

    if (!bot) {
      await dispatch(getPersonalBot());
    }

    await Promise.all([
      dispatch(updateUserInterests(interests)),
      dispatch(
        updateBot({
          name: signupOpts.replikaName,
          gender: signupOpts.replikaGender,
          avatar_v2: {
            id: signupOpts.avatarId,
            type: '3d',
            active_variations: signupOpts.activeVariations,
          },
        }),
      ),
    ]);

    postSignup(dispatch);
  };
};

export const setOnboardingStatus = (status: OnboardingStatus) => {
  return {
    type: AT.SetOnboardingStatus,
    status,
  };
};
