import * as Accordion from '@radix-ui/react-accordion';
import { replace } from 'connected-react-router';
import {
  ReactNode,
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import styled, { keyframes } from 'styled-components/macro';
import { ReactComponent as MenuArrowIcon } from '../../icons/MenuArrow.svg';
import useHorizontalScroll from '../../utils/useHorizontalScroll';
import Scrollable from '../Scrollable';
import outlineCss from '../outlineCss';

type SubItem = {
  label: string;
  value: string;
};

type Item = {
  label: string;
  items: SubItem[];
  value: string;
};

type Props = {
  className?: string;
  items: Item[];
  onSelect: (item: string, subitem: string) => void;
};

function findItem(id: string, items: Item[]) {
  return items.find((i) => {
    return !!i.items.find((subi) => subi.value === id) || i.value === id;
  });
}

function firstChildOrSelfId(item: Item) {
  return item.items[0]?.value ?? item.value;
}

// We are setting item imperatively because
// it's to easy to break smooth scrolling if we do it via props
export type MenuRefHandle = { selectItem: (item: string) => void };

function AccordionMenu(props: Props, ref: Ref<MenuRefHandle>) {
  const { items, className, onSelect } = props;

  const [selectedItem, setSelectedItem] = useState<string | undefined>(
    undefined,
  );

  const [expanded, setExpanded] = useState<string>('');
  const dispatch = useDispatch();

  useImperativeHandle(
    ref,
    () => {
      return {
        selectItem(itemId) {
          setSelectedItem(itemId);
          const parentItem = findItem(itemId, items);
          const el = document.getElementById(
            'accordion-menu-item-' + parentItem?.value,
          );
          if (!el) return;
          el.scrollIntoView();
        },
      };
    },
    [items],
  );

  useEffect(() => {
    if (!selectedItem) return;
    const item = findItem(selectedItem, items);
    if (item) {
      setExpanded((e) => (item.value !== e ? '' : e));
    }
  }, [selectedItem, items]);

  const rootRef = useHorizontalScroll<HTMLDivElement>();
  const search = new URLSearchParams(useLocation().search);
  const categoryId = search.get('category');
  useEffect(() => {
    if (!categoryId || !ref || !('current' in ref)) return;
    const item = findItem(categoryId, items);
    if (item) {
      ref.current?.selectItem(firstChildOrSelfId(item));
      onSelect(
        item.value,
        item.value === categoryId ? firstChildOrSelfId(item) : categoryId,
      );
    }
    search.delete('category');
    dispatch(replace({ search: search.toString() }));
    // eslint-disable-next-line local-rules/exhaustive-deps
  }, [categoryId, items, ref]);

  return (
    <AccordionMenuRoot className={className}>
      <StyledScrollable noScrollbar direction="horizontal" ref={rootRef}>
        <StyledAccordion
          type="single"
          defaultValue="item-1"
          orientation="horizontal"
          collapsible
          value={expanded}
          onValueChange={(v) => {
            const item = findItem(v, items);
            setExpanded(!item || item?.items.length < 2 ? '' : v);
          }}
        >
          {items.map((item, idx) => {
            const selected =
              !!item.items.find((i) => i.value === selectedItem) ||
              item.value === selectedItem;

            const single = item.items.length < 2;

            return (
              <StyledItem
                id={'accordion-menu-item-' + item.value}
                key={idx}
                value={item.value}
              >
                <AccordionTrigger
                  selected={selected}
                  single={single}
                  onClick={() => {
                    if (expanded) {
                      setExpanded(item.items.length > 1 ? item.value : '');
                    }
                    setSelectedItem(firstChildOrSelfId(item));
                    onSelect(item.value, firstChildOrSelfId(item));
                  }}
                >
                  {item.label}
                </AccordionTrigger>
                <AccordionContent selected={selected}>
                  {item.items.map((subItem, idx) => {
                    return (
                      <SubItemButton
                        $selected={subItem.value === selectedItem}
                        key={idx}
                        onClick={() => {
                          setSelectedItem(subItem.value);
                          onSelect(item.value, subItem.value);
                        }}
                      >
                        {subItem.label}
                      </SubItemButton>
                    );
                  })}
                </AccordionContent>
              </StyledItem>
            );
          })}
        </StyledAccordion>
      </StyledScrollable>
    </AccordionMenuRoot>
  );
}

export default forwardRef(AccordionMenu);

const AccordionTrigger = forwardRef<
  HTMLButtonElement,
  {
    children: ReactNode;
    selected: boolean;
    single: boolean;
    onClick: () => void;
  }
>(({ children, selected, onClick, single, ...props }, forwardedRef) => (
  <AccordionTriggerRoot $selected={selected}>
    <ItemButton $selected={selected} onClick={onClick}>
      {children}
    </ItemButton>
    {!single && (
      <StyledTrigger
        aria-hidden={!selected}
        disabled={!selected}
        $selected={selected}
        {...props}
        ref={forwardedRef}
      >
        <MenuArrowIcon />
      </StyledTrigger>
    )}
  </AccordionTriggerRoot>
));

const AccordionContent = forwardRef<
  HTMLDivElement,
  { children: ReactNode; selected: boolean }
>(({ children, selected, ...props }, forwardedRef) => (
  <AccordionContentRoot $selected={selected} {...props} ref={forwardedRef}>
    {children}
  </AccordionContentRoot>
));

// Render background separately so that scrollbar is outside of it
const AccordionMenuRoot = styled.div`
  position: relative;
  padding: 0 10px;

  &:before {
    position: absolute;
    content: '';
    pointer-events: none;
    top: 0;
    left: 0;
    right: 0;
    height: 54px;
    background: rgba(0 0 0 / 8%);
    border-radius: 24px;
    backdrop-filter: blur(50px);
  }
`;

const StyledScrollable = styled(Scrollable)`
  overflow-x: auto;
`;

const StyledAccordion = styled(Accordion.Root)`
  display: flex;
  flex-wrap: nowrap;
`;

const StyledItem = styled(Accordion.Item)`
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
`;

// FIXME: animation is not perfect in FF, which doesn't support :has for now
// (when it does, we can remove `&[data-state="open"]:before` rule)
const AccordionTriggerRoot = styled(Accordion.Header)<{ $selected: boolean }>`
  flex: 1;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0;
  border-radius: 14px;
  height: 54px;
  padding: 0 5px;
  isolation: isolate;

  color: ${(p) => (p.$selected ? '#fff' : 'rgba(255 255 255 / 70%)')};

  &:before {
    position: absolute;
    content: '';
    top: 10px;
    left: 0;
    z-index: -1;
    pointer-events: none;
    bottom: 10px;
    width: 100%;
    background: ${(p) => (p.$selected ? 'rgba(255 255 255 / 20%)' : 'none')};
    border-radius: 14px;
    transition: background 0.2s;
  }

  transition: color 0.2s;

  &:last-child:before {
    border-radius: 14px;
  }

  &[data-state='open']:before {
    border-radius: 14px 0 0 14px;
  }

  @supports selector(:has(+ *)) {
    &:before {
      border-radius: 14px 0 0 14px;
    }

    &:has(+ [hidden]):before {
      border-radius: 14px;
    }
  }
`;

const StyledTrigger = styled(Accordion.Trigger)<{ $selected: boolean }>`
  all: unset;
  height: 24px;
  width: 24px;
  border-radius: 9px;
  background: rgba(255 255 255 / 10%);
  cursor: pointer;

  max-width: ${(p) => (p.$selected ? '24px' : '0px')};
  transition: max-width 0.2s;
  overflow: hidden;

  &:focus-visible:focus {
    ${outlineCss({ offset: '0px' })};
  }

  & > svg {
    transform: rotate(0deg);
    transition: transform 0.2s;
  }

  &[data-state='open'] > svg {
    transform: rotate(180deg);
  }
`;

const slideRight = keyframes`
  from {
    width: 0;
  }
  to {
    width: var(--radix-accordion-content-width);
  }
`;

const slideLeft = keyframes`
  from {
    width: var(--radix-accordion-content-width);
  }
  to {
    width: 0;
  }
`;

const AccordionContentRoot = styled(Accordion.Content)<{ $selected: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 0px 10px;
  margin: 10px 0;
  isolation: isolate;

  border-radius: 0px 14px 14px 0;

  background: ${(p) => (p.$selected ? 'rgba(255 255 255 / 20%)' : 'none')};

  &[hidden] {
    display: none;
  }

  &[data-state='open'] {
    animation: ${slideRight} 0.3s both;
  }

  &[data-state='closed'] {
    animation: ${slideLeft} 0.3s both;
  }
`;

const ItemButton = styled.button<{ $selected: boolean }>`
  all: unset;
  flex: 1;
  text-align: center;
  position: relative;
  z-index: 1;
  padding: 4px 8px;
  white-space: nowrap;
  border-radius: 9px;
  cursor: pointer;
  font-family: ${(p) => p.theme.fonts.display};
  font-size: 16px;
  color: ${(p) => (p.$selected ? '#fff' : 'rgba(255 255 255 / 70%)')};

  &:focus-visible:focus {
    ${outlineCss({ offset: '0px' })};
  }
`;

const SubItemButton = styled.button<{ $selected: boolean }>`
  all: unset;
  padding: 4px 8px;
  white-space: nowrap;
  border-radius: 14px;
  cursor: pointer;
  font-family: ${(p) => p.theme.fonts.display};
  font-size: 16px;
  color: ${(p) => (p.$selected ? '#fff' : 'rgba(255 255 255 / 70%)')};

  &:focus-visible:focus {
    ${outlineCss({ offset: '0px' })};
  }
`;
