import { WritableAtom, atom, useSetAtom } from 'jotai';
import { atomWithReset, atomWithStorage, useResetAtom } from 'jotai/utils';
import { StorageKeys, Themes } from '../types/enums';
import { AvatarBackground, CameraSlot } from '../types/models';
import { AvatarMode, BasketItem } from '../types/states';
import atomWithSessionStorage from '../utils/atomWithSessionStorage';
import {
  equalVariations,
  mergeReceivedVariations,
} from '../utils/storeVariations';
import { Daytime, UnityInstance, UnityPlace } from './AvatarUnity';
import { EngineStatus } from './AvatarUnity/unityUtils';
import { AvatarAtom, HeaderAtom, VoiceCallStatus } from './types';

export const avatarPreviewEnabledAtom = atom<'store' | 'profile' | null>(null);
avatarPreviewEnabledAtom.debugLabel = 'App/avatarPreviewEnabledAtom';

const DEFAULT_ATOM: AvatarAtom = {
  type: null,
  age: null,
  variations: null,
  bodyType: null,
  gender: null,
  roomItems: null,
  pets: null,
};

export function partialUpdateAtom<T extends object>(
  initialAtom: WritableAtom<T, [any], void>,
) {
  return atom<T, [Partial<T>], void>(
    (get) => get(initialAtom),
    (get, set, update: Partial<T>) => {
      set(initialAtom, { ...get(initialAtom), ...update });
    },
  );
}

// Avatar with a currently available state, synced with the server
export const currentAvatarAtom = partialUpdateAtom(
  atomWithStorage<AvatarAtom>(
    'replika.avatar',
    { ...DEFAULT_ATOM },
    undefined,
    { getOnInit: true },
  ),
);
currentAvatarAtom.debugLabel = 'App/currentAvatarAtom';

// Avatar state shown in store, including items that are not yet bought
export const avatarPreviewAtom = partialUpdateAtom(
  atomWithStorage<AvatarAtom>(
    'replika.avatarPreview',
    { ...DEFAULT_ATOM },
    undefined,
    { getOnInit: true },
  ),
);
avatarPreviewAtom.debugLabel = 'App/avatarPreviewAtom';

export const avatarProfilePreviewAtom = partialUpdateAtom(
  atom<AvatarAtom>({ ...DEFAULT_ATOM }),
);
avatarPreviewAtom.debugLabel = 'App/avatarProfilePreviewAtom';

export const avatarUpdateAtom = atom(
  undefined,
  (get, set, update: Partial<AvatarAtom>) => {
    set(currentAvatarAtom, update);

    if (get(avatarPreviewEnabledAtom) !== 'profile') {
      set(avatarProfilePreviewAtom, { ...get(currentAvatarAtom), ...update });
    }

    if (get(avatarPreviewEnabledAtom) !== 'store') {
      let newAvatarAtom = {
        ...get(currentAvatarAtom),
        variations: get(avatarPreviewAtom).variations,
        roomItems: get(avatarPreviewAtom).roomItems,
      };

      if ('variations' in update) {
        const variations = mergeReceivedVariations(
          get(avatarPreviewAtom).variations ?? [],
          update.variations ?? [],
        );

        if (
          !equalVariations(get(avatarPreviewAtom).variations ?? [], variations)
        ) {
          newAvatarAtom.variations = variations;
        }
      }

      if ('roomItems' in update) {
        const roomItems = mergeReceivedVariations(
          get(avatarPreviewAtom).roomItems ?? [],
          update.roomItems ?? [],
        );

        if (
          !equalVariations(get(avatarPreviewAtom).roomItems ?? [], roomItems)
        ) {
          newAvatarAtom.roomItems = roomItems;
        }
      }

      set(avatarPreviewAtom, newAvatarAtom);
    }
  },
);

export const basketAtom = atomWithStorage<BasketItem[]>(
  'replika.basket',
  [],
  undefined,
  { getOnInit: true },
);
basketAtom.debugLabel = 'App/basketAtom';

export const avatarAtom = atom((get) => {
  switch (get(avatarPreviewEnabledAtom)) {
    case 'store':
      return get(avatarPreviewAtom);
    case 'profile':
      return get(avatarProfilePreviewAtom);
    default:
      return get(currentAvatarAtom);
  }
});
avatarAtom.debugLabel = 'App/avatarAtom';

export const backgroundAtom = atom<AvatarBackground | null>('main-page-framed');
backgroundAtom.debugLabel = 'App/backgroundAtom';

export const showLoaderAtom = atom(false);
showLoaderAtom.debugLabel = 'App/showLoaderAtom';

export const avatarBaseBundleSetAtom = atom<'all' | 'onboarding'>('all');
avatarBaseBundleSetAtom.debugLabel = 'App/avatarBaseBundleSetAtom';

export const avatarStateAtom = atom<'disabled' | '2d' | '3d'>('disabled');
avatarStateAtom.debugLabel = 'App/avatarStateAtom';

export const avatarHiddenAtom = atom(false);
avatarHiddenAtom.debugLabel = 'App/avatarHiddenAtom';

export const avatarProgressAtom = atom(0);
avatarProgressAtom.debugLabel = 'App/avatarProgressAtom';

export const cameraSlotAtom = atomWithReset<CameraSlot | null>(null);
cameraSlotAtom.debugLabel = 'App/cameraSlotAtom';

export const radioEnabledAtom = atomWithStorage<boolean>(
  'replika.radioEnabled',
  true,
  undefined,
  { getOnInit: true },
);
radioEnabledAtom.debugLabel = 'App/radioEnabledAtom';

export const audioVolumeAtom = atomWithStorage<number>(
  'replika.audioVolume',
  50,
  undefined,
  { getOnInit: true },
);

export const behaviourAtom = atom<
  | 'main_no_walk'
  | 'web_call'
  | '3d_call'
  | 'chat'
  | 'web_subscription'
  | 'store'
  | 'static'
  | string
  | null
>(null);
behaviourAtom.debugLabel = 'App/behaviourAtom';

export const unityPlaceAtom = atom<UnityPlace | null>(null);
unityPlaceAtom.debugLabel = 'App/unityPlaceAtom';

export const behaviourStateAtom = atom<string | null>(null);
behaviourStateAtom.debugLabel = 'App/behaviourStateAtom';

export const dayTimeAtom = atom<Daytime | 'dark'>('day');
dayTimeAtom.debugLabel = 'App/dayTimeAtom';

export const themeNameAtom = atom<Themes>(Themes.Dark);
themeNameAtom.debugLabel = 'App/themeNameAtom';

export function useResetAvatar() {
  const setAvatar = useSetAtom(currentAvatarAtom);
  const setAvatarPreview = useSetAtom(avatarPreviewAtom);
  const resetCameraSlot = useResetAtom(cameraSlotAtom);

  return () => {
    setAvatar({ ...DEFAULT_ATOM });
    setAvatarPreview({ ...DEFAULT_ATOM });
    resetCameraSlot();
  };
}

export const headerAtom = atom<HeaderAtom>({
  id: null,
  left: null,
  titleType: 'title',
  title: null,
  menuName: null,
  right: null,
  expandThreshold: 0,
});
headerAtom.debugLabel = 'App/headerAtom';

export const voiceCallStatusAtom = atom<VoiceCallStatus>('idle');
voiceCallStatusAtom.debugLabel = 'App/voiceCallStatusAtom';

export const unityLoadAttemptAtom = atomWithSessionStorage<number>(
  StorageKeys.UNITY_LOAD_ATTEMPT,
  0,
);
unityLoadAttemptAtom.debugLabel = 'App/unityLoadAttemptAtom';

export const unityEngineStatusAtom = atom<EngineStatus>(EngineStatus.Initial);
unityEngineStatusAtom.debugLabel = 'App/unityEngineStatusAtom';

export const unityInstanceAtom = atom<UnityInstance | null>(null);

export const unityRoleplayIdAtom = atom<string | null>(null);
unityRoleplayIdAtom.debugLabel = 'App/unityRoleplayIdAtom';

export const ageLockAtom = atom(false);
ageLockAtom.debugLabel = 'App/ageLockAtom';

export const avatarEmotionAtom = atom<string | null>(null);
avatarEmotionAtom.debugLabel = 'App/avatarEmotionAtom';

export const autoModeAtom = atom<AvatarMode>('animatedMedium');
autoModeAtom.debugLabel = 'App/autoModeAtom';
