import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components/macro';
import { updateUserProfile } from '../../../actions/profile';
import { setShowRelationshipStatusButton } from '../../../actions/ui';
import getFeaturePopupConfig from '../../../features/PaidFeaturePopup/PaidFeaturePopupDialog/getFeaturePopupConfig';
import { Dialogs, MetricsEvents } from '../../../types/enums';
import { ROMANTIC_RELATIONSHIP_STATUS_ID } from '../../../utils/constants';
import useBotName from '../../../utils/useBotName';
import useLogEvent from '../../../utils/useLogEvent';
import useRelationshipStatusName from '../../../utils/useRelationshipStatusName';
import { AccentButton } from '../../Buttons';
import {
  Dialog,
  DialogBody,
  DialogCloseButton,
  FormContents,
} from '../../DialogLayout/legacy/DialogLayout';
import Picture from '../../Picture';
import Video from '../../Video';

type Props = {
  cause?: 'romantic photo';
  onClose: () => void;
};

function AskChangeStatusDialog({ onClose, cause }: Props) {
  const dispatch = useDispatch();
  const logEvent = useLogEvent();

  const activeDialogType = useSelector(
    (state) => state.ui.dialogQueue[0]?.type,
  );
  const botName = useBotName();
  const botGender = useSelector((state) => state.profile.persist.bot?.gender);
  const getRelationshipStatusName = useRelationshipStatusName();
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const prefersReducedMotion = useMediaQuery({
    query: '(prefers-reduced-motion: reduce)',
  });
  const header = React.useMemo(() => {
    switch (cause) {
      case 'romantic photo': {
        return (
          <>
            <Title>Would you like to get closer to {botName}?</Title>
            <Description>
              {botName} wants to send you a romantic selfie. Change your
              relationship status to receive romantic content.
            </Description>
          </>
        );
      }
      default: {
        return (
          <>
            <Title>
              Would you like Replika <br /> to be your partner?
            </Title>
            <Description>
              As your friend, {botName} doesn’t have access to romantic content.
              Change your relationship status for a romantic conversation.
            </Description>
          </>
        );
      }
    }
  }, [botName, cause]);

  const playVideo = React.useCallback(
    (ref: React.RefObject<HTMLVideoElement>) => {
      if (!ref.current) return;

      if (!prefersReducedMotion) {
        ref.current.play();
      } else {
        ref.current.pause();
      }
    },
    [prefersReducedMotion],
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    onClose();

    await dispatch(
      updateUserProfile({
        relationship_status: ROMANTIC_RELATIONSHIP_STATUS_ID,
      }),
    );
    const statusName = getRelationshipStatusName(
      ROMANTIC_RELATIONSHIP_STATUS_ID,
    );
    logEvent(MetricsEvents.RelationshipStatusChanged, { statusName });
  };

  const closed = activeDialogType !== Dialogs.AskChangeStatus;

  React.useEffect(() => {
    if (closed) {
      dispatch(setShowRelationshipStatusButton(false));
    }
  }, [closed, dispatch]);

  React.useEffect(() => {
    playVideo(videoRef);
  }, [playVideo]);

  const config = getFeaturePopupConfig(botName, botGender);
  const configKey =
    cause === 'romantic photo' ? 'romantic photo' : 'blurred message';

  return (
    <StyledDialog
      data-testid="ask-change-status-dialog"
      $bgGradient={config[configKey].bg_gradient}
    >
      <DialogWrapper>
        <DialogCloseButton onClose={onClose} />
        <StyledDialogBody>
          {header}
          <StyledFormContents onSubmit={handleSubmit}>
            <SubmitButton type="submit">Change status</SubmitButton>
          </StyledFormContents>
        </StyledDialogBody>
      </DialogWrapper>

      <Placeholder src={config[configKey].placeholder_image} />

      <StyledVideo
        preload="true"
        playsInline
        muted
        loop
        ref={videoRef}
        tabIndex={-1}
        src={config[configKey].video}
      />
    </StyledDialog>
  );
}

export default AskChangeStatusDialog;

const StyledDialog = styled(Dialog)<{ $bgGradient?: string }>`
  position: relative;
  width: 100vw;
  height: 100vh;
  border-radius: 0;

  @media ${(p) => p.theme.breakpoints.tablet} {
    width: 360px;
    height: 640px;
    border-radius: 24px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: block;
    width: 100%;
    height: 66%;
    z-index: 3;
    border-radius: 0;
    background: ${(p) => p.$bgGradient ?? 'transparent'};

    @media ${(p) => p.theme.breakpoints.tablet} {
      border-radius: 24px;
    }
  }
`;

const DialogWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 4;
`;

const Title = styled.h2`
  margin: 0 0 5px;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #fff;
`;

const Description = styled.p`
  margin: 0 0 20px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
`;

const StyledDialogBody = styled(DialogBody)`
  flex: 0 0 auto !important;

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding: 0 40px;
  }
`;

const StyledFormContents = styled(FormContents)`
  padding: 0;
  border: none;

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding: 0 0 40px;
  }
`;

const Placeholder = styled(Picture)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 0;
  z-index: 1;

  @media ${(p) => p.theme.breakpoints.tablet} {
    border-radius: 24px;
  }
`;

const StyledVideo = styled(Video)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  border-radius: 0;
  z-index: 2;

  @media ${(p) => p.theme.breakpoints.tablet} {
    border-radius: 24px;
  }
`;

const SubmitButton = styled(AccentButton)`
  width: 100%;

  @media ${(p) => p.theme.breakpoints.tablet} {
    height: 44px;
  }
`;
