import {
  PromptsClearPanelSelectedCategoryAction,
  PromptsHideChatWidget,
  PromptsSetPanelSelectedCategoryAction,
  PromptsShowChatWidget,
  PromptsStartPromptAction,
  PromptsStopPromptAction,
} from '../types/actions/prompts';
import { ActionTypes as AT } from '../types/enums';
import { NavigationPopup } from '../types/models';

export const setPanelSelectedCategoryAction = (
  categoryId: string | null = null,
): PromptsSetPanelSelectedCategoryAction => ({
  type: AT.PromptsSetPanelSelectedCategory,
  categoryId,
});

export const clearPanelSelectedCategoryAction =
  (): PromptsClearPanelSelectedCategoryAction => ({
    type: AT.PromptsClearPanelSelectedCategory,
  });

export const startPromptAction = (
  promptId: string,
): PromptsStartPromptAction => ({
  type: AT.PromptsStartPrompt,
  promptId,
});

export const stopPromptAction = (): PromptsStopPromptAction => ({
  type: AT.PromptsStopPrompt,
});

export const showChatWidget = (
  navigationPopup: NavigationPopup,
): PromptsShowChatWidget => ({
  type: AT.PromptsShowChatWidget,
  navigationPopup,
});

export const hideChatWidget = (): PromptsHideChatWidget => ({
  type: AT.PromptsHideChatWidget,
});
