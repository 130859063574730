import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { getUnityBinaries, sendAvatarPhoto } from '../../../actions/avatars';
import { CustomEventsKeys, Routes, Themes } from '../../../types/enums';
import createLogger from '../../../utils/createLogger';
import isMobileClient from '../../../utils/isMobileClient';
import useApi from '../../../utils/useApi';
import { unityEngineStatusAtom, unityInstanceAtom } from '../../atoms';
import { AvatarAtom } from '../../types';
import useInAuth from '../../useInAuth';
import avatarKeyHash from '../avatarKeyHash';
import { EngineStatus, useDebouncedValue } from '../unityUtils';
import { useUnity3dDisabled } from '../useUnity3dDisabled';
import { photoStudio } from './PhotoStudio';
import { photoStudioLastAvatarHash } from './atoms';
import { createThrottlePromisesFunction } from './utils';

const runWithThrottle = createThrottlePromisesFunction();

const logger = createLogger('PhotoStudio');

export const AvatarPhotoStudio = ({ avatar }: { avatar?: AvatarAtom }) => {
  const dispatch = useDispatch();
  const instance = useAtomValue(unityInstanceAtom);
  const engineStatus = useAtomValue(unityEngineStatusAtom);
  const [studioLastAvatarHash, setStudioLastAvatarHash] = useAtom(
    photoStudioLastAvatarHash,
  );

  // optimization: prevent avatarPreview flickering on switching pages
  const avatarPreview = useDebouncedValue(avatar);

  const avatarItems = useMemo(
    () => avatarPreview?.variations ?? [],
    [avatarPreview?.variations],
  );

  const currentAvatarHash = avatarKeyHash({
    type: avatarPreview?.type ?? '',
    age: avatarPreview?.age ?? 0,
    variations: avatarItems ?? 0,
    bodyType: avatarPreview?.bodyType ?? {},
    theme: Themes.Default,
    cameraSlot: null,
  });

  const unityBinaries = useApi(
    (state) => state.avatars.persist.unityBinaries,
    getUnityBinaries,
    { memoDeepEqual: true },
  );

  const inAuth = useInAuth();
  const isMobile = isMobileClient();
  const disabled3d = useUnity3dDisabled();

  const isRoomRoute = !!useRouteMatch(Routes.Room)?.isExact;
  const isChatRoute = !!useRouteMatch(Routes.Chat)?.isExact;
  const isMainOrChatRoute = isRoomRoute || isChatRoute;

  const engineIsReady = engineStatus === EngineStatus.Ready;
  const hasUnpaidVariations = avatarItems.some(
    (item) => item.bought_count === 0,
  );

  useEffect(() => {
    if (
      !isMobile &&
      !inAuth &&
      engineIsReady &&
      isMainOrChatRoute &&
      !hasUnpaidVariations &&
      (!studioLastAvatarHash || currentAvatarHash !== studioLastAvatarHash)
    ) {
      if (!instance) {
        logger.error('Unity instance is not ready');
        return;
      }

      photoStudio.takeAllPhotos(
        instance,
        avatarItems,
        unityBinaries?.selfie_bundles ?? [],
      );

      setStudioLastAvatarHash(currentAvatarHash);
    }
  }, [
    engineIsReady,
    avatarItems,
    instance,
    isMainOrChatRoute,
    inAuth,
    hasUnpaidVariations,
    studioLastAvatarHash,
    currentAvatarHash,
    isMobile,
    unityBinaries?.selfie_bundles,
  ]);

  useEffect(() => {
    const handleFileEvent = (event) => {
      const { detail } = event as CustomEvent<{
        file: Uint8Array;
        path: string;
      }>;

      const { file, path } = detail || {};

      if (!file || !path) return;

      logger.log('Upload photo to server:', path);

      const params = photoStudio.getPhotoParams(path);

      if (!params) return;

      const imageFile = new File([file], 'selfie.jpg', {
        type: 'image/jpeg',
      });

      /**
       * Limit the maximum number of parallel promises for slow internet connection.
       */
      runWithThrottle(() =>
        dispatch(
          sendAvatarPhoto(imageFile, params, () =>
            photoStudio.deletePhotoParams(path),
          ),
        ),
      );
    };

    document.addEventListener(
      CustomEventsKeys.UNITY_FILE_EVENT,
      handleFileEvent,
    );

    return () => {
      document.removeEventListener(
        CustomEventsKeys.UNITY_FILE_EVENT,
        handleFileEvent,
      );
    };
    // eslint-disable-next-line local-rules/exhaustive-deps
  }, []);

  useEffect(() => {
    if (disabled3d) {
      photoStudio.destroy();
    }
  }, [disabled3d]);

  useEffect(() => {
    return () => {
      photoStudio.destroy();
    };
  }, []);

  return null;
};
