import MemoryRecordAddModal from 'src/features/Memory/Components/MemoryModals/MemoryRecordAddModal';
import MemoryRecordEditModal from 'src/features/Memory/Components/MemoryModals/MemoryRecordEditModal';
import { useMobileQuery } from '../../components/responsive';
import { ModalRoutes, Routes } from '../../types/enums';
import BackstoryModal from './BackstoryModal';
import TransitionModalRoute, {
  getModalRouteMatcher,
} from './ModalRoutes/TransitionModalRoute';
import ModalScreenLayout from './ModalScreenLayout';
import settingsRoutes from './SettingsModal/settingsRoutes';
import StoreBasketModal from './StoreBasketModal';
import StoreDialogItemModal from './StoreDialogItemModal';
import TransitionRoutes from './TransitionRoutes';
import TransitionRoute from './TransitionRoutes/TransitionRoute';

// Transitions on mobile are instant for now, otherwise we'd need to implement
// nested transition routing, which would be tricky
export default function Modals() {
  const isMobile = useMobileQuery();
  return (
    <TransitionRoutes
      root={ModalScreenLayout}
      getRouteMatcher={getModalRouteMatcher}
      instant={isMobile}
    >
      {settingsRoutes(isMobile)}
      <TransitionRoute path={Routes.StoreCategoryTabItem}>
        <StoreDialogItemModal />
      </TransitionRoute>
      <TransitionRoute path={Routes.StoreBasket}>
        <StoreBasketModal />
      </TransitionRoute>
      <TransitionModalRoute
        instant={isMobile}
        path={ModalRoutes.MemoryRecordAdd}
      >
        <MemoryRecordAddModal />
      </TransitionModalRoute>
      <TransitionModalRoute
        instant={isMobile}
        path={ModalRoutes.MemoryRecordEdit}
      >
        <MemoryRecordEditModal />
      </TransitionModalRoute>
      <TransitionModalRoute instant={isMobile} path={ModalRoutes.Backstory}>
        <BackstoryModal />
      </TransitionModalRoute>
    </TransitionRoutes>
  );
}
