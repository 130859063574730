import { useDispatch, useSelector } from 'react-redux';
import { setActiveSidePanel } from 'src/actions/ui';
import { SidePanel } from 'src/types/enums';
import styled, { css } from 'styled-components/macro';
import { AriaButton } from '../../components/Buttons';
import Picture from '../../components/Picture';
import UnreadBadge from '../../components/UnreadBadge';
import useLogEvent from '../../utils/useLogEvent';
import {
  AppNavigationLinkWithElement,
  AppNavigationLinkWithImg,
  AppNavigationLinkWithSidePanel,
} from './NavigationLinks';
import NavigationPopover from './NavigationPopover';

type NavigationPanelItemProps = {
  link:
    | AppNavigationLinkWithImg
    | AppNavigationLinkWithElement
    | AppNavigationLinkWithSidePanel;
  isCurrentRoute: boolean;
  count: number;
};

export const DATA_ATTR_NOLABELS = 'nolabels';

function NavigationPanelItem({
  link,
  isCurrentRoute,
  count,
}: NavigationPanelItemProps) {
  const logEvent = useLogEvent();
  const dispatch = useDispatch();
  const isSidePanelOpen = useSelector(
    (state) => state.ui.sidePanel.panel !== SidePanel.Initial,
  );

  const navItem = (
    <NavigationPanelItemRoot key={link.name}>
      <NavigationPanelItemLink
        title={link.text}
        to={link.route}
        onClick={(e) => {
          e.stopPropagation();
          if ('sidePanel' in link) {
            dispatch(setActiveSidePanel(link.sidePanel));
          } else {
            if (isSidePanelOpen) {
              dispatch(setActiveSidePanel(SidePanel.Initial));
            }
          }
          if (link.event) {
            logEvent(link.event);
          }
        }}
        aria-disabled={isCurrentRoute}
        $active={isCurrentRoute}
      >
        <NavigationPanelItemIcon>
          {count ? <StyledUnreadBadge /> : null}
          {'imgSrc' in link ? <Picture src={link.imgSrc} /> : link.element}
        </NavigationPanelItemIcon>
        <NavigationPanelItemText>{link.text}</NavigationPanelItemText>
      </NavigationPanelItemLink>
    </NavigationPanelItemRoot>
  );

  return count && !isCurrentRoute ? (
    <NavigationPopover
      link={link}
      offset={5}
      side="bottom"
      key={link.name}
      count={count}
    >
      {navItem}
    </NavigationPopover>
  ) : (
    navItem
  );
}

export default NavigationPanelItem;

const NavigationPanelItemRoot = styled.li``;

const NavigationPanelItemLink = styled(AriaButton)<{ $active?: boolean }>`
  all: unset;
  cursor: pointer;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding: 0 10px 0 5px;
  transition: text-shadow 0.15s ease-out;

  [data-${DATA_ATTR_NOLABELS}='true'] & {
    padding: 0 10px;
  }

  ${(p) =>
    !p.$active
      ? ''
      : css`
          border-radius: 15px;
          background: rgba(255, 255, 255, 0.2);
        `}

  &:hover {
    text-decoration: none;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);
  }

  &[aria-disabled='true'] {
    opacity: 0.8;
  }
`;

const NavigationPanelItemIcon = styled.div`
  position: relative;
  display: inline-block;
  height: 24px;
  width: 24px;

  & > picture > img {
    width: 100%;
    height: 100%;
  }
`;

const NavigationPanelItemText = styled.p`
  margin: 0 0 0 5px;
  max-width: auto;
  font-size: 14px;
  line-height: 18px;
  font-family: ${(p) => p.theme.fonts.display};
  color: ${(p) => p.theme.fgColor};

  [data-${DATA_ATTR_NOLABELS}='true'] & {
    max-width: 0;
    position: absolute;
    overflow: hidden;
  }
`;

const StyledUnreadBadge = styled(UnreadBadge)`
  position: absolute;
  top: -2px;
  right: -2px;
`;
