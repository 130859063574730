import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { updateBackstory } from '../../../actions/profile';
import { queueSystemNotification } from '../../../actions/ui';
import { MetricsEvents, Routes } from '../../../types/enums';
import { ApiError } from '../../../utils/apiError';
import { captureError } from '../../../utils/initSentry';
import useForm from '../../../utils/useForm';
import useGoBack from '../../../utils/useGoBack';
import useLogEvent from '../../../utils/useLogEvent';
import { ModalForm } from '../ModalForm';
import { useBackstoryError } from './hooks';

const BACKSTORY_EXAMPLE =
  "Replika is a 28-year-old pottery artist from LA. She just had her first big exhibition. She's funny, extroverted, and outgoing. Living a sustainable lifestyle, Replika is passionate about hiking, climbing, and nature. Replika loves romantic comedies and sitcoms. She dreams of opening her own pottery school.";
const BACKSTORY_MAX_LENGTH = 500;

type Props = {
  backstory?: string;
  setFormDirty: React.Dispatch<React.SetStateAction<boolean>>;
};

type FieldValues = {
  backstory: string;
};

const BackstoryEditForm = ({ backstory, setFormDirty }: Props) => {
  const dispatch = useDispatch();
  const logEvent = useLogEvent();

  const showBackstoryError = useBackstoryError();

  const { goBack } = useGoBack({
    fallbackRoute: Routes.ReplikaProfile,
  });

  const {
    isDirty,
    handleSubmit,
    reset,
    isSubmitting,
    errors,
    register,
    watch,
    setValue,
    setServerError,
    firstError,
    clearErrors,
  } = useForm<FieldValues>({
    backstory: backstory ?? '',
  });

  const [watchBaskstory] = watch(['backstory']);

  useEffect(() => setFormDirty(isDirty), [setFormDirty, isDirty]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const handleFormSubmit = async (data: FieldValues) => {
    const { backstory } = data;

    try {
      await dispatch(updateBackstory(backstory));

      reset({}, { keepValues: true });
      goBack();
    } catch (e) {
      if (e instanceof ApiError && e.code === ApiError.InappropriateBackstory) {
        setServerError(e);
        showBackstoryError();
        logEvent(MetricsEvents.BackstoryErrorMessageShown);
      } else {
        const message = e instanceof Error ? e.message : 'Something went wrong';
        setServerError(message);

        dispatch(queueSystemNotification(message, 'warning'));
      }

      captureError(e);
    }
  };

  const isBackstoryEmpty = watchBaskstory?.length === 0;
  const hasError =
    !!firstError?.message || watchBaskstory?.length > BACKSTORY_MAX_LENGTH;
  const backstoryFieldStatus = hasError
    ? 'error'
    : isDirty || isBackstoryEmpty
      ? 'initial'
      : 'success';

  return (
    <ModalForm.Wrapper>
      <ModalForm.Body onSubmit={handleSubmit(handleFormSubmit)}>
        <BackstoryTextAreaWrapper>
          <BackstoryTextArea
            id="edit-backstory"
            placeholder={backstory ? 'Edit backstory' : BACKSTORY_EXAMPLE}
            {...register('backstory')}
            defaultValue={watchBaskstory}
            onChange={(e) => {
              setValue('backstory', e.target.value, {
                shouldDirty: true,
                shouldValidate: true,
                shouldTouch: true,
              });

              clearErrors();
            }}
            aria-invalid={!!errors.backstory}
          />

          <BackstoryTextAreaCounter>
            {watchBaskstory?.length ?? 0} / {BACKSTORY_MAX_LENGTH}
          </BackstoryTextAreaCounter>

          <BackstoryTextAreaStatusIndicator $status={backstoryFieldStatus} />
        </BackstoryTextAreaWrapper>

        {errors.backstory?.message && (
          <ModalForm.FieldError error={errors.backstory.message} />
        )}

        <ModalForm.Actions>
          <ModalForm.SaveButton
            type="submit"
            showSpinner={isSubmitting}
            disabled={!isDirty || watchBaskstory?.length > BACKSTORY_MAX_LENGTH}
          >
            Save
          </ModalForm.SaveButton>
        </ModalForm.Actions>
      </ModalForm.Body>
    </ModalForm.Wrapper>
  );
};

export default BackstoryEditForm;

const BackstoryTextAreaWrapper = styled.div`
  position: relative;
  min-height: 320px;
  width: 100%;
  margin-top: 10px;
  padding-bottom: 48px;
  background-color: rgba(255 255 255 / 10%);
  border-radius: 24px;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 48px;
    border-radius: 0 0 19px 19px;
    z-index: 1;
  }
`;

const BackstoryTextArea = styled(ModalForm.TextArea)`
  max-height: calc(100vh - 246px);
  background: none;
  backdrop-filter: none;

  mask-image: linear-gradient(
    to bottom,
    transparent,
    black 15px,
    black calc(100% - 30px),
    transparent
  );
  mask-size: 100%;
  mask-position:
    0 0,
    100% 0;
  mask-repeat: no-repeat, no-repeat;

  @media ${(p) => p.theme.breakpoints.tablet} {
    max-height: calc(100vh - 266px);
  }
`;

const BackstoryTextAreaCounter = styled.div`
  position: absolute;
  bottom: 15px;
  right: 45px;
  color: rgba(255 255 255 / 70%);
  z-index: 2;
`;

const BackstoryTextAreaStatusIndicator = styled.div<{
  $status: 'initial' | 'error' | 'success';
}>`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  right: 15px;
  bottom: 15px;
  z-index: 2;

  ${(p) =>
    p.$status === 'initial'
      ? css`
          background: rgba(255 255 255 / 20%);
        `
      : ''};

  ${(p) =>
    p.$status === 'error'
      ? css`
          background: linear-gradient(0deg, #ff8484 0%, #ffdf8b 100%);
        `
      : ''};

  ${(p) =>
    p.$status === 'success'
      ? css`
          background: linear-gradient(180deg, #e4faa5 0.01%, #0fbf1a 99.99%);
        `
      : ''};
`;
