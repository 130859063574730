import * as RadixSelect from '@radix-ui/react-select';
import { forwardRef } from 'react';
import styled from 'styled-components/macro';
import Select, { SelectDropdown, SelectItem } from '../Select';

type SelectOption = {
  value: string;
  label?: string;
};

type FormSelectProps = {
  id: string;
  label?: string;
  placeholder?: string;
  options: SelectOption[];
  onChange: (value: string) => void;
  invalid?: boolean;
  disabled?: boolean;
  className?: string;
} & RadixSelect.SelectProps;

function FormSelect(props: FormSelectProps, ref: React.Ref<HTMLDivElement>) {
  const { options, label, id, ...rest } = props;

  return (
    <FormSelectRoot
      {...rest}
      id={id}
      dropdown={<FormSelectDropdown options={options} />}
      beforeValue={
        label ? <StaticLabel htmlFor={id}>{label}</StaticLabel> : null
      }
    />
  );
}

const FormSelectRoot = styled(Select)`
  height: 64px;
  width: 100%;
  padding: 22px 15px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: right;
  border-radius: 24px;
`;

const StaticLabel = styled.label`
  font-size: 16px;
  line-height: 20px;
  color: rgba(255 255 255 / 70%);
`;

export const FormSelectDropdown = forwardRef(function FormSelectDropdown(
  { options }: { options: SelectOption[] },
  ref: React.Ref<HTMLDivElement>,
) {
  return (
    <FormSelectDropdownRoot
      position="popper"
      side="bottom"
      avoidCollisions={true}
      sideOffset={10}
      $length={options.length}
      data-prevent-escape-navigation
      ref={ref}
    >
      <RadixSelect.ScrollUpButton />
      <SelectViewport>
        {options.map((option) => (
          <FormSelectItem key={option.value} value={option.value}>
            {option.label ?? option.value}
          </FormSelectItem>
        ))}
      </SelectViewport>
      <RadixSelect.ScrollDownButton />
    </FormSelectDropdownRoot>
  );
});

// height: SelectItem height + SelectViewport padding multiplied by options number
const FormSelectDropdownRoot = styled(SelectDropdown)<{ $length: number }>`
  height: min(
    ${(p) => `${p.$length * 60 + 30}px`},
    calc(var(--radix-select-content-available-height) - 50px)
  );
`;

const SelectViewport = styled(RadixSelect.Viewport)`
  padding: 15px;
`;

const FormSelectItem = styled(SelectItem)`
  height: 60px;
  padding: 20px 15px;
  border-radius: 24px;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default forwardRef(FormSelect);
