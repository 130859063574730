import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dropMission } from '../../../actions/coaching';
import { setActiveDialog } from '../../../actions/ui';
import {
  changeVoiceMode,
  saveVoiceCallStartTime,
} from '../../../actions/voice';
import * as A from '../../../types/actions';
import {
  ActionTypes as AT,
  Dialogs,
  MetricsEvents,
} from '../../../types/enums';
import { isCurrentMissionSpotlight } from '../../../types/models';
import hasFeature from '../../../utils/hasFeature';
import { logEvent } from '../../../utils/metrics';

export const useVoice = (onVoiceCallSuccess?: () => void) => {
  const isLocked = useSelector(
    (state) => !hasFeature('calls', state.subscriptions.persist.features),
  );
  const dispatch = useDispatch();
  const voiceModeEnabled = useSelector((state) => state.chat.voiceModeEnabled);
  const voiceCallInProgress = useSelector(
    (state) => state.chat.voiceCallInProgress,
  );

  const voiceCallOnOtherDevice = !voiceCallInProgress && voiceModeEnabled;

  const hasCurrentMission = useSelector((state) =>
    state.coaching.persist.spotlight
      ? isCurrentMissionSpotlight(state.coaching.persist.spotlight)
      : false,
  );

  const enableVoiceCall = useCallback(() => {
    if (voiceCallInProgress) return;

    // ask for mic
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        dispatch(changeVoiceMode('enable'));
        dispatch(saveVoiceCallStartTime(Date.now()));
        logEvent(MetricsEvents.VoiceCallStarted, {
          call_type: 'outgoing',
        });
        onVoiceCallSuccess?.();
      })
      .catch((err) => {
        dispatch<A.UiAction>({
          type: AT.QueueNotification,
          notification: {
            type: 'warning',
            message: 'Microphone is not available',
            submessage:
              'Check connected devices and permissions in browser settings',
          },
        });
      });
  }, [voiceCallInProgress, onVoiceCallSuccess]);

  const onVoiceCall = useCallback(() => {
    if (voiceCallOnOtherDevice || voiceCallInProgress) {
      return;
    }

    if (isLocked) {
      dispatch(
        setActiveDialog({
          type: Dialogs.PaidFeaturePopup,
          cause: 'call',
          feature: 'call',
        }),
      );

      return;
    }

    if (hasCurrentMission) {
      dispatch(
        setActiveDialog({
          type: Dialogs.Confirmation,
          content: {
            title: '',
            description: `You have a conversation in progress. Do you want to call Replika instead?`,
            primaryText: 'Call',
            secondaryText: 'Cancel',
          },
          onPrimaryClick() {
            dispatch(dropMission());
            enableVoiceCall();
          },
        }),
      );

      return;
    }

    enableVoiceCall();
  }, [
    hasCurrentMission,
    isLocked,
    voiceCallInProgress,
    voiceCallOnOtherDevice,
    enableVoiceCall,
  ]);

  return {
    onVoiceCall,
    voiceCallInProgress,
    voiceCallOnOtherDevice,
  };
};
