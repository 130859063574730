import * as A from '../types/actions';
import { ToSuccess } from '../types/asyncActions';
import { AsyncActionTypes as AAT, ActionTypes as AT } from '../types/enums';
import { Reducer } from '../types/redux';
import { CoachingState } from '../types/states';
import applyReducer from '../utils/applyReducer';

const DEFAULT_STATE: CoachingState = {
  persist: {
    tracks: {},
    trackIdList: [],
    spotlight: null,
    categories: null,
  },
  lastJourneyChange: {
    updated: Date.now(),
    affectedTrackIds: [],
  },
  missions: {},
  missionState: null,
};

type R<X extends A.CoachingAction | A.ChatAction | A.IncomingWsAction> =
  Reducer<CoachingState, X>;
type RSuccess<X> = Reducer<CoachingState, ToSuccess<X>>;

const getTracksSuccess: RSuccess<A.GetTracks> = (
  state,
  { result: { tracks, categories } },
) => ({
  ...state,
  persist: {
    ...state.persist,
    categories,
    tracks: tracks.reduce(
      (acc, t) => {
        acc[t.id] = t;
        return acc;
      },
      { ...state.persist.tracks },
    ),
    trackIdList: tracks.map((t) => t.id),
  },
});

const getTrackSuccess: RSuccess<A.GetTrack> = (state, { result: track }) => ({
  ...state,
  persist: {
    ...state.persist,
    tracks: {
      ...state.persist.tracks,
      [track.id]: track,
    },
  },
});

const getMissionsSuccess: RSuccess<A.GetMissions> = (
  state,
  { result: { missions }, params: { trackId } },
) => ({
  ...state,
  missions: {
    ...state.missions,
    [trackId]: missions,
  },
});

const getSpotlightSuccess: RSuccess<A.GetSpotlight> = (
  state,
  { result: { spotlight } },
) => ({
  ...state,
  persist: {
    ...state.persist,
    spotlight,
  },
});

const journeyChanged: R<A.JourneyChanged> = (state, { affectedTrackIds }) => ({
  ...state,
  lastJourneyChange: {
    updated: Date.now(),
    affectedTrackIds,
  },
});

const setMissionState: RSuccess<A.GetPersonalBotChat> = (state, { result }) => {
  return {
    ...state,
    missionState: result.mission_state,
  };
};

const resetMissionStateOnDrop: RSuccess<A.DropMission> = (state) => {
  return {
    ...state,
    missionState: null,
  };
};

const resetMissionStateOnSkip: RSuccess<A.SkipMission> = (state) => {
  return {
    ...state,
    missionState: null,
  };
};

const resetCoaching = () => ({
  ...DEFAULT_STATE,
});

export default function coaching(
  state: CoachingState = DEFAULT_STATE,
  action: A.AnyAction,
) {
  return applyReducer(
    'coaching',
    {
      [AAT.GetTracks]: {
        success: getTracksSuccess,
      },
      [AAT.GetTrack]: {
        success: getTrackSuccess,
      },
      [AAT.GetMissions]: {
        success: getMissionsSuccess,
      },
      [AAT.GetSpotlight]: {
        success: getSpotlightSuccess,
      },
      [AT.WsJourneyChanged]: journeyChanged,
      [AAT.Logout]: {
        success: resetCoaching,
        error: resetCoaching,
      },
      [AAT.DeleteAccount]: {
        success: resetCoaching,
      },
      [AAT.GetPersonalBotChat]: {
        success: setMissionState,
      },
      [AAT.WsDropMission]: {
        success: resetMissionStateOnDrop,
      },
      [AAT.WsSkipMission]: {
        success: resetMissionStateOnSkip,
      },
    },
    state,
    action,
  );
}
