import { useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import findParentCategory from '../../routes/Store/findParentCategory';
import { Routes } from '../../types/enums';
import { StoreItem } from '../../types/models';
import toQueryString from '../../utils/toQueryString';
import { StoreCategories } from './useStoreCategories';

export function useStoreItemLink(
  storeItem: StoreItem | undefined,
  categories: StoreCategories,
  variationId?: string,
): string | null {
  const { customization, dialog } = categories;

  return useMemo(() => {
    if (!customization || !dialog || !storeItem) {
      return null;
    }

    const search =
      '?' +
      toQueryString({
        item: storeItem.id,
        category: storeItem.category_id,
        variation: variationId,
      });

    switch (storeItem.variation_type) {
      case 'Color':
      case 'Print':
      case 'Hair': {
        const parentCategory = findParentCategory(
          storeItem.category_id,
          customization,
        );

        return (
          generatePath(Routes.StoreCategoryTab, {
            category: 'customization',
            tab: parentCategory?.name || '',
          }) + search
        );
      }
      case 'Dialog': {
        const parentCategory = findParentCategory(
          storeItem.category_id,
          customization,
        );
        return (
          generatePath(Routes.StoreDialogTab, {
            tab: parentCategory?.name || '',
          }) + search
        );
      }
    }

    return '';
  }, [dialog, customization, storeItem, variationId]);
}
