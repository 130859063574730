import { useDispatch } from 'react-redux';

import { skipDailyReward } from '../../../actions/store';
import { setActiveDialog } from '../../../actions/ui';
import { Dialogs } from '../../../types/enums';
import {
  ConfirmDialogBody,
  Dialog,
} from '../../DialogLayout/legacy/DialogLayout';

type Props = {
  onClose: () => void;
};

function SkipDailyRewardDialog({ onClose }: Props) {
  const dispatch = useDispatch();

  const getBack = () => {
    dispatch(
      setActiveDialog({
        type: Dialogs.DailyReward,
        autoClaim: true,
      }),
    );
  };

  const handleSkip = () => {
    dispatch(skipDailyReward());
    onClose();
  };

  return (
    <Dialog data-testid="skip-daily-reward-dialog" mobileLayout="action-sheet">
      <ConfirmDialogBody
        title="Don’t forget your reward"
        description="To keep the streak going, you have to claim the reward. Would you like to do it now?"
        confirmText="Claim now"
        cancelText="Skip"
        onConfirm={getBack}
        onCancel={handleSkip}
      />
    </Dialog>
  );
}

export default SkipDailyRewardDialog;
