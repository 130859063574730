import { sortBy } from 'lodash';
import * as A from '../types/actions';
import { ToSuccess } from '../types/asyncActions';
import { AsyncActionTypes as AAT, ActionTypes as AT } from '../types/enums';
import { Reducer } from '../types/redux';
import { AvatarState } from '../types/states';
import applyReducer from '../utils/applyReducer';
import { DEFAULT_AVATAR_ID } from '../utils/constants';
import getUnityBundleVersion from '../utils/getUnityBundleVersion';

const DEFAULT_STATE: AvatarState = {
  avatarStatus: 'idle',
  modelsV2: [],
  persist: {
    version: 1,
    lastUpdated: null,
    chooseAvatarScreenShown: false,
    unityBinaries: null,
    lastUnityVersion: getUnityBundleVersion(),
    unityWebEngine: null,
    roomSettings: {
      audioVolume: 50,
      radioEnabled: true,
      quality: null,
      daytime: null,
    },
  },
};

type R<X extends A.AvatarAction> = Reducer<AvatarState, X>;
type RSuccess<X> = Reducer<AvatarState, ToSuccess<X>>;

const setAvatars: RSuccess<A.GetAvatars> = (state, { result }) => {
  let models = result.avatars;
  const hasDefault = models.some((m) => m.is_default);

  // Make sure default model is always first in the list
  models = sortBy(models, (m) => {
    if (hasDefault) {
      return !m.is_default;
    } else {
      return m.id !== DEFAULT_AVATAR_ID;
    }
  });

  return {
    ...state,
    modelsV2: models,
    persist: {
      ...state.persist,
      models: undefined,
      lastUpdated: result.last_updated_timestamp,
    },
  };
};

const resetState: R<any> = (state) => ({
  ...DEFAULT_STATE,
});

const setChooseAvatarScreenShown: R<A.SetChooseAvatarScreenShown> = (
  state,
) => ({
  ...state,
  persist: {
    ...state.persist,
    chooseAvatarScreenShown: true,
  },
});

const setUnityBinaries: RSuccess<A.GetUnityBinaries> = (state, { result }) => ({
  ...state,
  persist: {
    ...state.persist,
    unityBinaries: result,
  },
});

const setAvatarStatus: R<A.SetAvatarStatus> = (state, { status }) => {
  if (state.avatarStatus === 'hidden-update' && status === 'updating') {
    return state;
  }

  return {
    ...state,
    avatarStatus: status,
  };
};

const setLastUnityVersion: R<A.SetLastUnityVersion> = (state, { version }) => ({
  ...state,
  persist: {
    ...state.persist,
    lastUnityVersion: version,
  },
});

const setUnityWebEngine: RSuccess<A.GetUnityWebEngine> = (
  state,
  { result },
) => ({
  ...state,
  persist: {
    ...state.persist,
    unityWebEngine: result,
  },
});

const setRoomSettings: R<A.SetRoomSettings> = (state, { settings }) => ({
  ...state,
  persist: {
    ...state.persist,
    roomSettings: {
      ...state.persist.roomSettings,
      ...settings,
    },
  },
});

const setAvatarViewMode: R<A.SetAvatarViewMode> = (state, { viewMode }) => ({
  ...state,
  viewMode,
});

export default function avatars(
  state: AvatarState = DEFAULT_STATE,
  action: A.AnyAction,
) {
  return applyReducer(
    'avatars',
    {
      [AAT.GetAvatars]: {
        success: setAvatars,
      },
      [AAT.Logout]: {
        success: resetState,
        error: resetState,
      },
      [AAT.DeleteAccount]: {
        success: resetState,
      },
      [AT.SetChooseAvatarScreenShown]: setChooseAvatarScreenShown,
      [AAT.GetUnityBinaries]: {
        success: setUnityBinaries,
      },
      [AT.SetAvatarStatus]: setAvatarStatus,
      [AT.SetLastUnityVersion]: setLastUnityVersion,
      [AAT.GetUnityWebEngine]: {
        success: setUnityWebEngine,
      },
      [AT.SetRoomSettings]: setRoomSettings,
      [AT.SetAvatarViewMode]: setAvatarViewMode,
    },
    state,
    action,
  );
}
