import * as amplitude from '@amplitude/analytics-browser';
import { differenceInDays, format } from 'date-fns';
import {
  CampaignswellEventName,
  GaActions,
  GaCategories,
  MetricsEvents,
} from '../types/enums';
import { GaEvent } from '../types/models';
import { BUILD_VERSION } from './constants';
import dateFromObjectId from './dateFromObjectId';
import { isEnvironment } from './environment';
import { getSessionSetting } from './session';

type CommonEventProperties = {
  'user platform': string;
  'web app version'?: string;
  date: string;
  hour: string;
  'day of the week': string;
  month: string;
  year: string;
  'user localization'?: string;
  'days since registration'?: number;
};

export type NoDuplicateEventProperties = { [key: string]: any } & {
  [K in keyof CommonEventProperties]?: never;
};

export type AnalyticsEventProperties = Record<string, any> &
  NoDuplicateEventProperties;

const AMPLITUDE_ID = 'fcad1c98162bbbe1591db4e6ca58fb4e';
const AMPLITUDE_ID_DEV = 'ad219de8f5c8e791840b3d916b5dcf1e';
const IS_PROD = isEnvironment('production');

let initialized = false;

export function initMetrics(userId: string | undefined) {
  if (!initialized) {
    amplitude.init(
      IS_PROD ? AMPLITUDE_ID : AMPLITUDE_ID_DEV,
      (userId || getSessionSetting('userid')) ?? '',
    );
  } else if (userId) {
    updateMetrics(userId);
  }
}

export function updateMetrics(userId: string) {
  if (amplitude.getUserId() !== userId) {
    amplitude.setUserId(userId);
  }
}

export enum EUserProperties {
  UnityContentVersion = 'unity_content_version',
  AppVersion = 'app_version',
  AbTestingGroup = 'ab_testing_group',
  ExperimentName = 'Experiment name',
  Theme = 'theme',
  Style = 'style',
  ReplikaGender = 'replika gender',
  AgeRange = 'age_range',
  Pronoun = 'pronoun',
}

export function setUserProperties(
  props: Partial<Record<EUserProperties, string | number>>,
) {
  const identify = new amplitude.Identify();
  for (let key in props) {
    identify.set(key, props[key]);
  }

  amplitude.identify(identify);
}

export function logEvent(
  event: MetricsEvents,
  eventProperties?: AnalyticsEventProperties,
  userId?: string,
) {
  const now = new Date();

  const hour = format(now, 'H').toLowerCase();
  const date = format(now, 'yyyy-MM-dd');
  const weekday = format(now, 'eeee').toLowerCase();
  const month = format(now, 'MMMM').toLowerCase();
  const year = format(now, 'yyyy');

  const clientLocale = navigator.languages?.length
    ? navigator.languages[0]
    : navigator.language;

  // FIXME: dateFromObjectId relies on moggodb API
  // it's better to use client REST API
  const daysSinceReg = userId
    ? differenceInDays(now, dateFromObjectId(userId))
    : undefined;

  const commonEventProperties: CommonEventProperties = {
    'user platform': 'web',
    'web app version': BUILD_VERSION,
    date,
    hour,
    'day of the week': weekday,
    month,
    year,
    ...(clientLocale && { 'user localization': clientLocale }),
    ...(daysSinceReg !== undefined && {
      'days since registration': daysSinceReg,
    }),
  };

  const mergedEventProps = {
    ...commonEventProperties,
    ...eventProperties,
  };

  amplitude.track(event, mergedEventProps);

  if (!IS_PROD) {
    // eslint-disable-next-line
    console.debug('[metrics event] ' + event, {
      ...eventProperties,
      ...commonEventProperties,
    });
  }
}

export const trackGaEvent = (
  action: GaActions,
  { category = GaCategories.Web, label, value, ...otherEventProps }: GaEvent,
) => {
  gtag?.('event', action, {
    category,
    label,
    ...(value ? { value } : {}),
    ...otherEventProps,
  });
};

export const trackCampaignswellSubscribeEvent = (params: {
  /**
   * Payment system internal subscription id:
   */
  subscription_id: string;
  /**
   * Payment system internal transaction id:
   * 1. Stripe: invoice id
   * 2. PayPal: transaction_id
   */
  transaction_id?: string;
  /**
   * Payment service provider
   */
  payment_system: string;
  /**
   * Main subscription period price
   */
  price: number;
  /**
   * Payment currency
   */
  currency: string;
  /**
   * Payment system internal product id:
   * 1. Stripe: price id
   * 2. PayPal: plan_id
   */
  product_id: string;
}) => {
  gtag?.('event', CampaignswellEventName.CwSubscribe, {
    ...params,
    currency: params.currency.toUpperCase(),
    payment_system: toInitCap(params.payment_system),
  });
};

export const trackCampaignswellOneTimePaymentEvent = (params: {
  /**
   * Payment system internal transaction id:
   * 1. Stripe: invoice id
   * 2. PayPal: transaction_id
   */
  transaction_id: string;
  /**
   * Payment service provider
   */
  payment_system: string;
  /**
   * Main subscription period price
   */
  price: number;
  /**
   * Payment currency
   */
  currency: string;
  /**
   * Payment system internal product id:
   * 1. Stripe: price id
   * 2. PayPal: plan_id
   */
  product_id: string;
  /**
   * Number of purchased in-apps
   */
  quantity: number;
}) => {
  gtag?.('event', CampaignswellEventName.CwNrcPurchase, {
    ...params,
    currency: params.currency.toUpperCase(),
    payment_system: toInitCap(params.payment_system),
  });
};

function toInitCap(value: unknown) {
  if (typeof value !== 'string') return value;

  return value.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (m) {
    return m.toUpperCase();
  });
}
