import { push } from 'connected-react-router';
import { useAtom } from 'jotai';
import { groupBy } from 'lodash';
import { Fragment, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { getStorefront, requestWallet } from '../../../actions/store';
import Scrollable from '../../../components/Scrollable';
import StoreCategoryGridSection, {
  StoreItemWithVariation,
} from '../../../components/StoreCategoryGrid/StoreCategoryGridSection';
import WalletButton from '../../../components/WalletButton';
import { useMobileQuery } from '../../../components/responsive';
import { useStoreCategories } from '../../../core/Store';
import { avatarPreviewAtom, basketAtom } from '../../../core/atoms';
import { Routes } from '../../../types/enums';
import useApi from '../../../utils/useApi';
import useGoBack from '../../../utils/useGoBack';
import findCategoryName from '../../Store/findCategoryName';
import findStoreFrontCategory from '../../Store/findStoreFrontCategory';
import { ModalLayout } from '../ModalScreenLayout';
import BasketFooter from './BasketFooter';

export default function StoreBasketModal() {
  const { goBack } = useGoBack({
    fallbackRoute: Routes.Store,
    closeOnEscape: true,
  });
  const dispatch = useDispatch();
  const rootCategories = useStoreCategories();

  const storefront = useApi((state) => state.store.storefront, getStorefront, {
    memoDeepEqual: true,
  });
  const storeFrontCategories = useMemo(() => {
    return storefront?.store_front_categories ?? [];
  }, [storefront]);

  const [error, setError] = useState<string | null>(null);

  const credit = useApi((state) => state.store.persist.credit, requestWallet, {
    memoDeepEqual: true,
  });

  const [basket, setBasket] = useAtom(basketAtom);
  const [{ variations, roomItems, pets }, updateAvatarPreview] =
    useAtom(avatarPreviewAtom);
  const selectedVariations = variations ?? [];
  const selectedRoomVariations = roomItems ?? [];
  const selectedPets = pets ?? [];

  const items = basket.map((b) => ({
    ...b.storeItem,
    variationIndex: b.variationIndex,
  }));

  const sumPrice = basket
    .map((item) => item.storeItem.price)
    .reduce(
      (acc, price) => {
        if (price.currency === 'coin') {
          acc.coins_count += price.amount;
        } else {
          acc.gems_count += price.amount;
        }

        return acc;
      },
      {
        coins_count: 0,
        gems_count: 0,
      },
    );

  const handleRemoveItem = (item: StoreItemWithVariation) => {
    const newBasket = basket.filter((i) => i.storeItem.id !== item.id);
    setBasket(newBasket);
    updateAvatarPreview({
      variations: selectedVariations.filter(
        (v) => v.id !== item.variations[item.variationIndex ?? 0]!.id,
      ),
      roomItems: selectedRoomVariations.filter(
        (v) => v.id !== item.variations[item.variationIndex ?? 0]!.id,
      ),
      pets: selectedPets.filter(
        (v) => v.id !== item.variations[item.variationIndex ?? 0]!.id,
      ),
    });
    if (newBasket.length === 0) {
      goBack();
    }
  };

  const isMobile = useMobileQuery();

  const groupedItems = useMemo(() => {
    if (items.length <= 6) {
      return [['', items] as [string, typeof items]];
    }
    let entries = Object.entries(
      groupBy(items, (item) => {
        const cat = findStoreFrontCategory(
          item.category_id,
          storeFrontCategories,
          rootCategories,
        );
        return cat
          ? findCategoryName(cat.category.category_id, cat.rootCategory) ?? ''
          : '';
      }),
    );

    entries.sort(([a], [b]) => {
      return a < b ? -1 : 1;
    });

    return entries;
  }, [items, storeFrontCategories, rootCategories]);

  return (
    <ModalLayout.Root>
      <ModalLayout.Header>
        <ModalLayout.HeaderRight>
          <WalletButton source="store" />
          <ModalLayout.CloseButton onClick={goBack} />
        </ModalLayout.HeaderRight>
      </ModalLayout.Header>
      <StyledContent>
        <Title>Purchase</Title>
        {items.length > 0 && (
          <Desc>
            Buy items in Store and customize your Replika’s look and personality
          </Desc>
        )}
        <StyledScrollable maskSize={50}>
          {groupedItems.map(([categoryName, subitems]) => {
            return (
              <Fragment key={categoryName}>
                <CategoryTitle>{categoryName}</CategoryTitle>
                <StyledGrid
                  $flex={items.length < 3}
                  id="basket-grid"
                  items={subitems}
                  itemWidth={isMobile ? 104 : 164}
                  onRemoveItem={handleRemoveItem}
                  emptyMessage="Your basket is empty"
                  noNewBadge
                />
              </Fragment>
            );
          })}
        </StyledScrollable>
        {error && <ModalLayout.Error>{error}</ModalLayout.Error>}
        <StyledBasketFooter
          credit={credit}
          onError={setError}
          sumPrice={sumPrice}
          onClose={(status) => {
            if (status === 'success') {
              goBack();
            } else {
              dispatch(push(Routes.Wallet));
            }
          }}
        />
      </StyledContent>
    </ModalLayout.Root>
  );
}

const StyledScrollable = styled(Scrollable)`
  flex: 1 1 auto;
  width: 100%;
  max-width: 560px;
  min-height: 0;
  overflow-y: auto;

  @media ${(p) => p.theme.breakpoints.tablet} {
    flex: 0 1 auto;
  }
`;

const Title = styled.h1`
  padding-inline: 20px;
  font-size: 28px;
  line-height: 32px;
  margin: 10px 0 0;

  @media ${(p) => p.theme.breakpoints.tablet} {
    font-size: 36px;
    line-height: 36px;
    padding-inline: 30px;
  }
`;

const Desc = styled.p`
  padding-inline: 20px;
  font-size: 14px;
  line-height: 18px;

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding-inline: 30px;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    max-width: 420px;
  }
`;

const CategoryTitle = styled.h2`
  padding-inline: 20px;
  font-size: 14px;
  margin-block: 15px;
  text-transform: capitalize;

  @media ${(p) => p.theme.breakpoints.tablet} {
    padding-inline: 30px;
    font-size: 22px;
    margin-block: 25px;
  }
`;

const StyledGrid = styled(StoreCategoryGridSection)<{ $flex: boolean }>`
  overflow: hidden;
  & > ul {
    flex: 1;
    display: ${(p) => (p.$flex ? 'flex' : 'grid')};
    justify-content: flex-start;
    width: 100vw;
    padding-top: 0;
    padding-inline: 20px;
    gap: 4px;
  }

  & > ul > li {
    max-width: ${(p) => p.itemWidth}px;
  }

  @media ${(p) => p.theme.breakpoints.tablet} {
    & > ul {
      padding-inline: 30px;
      min-width: 560px;
      width: auto;
      justify-content: center;
    }
  }
`;

const StyledContent = styled(ModalLayout.Content)`
  min-height: 0;

  justify-content: flex-start;
  align-items: flex-start;

  @media ${(p) => p.theme.breakpoints.tablet} {
    justify-content: center;
    align-items: center;
  }
`;

const StyledBasketFooter = styled(BasketFooter)`
  align-self: center;
`;
