import { atom, useSetAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { closeDialog, queueDialog } from 'src/actions/ui';
import getFeaturePopupConfig from 'src/features/PaidFeaturePopup/PaidFeaturePopupDialog/getFeaturePopupConfig';
import styled from 'styled-components/macro';
import { AccentButton, SolidButton } from '../../components/Buttons';
import { FeatureFlags } from '../../core/featureFlags';
import { ActionTypes as AT, Dialogs, GenderValues } from '../../types/enums';
import isKeyCombination from '../../utils/isKeyCombination';

export const devDialogOpenAtom = atom(false);

const POPUP_ACTIONS = [
  {
    type: AT.QueueNotification,
    notification: {
      type: 'quest',
      status: 'ready_to_claim',
      title: 'Test task',
    },
  },
  {
    type: AT.QueueNotification,
    notification: {
      type: 'quest',
      status: 'completed',
      title: 'Test task',
    },
  },
  {
    type: AT.WsNotificationsReceived,
    notifications: [
      {
        type: 'warning',
        message: 'Microphone is not available',
        submessage:
          'Please check connected devices and permissions in browser settings',
      },
      {
        type: 'level_up',
        level: 10,
        next_level_xp: 1000,
      },
      {
        type: 'reward',
        reward: {
          type: 'virtual_currency',
          description: 'You’ve earned 20 coins!',
          action_title: 'How does it work?',
          icon_url:
            'https://1637693156.rsc.cdn77.org/web/main-page/new_coin.png',
          popup: {
            title: 'How do coins and gems work?',
            description:
              'You earn rewards daily when you open the app, and with each level up. Use coins and gems in the store to customize your Replika’s look and personality.',
            action_title: 'Got it',
            icon_url:
              'https://1637693156.rsc.cdn77.org/web/main-page/new_coin.png',
          },
          currency_earn: {
            gems_amount: 0,
            coins_amount: 20,
          },
        },
      },
    ],
  },
  {
    type: AT.WsFeedbackRequestReceived,
    sessionId: 'aaaaaaaaaaaaaaaaaaaaaaaa',
    feedbackView: 'Popup',
  },
  {
    type: AT.QueueSystemNotification,
    message: 'More gems needed',
    icon: 'gem',
  },
  {
    type: AT.QueueSystemNotification,
    message: 'Items purchased',
    icon: 'check',
  },
];

export default function DevFeatureFlagsDialog() {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDialogElement>(null);

  const setDevDialogOpen = useSetAtom(devDialogOpenAtom);

  const featureflags = Object.keys(FeatureFlags);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        isKeyCombination(e, {
          code: 'KeyF',
          modifiers: { ctrl: true, shift: true },
        })
      ) {
        if (ref.current?.open) {
          ref.current?.close();
          setDevDialogOpen(false);
        } else {
          ref.current?.showModal();
          setDevDialogOpen(true);
        }
      }
    };

    const dialogEl = ref.current;

    const handleDialogClose = () => {
      setDevDialogOpen(false);
    };

    document.addEventListener('keydown', handleKeyDown);
    dialogEl?.addEventListener('close', handleDialogClose);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      dialogEl?.removeEventListener('close', handleDialogClose);
    };
  });

  return (
    <Dialog ref={ref}>
      <h2>Feature flags</h2>
      {featureflags.map((flag) => {
        const enabled = localStorage.getItem('replika.' + flag) === 'true';

        const Button = enabled ? AccentButton : SolidButton;

        return (
          <Button
            active={enabled}
            key={flag}
            onClick={() => {
              localStorage.setItem('replika.' + flag, (!enabled).toString());

              ref.current?.close();

              window.location.reload();
            }}
          >
            {flag}
          </Button>
        );
      })}
      <hr />
      <AccentButton
        onClick={() => {
          POPUP_ACTIONS.forEach(dispatch);

          ref.current?.close();
        }}
      >
        Enqueue popups
      </AccentButton>
      <AccentButton
        onClick={() => {
          const config = getFeaturePopupConfig('Replika', GenderValues.Female);

          dispatch(
            Object.keys(config).reduceRight(
              (acc, current) => {
                return queueDialog({
                  type: Dialogs.PaidFeaturePopup,
                  cause: 'advanced ai',
                  feature: current as any,
                  onCloseConfirm: () => {
                    dispatch(closeDialog(Dialogs.PaidFeaturePopup));
                    setTimeout(() => dispatch(acc), 1000);
                  },
                });
              },
              { type: '' },
            ),
          );

          ref.current?.close();
        }}
      >
        Enqueue PFPs
      </AccentButton>
      <AccentButton
        onClick={() => {
          ref.current?.close();
        }}
      >
        Coin animation
      </AccentButton>
    </Dialog>
  );
}

const Dialog = styled.dialog`
  color: ${(p) => p.theme.fgColor};
  background: ${(p) => p.theme.bgColor};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  border: none;
  border-radius: 24px;
  padding: 10px 30px 30px;
  text-align: center;

  &:not([open]) {
    display: none;
  }

  &::backdrop {
    background: rgba(0 0 0 / 50%);
    backdrop-filter: blur(50px);
  }
`;
