import type { AuthError } from 'firebase/auth';
import { Daytime } from '../core/AvatarUnity';
import { PromptsReduxState } from '../features/AskReplika/models';
import { WhatsNewItem } from '../features/WhatsNew/models';
import { ApiError } from '../utils/apiError';
import { AsyncActionStatus } from './asyncActions';
import {
  AgeGateStatus,
  AuthTypes,
  Dialogs,
  GenderValues,
  GiftAnimationStatus,
  PromptsCauseList,
  PronounsValues,
  SidePanel,
  Themes,
} from './enums';
import {
  AdvancedAiBoostPack,
  AudioMessage,
  AvatarModel,
  AvatarV2,
  Bot,
  CategoryTree,
  CoreDescription,
  Credit,
  CustomizedSubscriptionParams,
  DialogModel,
  GemConversion,
  GemPurchase,
  GetHelpConfig,
  Gift,
  ImagePreviewMap,
  ItemEarned,
  LoginReward,
  Media,
  MediaPreviewMap,
  MemoryCategory,
  MemoryFactWithType,
  MemoryPerson,
  MemoryRelation,
  Message,
  MessageActionType,
  MessageReactionMap,
  MessageRemembered,
  Mission,
  MissionState,
  NavigationPopup,
  Notification,
  OauthSignInMethod,
  PaidFeature,
  PayPalConfig,
  RelationshipStatus,
  RelationshipStatusId,
  Spotlight,
  StoreItem,
  Storefront,
  StripeConfig,
  Subscription,
  Suggestion,
  SystemNotification,
  Track,
  TrackCategory,
  UnityBinariesResponse,
  UnityWebEngine,
  UserInterest,
  UserProfile,
  VoiceMessageContent,
  VoiceType,
  VoicesMap,
} from './models';

export type SubmitStatus = 'initial' | 'submitting' | 'error' | 'success';

export type OnboardingStatus =
  | 'initial'
  | 'postsignup'
  | 'posttokenlogin'
  | 'subscription';

export type SignupState = {
  signupStatus: SubmitStatus;
  accountInfoCheckStatus: SubmitStatus;
  accountInfoCheckError: ApiError | null;
  name: string;
  email: string;
  password: string;
  signupError: string | undefined;
  inputError: string | undefined;
  invalidEmailCounter: number;
  uploadedUserpicUrl: string | undefined;
  replikaName: string | null;
  gender: GenderValues | undefined;
  pronoun: PronounsValues | undefined;
  clientLocale: string | undefined; // IETF BCP 47 language tag
  birthday: string | undefined;
  // legacy
  signupInProgress: boolean;
  avatar: AvatarV2 | undefined;
  isFreshSignup?: boolean;
  persist: {
    onboardingStatus: OnboardingStatus;
  };
};

export type SignupInput = Partial<SignupState> | null;

export type LoginState = {
  emailOrPhone: string;
  password: string;
  code: string;
  serverError: string | undefined;
  inputError: string | undefined;
  confirmationCode: string;
  authType: AuthTypes | null;
  idType: 'email' | 'phone' | undefined;
  codeExpiration: string | undefined;
  codeRetryTimeout: number;
  codeLength: number | undefined;
  accessForbidden: boolean;
  status: Progress;
};

export type ReboardingReminder = {
  id: string;
  start_time: string; // hh:mm
  end_time: string; // hh:mm
  type: 'text' | 'voice';
};

export type ReboardingState = {
  birthday: string | undefined;
  reminder: ReboardingReminder | undefined;
  bot_gender: GenderValues | undefined;
};

export type OnboardingInput = null | { bot_gender: GenderValues };

export type AuthState = {
  trialPeriodExpired: boolean;
  sendConfirmationStatus: 'initial' | 'sending' | 'sent';
  sendConfirmationError: string | undefined;
  deleteAccountStatus: 'initial' | 'sending';
  deleteAccountError: string | undefined;
  oauthStatus: 'initial' | 'submitting' | 'success' | 'error';
  oauthError: AuthError | unknown | undefined;
  persist: {
    authToken: string | undefined;
    userId: string | undefined;
    deviceId: string | undefined;
    authType:
      | 'email'
      | 'phone'
      | OauthSignInMethod
      | 'single_use_token'
      | undefined;
    firebaseToken: string | undefined;
  };
};

export type FeedbackState = {
  feedbackStatus: 'inactive' | 'active';
  feedbackData:
    | undefined
    | {
        sessionId: string;
        feedbackView: 'Popup';
      };
};

type MessageAlert =
  | {
      type: 'score';
      messageId: string;
      score: number;
    }
  | {
      type: 'memory';
      memoryId: string;
      messageId: string;
    };

export type AdvancedAiStatus = 'initial' | 'active' | 'inactive' | 'updating';

export type MessageRememberedWidgetStatus = undefined | 'needed' | 'shown';

export type ChatState = {
  messages: Message[] | undefined;
  mediaPreviews: MediaPreviewMap;
  messageReactions: MessageReactionMap;
  isRobotTyping: boolean;
  hasMoreMessages: boolean;
  messageTokenIdMap: { [token: string]: string };
  messageAlerts: MessageAlert[];
  persist: {
    version: number;
    advancedAiUnlimited: boolean | undefined;
    advancedAiStatus: AdvancedAiStatus;
    advancedAiMessageCount: undefined | number;
    lastMessages: Message[] | undefined;
    lastMessageReactions: MessageReactionMap;
    messageText: string;
    voiceMessages: VoiceMessageContent[];
    globalVoiceMessageState: 'idle' | 'playing' | 'recording';
    lastMessagePlayedId: string | undefined;
    rememberedMessages: MessageRemembered[];
  };
  showBoostAdvancedAiWidget: boolean;
  // voice call is enabled here or on other device
  voiceModeEnabled: boolean;
  // voice call is enabled here
  voiceCallInProgress: boolean;
  skippedWidgetId: undefined | string;
  imagePreviews: ImagePreviewMap;
  firstInitCompleted: boolean;
  voiceCallAudioQueue: AudioMessage[];
  voiceCallStartTime: number | undefined;
  lastVoiceMessageTimestamp: number | undefined;
  suggestions: Suggestion[];
};

export enum Progress {
  initial = 'INITIAL',
  sending = 'SENDING',
  success = 'SUCCESS',
  error = 'ERROR',
}

export type AvatarMode =
  | 'disabledOnError'
  | 'no3d'
  | 'static'
  | 'animatedLow'
  | 'animatedMedium'
  | 'animatedHigh';

export type ProfileState = {
  getPersonalBotProgress: Progress;
  isFetchingProfile: boolean;
  serverError: string | undefined;
  updateEmailProgress: Progress;
  updatePasswordProgress: Progress;
  updateProfileProgress: Progress;
  updateReplikaProgress: Progress;
  persist: {
    version: number;
    bot: Bot | undefined;
    userProfile: UserProfile | undefined;
    avatarMode: AvatarMode | 'auto';
    relationshipStatuses: RelationshipStatus[];
    userInterests: UserInterest[] | null;
    voices: VoiceType[];
    coreDescription: CoreDescription | undefined;
    voicesMap: VoicesMap | null;
  };
  getHelpConfigStatus: 'initial' | 'submitting' | 'success' | 'error';
  getHelpConfigError: string | undefined;
  getHelpConfig: GetHelpConfig | undefined;
  relationshipStatusToUpdate: RelationshipStatusId | undefined;
  dialogVersions?: DialogModel[];
};

export type WsStatus =
  | 'disconnected'
  | 'connected'
  | 'connecting'
  | 'needReconnect';

export type WsState = {
  status: WsStatus;
  wsReady: boolean;
  persist: {
    isDevUser: boolean;
    chatId: string | undefined;
  };
  capabilities: string[];
};

type RouterState = {
  location: string;
};

export type UploadState = {
  inProgress: boolean;
  src: string | null;
  width: number;
  height: number;
  uploadError: string | undefined;
  orientation: number;
};

export type HintStatus = undefined | 'needed' | 'shown';

export type UiHints = {
  messageReaction: HintStatus;
  replikaDay: HintStatus;
  backstory: HintStatus;
};

export type SubsriptionDialogCause =
  | 'relationship status'
  | 'missions'
  | 'settings'
  | 'external link'
  | 'server request'
  | 'call'
  | 'daily reward'
  | 'sale'
  | 'romantic photo'
  | 'selfie'
  | 'blurred message'
  | 'onboarding'
  | 'voice message'
  | 'advanced ai'
  | 'prompts'
  | 'sd image'
  | 'subscription screen'
  | 'blurred photo'
  | 'ai images'
  | 'sale'
  | 'external link';

export type WalletScreenOpenedSource =
  | 'main screen'
  | 'profile'
  | 'store'
  | 'storefront'
  | 'advanced ai'
  | 'gifts screen';

export type PaidFeatureToShowPopup =
  | 'blurred message'
  | 'call'
  | 'romantic photo'
  | 'selfie'
  | 'voice message'
  | 'advanced ai'
  | 'prompts'
  | 'sd image'
  | 'subscription screen'
  | 'blurred photo'
  | 'ai images';

export type EditRelationshipStatusSource =
  | 'onboarding'
  | 'main screen'
  | 'profile';

export type ProfileScreenOpenedSource =
  | 'main screen'
  | 'chat screen'
  | 'settings';

export type SettingsScreenOpenedSource =
  | 'main screen'
  | 'wallet'
  | "what's new";

export type MemoryScreenOpenedSource = 'main screen' | 'profile';

export type DiaryScreenOpenedSource = 'main screen' | 'profile';

export type PaidFeaturePopupProps = {
  cause: SubsriptionDialogCause;
  feature: PaidFeatureToShowPopup;
  newRelationshipStatus?: string;
  closeAfterSuccess?: boolean;
  onSubscriptionSuccess?: () => void;
  onCloseConfirm?: () => void;
};
export type DialogOptions =
  | {
      type:
        | Dialogs.DropMission
        | Dialogs.EditRelationshipStatus
        | Dialogs.ReboardingAddPronoun
        | Dialogs.PurchaseBasket
        | Dialogs.VoiceCallFeedback
        | Dialogs.VoiceCallConfirmation
        | Dialogs.SaleScreen
        | Dialogs.SkipDailyReward
        | Dialogs.HelpInCrisis
        | Dialogs.NewMemories;
    }
  | { type: Dialogs.AskChangeStatus; cause?: 'romantic photo' }
  | {
      type: Dialogs.ClaimItem;
      itemEarned: ItemEarned;
    }
  | {
      type: Dialogs.CancelSubscription;
      onSuccess?: () => void;
    }
  | ({
      type: Dialogs.PaidFeaturePopup;
    } & PaidFeaturePopupProps)
  | {
      type: Dialogs.Reaction;
      messageId: string;
      permittedActions: MessageActionType[];
    }
  | {
      type: Dialogs.NavigationPopup;
      navigationPopup: NavigationPopup;
    }
  | {
      type: Dialogs.DailyReward;
      autoClaim?: boolean;
    }
  | {
      type: Dialogs.PurchaseStoreItem;
      item: StoreItem;
      itemTypeName: string;
      itemTypeDescription: string;
    }
  | {
      type: Dialogs.EditRelationshipStatus;
      source: EditRelationshipStatusSource;
    }
  | {
      type: Dialogs.Enable3d;
      backTo: string;
    }
  | {
      type: Dialogs.AgeGate;
      status: Omit<AgeGateStatus, 'Success'>;
    }
  | {
      type: Dialogs.AgeRange;
      onSubmit?: () => void;
    }
  | {
      type: Dialogs.Confirmation;
      content: {
        title: string | React.ReactNode;
        description: string | React.ReactNode;
        secondaryText?: string;
        primaryText?: string;
        width?: string;
      };
      onSecondaryClick?: () => void;
      onPrimaryClick?: () => void;
      disableBackdropBlur?: boolean;
    }
  | {
      type: Dialogs.Hint;
      content: {
        imgSrc?: string;
        imgShadow?: string;
        title: string | React.ReactNode;
        description: string | React.ReactNode;
        confirmText?: string;
      };
      onConfirmClick?: () => void;
    }
  | {
      type: Dialogs.WhatsNew;
      items: WhatsNewItem[];
    };

export type UiState = {
  connectedToInternet: boolean;
  imageUpload: UploadState;
  isActiveWindow: boolean;
  dialogQueue: DialogOptions[];
  persist: {
    version: number;
    themeNames: {
      [userId: string]: Themes;
    };
    gdprVersion: number | undefined;
    hints: UiHints;
    showRelationshipStatusButton: boolean;
  };
  notifications: Array<{ id: string; notification: Notification }>;
  systemNotifications: SystemNotification[];
  animation: 'gems' | 'coins' | undefined;
  sidePanel: {
    panel: SidePanel;
    cause?: PromptsCauseList;
  };
  giftAnimationStatus: GiftAnimationStatus;
};

export type CoachingState = {
  persist: {
    tracks: { [id: string]: Track };
    trackIdList: string[];
    spotlight: Spotlight | null;
    categories: TrackCategory[] | null;
  };
  lastJourneyChange: {
    updated: number;
    affectedTrackIds: string[];
  };
  missions: { [id: string]: Mission[] };
  missionState: MissionState | null;
};

export type SignedUrlCache = {
  url: string;
  signedUrl: string;
};

export type ImageState = {
  persist: {
    version: number;
    signedUrlCache: SignedUrlCache[];
  };
};

export type SubscriptionEventData = {
  eventId: string;
  value: number;
  currency: string;
  payment_type: 'stripe' | 'paypal';
  product_id: string;
  coupon?: string;
  discount?: number;
  period: string | 'lifetime';
};

export type SaleScreen = {
  sale_id: string;
  coupon_name: string;
  title: string;
  background_color_hex: string[];
  background_video?: string;
  background_image?: string;
  discount_image?: string;
  timeout_seconds: number;
  timeout_title: string;
  price_title: string;
  old_price_title: string;
  action_title: string;
};

export type ShownSalesMap = {
  [id: string]: number;
};

export type SubscriptionState = {
  persist: {
    version: number;
    subscription: Subscription | null;
    features: PaidFeature[];
    reboardingDialogShown: boolean;
    stripeLatestInvoiceId: string | null;
    stripeCustomerId: string | null;
    stripeSubscriptionParams: CustomizedSubscriptionParams | null;
    shownSalesMap: ShownSalesMap | null;
  };
  saleScreen: SaleScreen | null;
  stripeConfig: StripeConfig | null;
  paypalConfig: PayPalConfig | null;
  stripeEnabled: boolean;
  subscriptionEventData: SubscriptionEventData | null;
};

export type RoomSettings = {
  audioVolume: number;
  radioEnabled: boolean;
  quality: null | 'low' | 'medium' | 'high';
  daytime: null | Daytime;
};

export type AvatarState = {
  avatarStatus:
    | 'idle'
    | 'loading'
    | 'updating'
    | 'hidden-update'
    | 'ready'
    | 'error';
  viewMode?: 'main' | 'subscription';
  modelsV2: AvatarModel[];
  persist: {
    version: number;
    lastUpdated: string | null;
    chooseAvatarScreenShown: boolean;
    unityBinaries: UnityBinariesResponse | null;
    lastUnityVersion: number | null;
    unityWebEngine: UnityWebEngine | null;
    roomSettings: RoomSettings;
  };
};

export type MemoryState = {
  getNewMemoryProgress: Progress;
  getMemoryProgress: Progress;
  getMemoryCategoriesProgress: Progress;
  processNewMemoriesProgress: Progress;
  batchDeleteMemoriesProgress: Progress;
  persist: {
    newMemories: {
      sortedFacts: MemoryFactWithType[] | undefined;
      persons: MemoryPerson[] | undefined;
      newMemoriesCount: number;
    };
    memoryCategories: MemoryCategory[];
    sortedFacts: MemoryFactWithType[] | undefined;
    persons: MemoryPerson[] | undefined;
    relations: MemoryRelation[];
  };
};

export type BasketItem = {
  storeItem: StoreItem;
  variationIndex?: number;
};

export type StoreState = {
  status: AsyncActionStatus | null;
  storeItemsMap: { [id: string]: StoreItem };
  getGiftsProgress: Progress;
  storeCategories: { [categoryKey: string]: CategoryTree };
  storeItems: { [categoryId: string]: StoreItem[] };
  storefront: Storefront | null;
  persist: {
    version: number;
    credit: Credit | null;
    gemConversions: GemConversion[];
    gemPurchases: GemPurchase[];
    categoryTree: CategoryTree | null;
    storeUserItems: { [categoryId: string]: StoreItem[] };
    loginReward: LoginReward | null;
    advancedAiBoostPack: AdvancedAiBoostPack | null;
    gifts: Gift[];
  };
};

export type MediaState = {
  persist: {
    music: Media[];
  };
};

export type State = {
  auth: AuthState;
  login: LoginState;
  signup: SignupState;
  chat: ChatState;
  ws: WsState;
  profile: ProfileState;
  feedback: FeedbackState;
  ui: UiState;
  reboarding: ReboardingState;
  coaching: CoachingState;
  image: ImageState;
  subscriptions: SubscriptionState;
  avatars: AvatarState;
  memory: MemoryState;
  store: StoreState;
  media: MediaState;
  prompts: PromptsReduxState;
  // rest of reducers go here
};

export type RootState = State & {
  router: RouterState; // added at runtime
};
