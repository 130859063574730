import { useAtomValue } from 'jotai';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components/macro';
import { useUnity3dDisabled } from '../../../core/AvatarUnity/useUnity3dDisabled';
import { avatarPreviewAtom, basketAtom } from '../../../core/atoms';
import { ReactComponent as DialogBgShine } from '../../../icons/DialogBgShine.svg';
import { ItemEarned, StoreItem } from '../../../types/models';
import StoreItemCard from '../../Cards/StoreItemCard';
import { Dialog, DialogBody } from '../../DialogLayout';
import ClaimedItemFooter from './ClaimedItemFooter';

type Props = {
  onClose: () => void;
  itemEarned: ItemEarned;
};

function ClaimItemDialog({ onClose, itemEarned }: Props) {
  const selectedVariations = useAtomValue(avatarPreviewAtom).variations ?? [];

  const basket = useAtomValue(basketAtom);
  const bot = useSelector((state) => state.profile.persist.bot);

  const disabled3d = useUnity3dDisabled();

  if (!itemEarned) return null;

  let title: string;
  let variationId: string | null = null;
  let claimedItem: StoreItem | null = null;
  let description: string;
  let imgUrl: string | null = null;

  if ('store_item' in itemEarned) {
    title = itemEarned.variation_title;
    variationId = itemEarned.variation_item_id;
    claimedItem = itemEarned.store_item;
    description = '7-day-streak special gift';
  } else {
    title = itemEarned.title;
    description = itemEarned.description;
    imgUrl = itemEarned.preview_url;
  }

  const avatarLocked = disabled3d || !bot?.avatar_v2;

  return (
    <StyledDialog data-testid="claim-item-dialog" mobileLayout="centered">
      <DialogBg src="/images/dialog-bg-sparks.jpg" />
      <StyledShine />
      <DialogBody mobileLayout="centered">
        {imgUrl ? (
          <ClaimedQuestReward src={imgUrl} />
        ) : variationId ? (
          <ClaimedStoreItemCard
            size={240}
            item={claimedItem}
            variationId={variationId}
            standalone
            noPriceBadge
            noNewBadge
            noTitle
          />
        ) : null}
        <Title>{title}</Title>
        <Subtitle>{description}</Subtitle>
        <ClaimedItemFooter
          claimedItem={claimedItem ?? undefined}
          avatarLocked={avatarLocked}
          variationId={variationId ?? undefined}
          onClose={onClose}
          selectedVariations={selectedVariations}
          basket={basket}
          closeText={'store_item' in itemEarned ? undefined : 'Continue'}
        />
      </DialogBody>
    </StyledDialog>
  );
}

export default ClaimItemDialog;

const ClaimedQuestReward = styled.img`
  position: relative;
  border-radius: 9px;
  margin-top: 60px;
  background-color: transparent;
  width: 152px;
  height: 152px;
`;

const scaleSparksIn = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
`;

const scaleShineIn = keyframes`
  from {
    opacity: 0.5;
    transform: scale(0.6);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

// clip-path is used to mitigate Chrome bug:
// border-radius, mix-blend-mode and animation don't play well together
const StyledDialog = styled(Dialog)`
  position: relative;
  min-width: 345px;
  background: linear-gradient(180deg, #ffa26d 0%, #8371f2 50%, #352562 100%);
  clip-path: inset(0% round 24px);
`;

const DialogBg = styled.img`
  inset: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  pointer-events: none;
  z-index: -1;
  animation: ${scaleSparksIn} 2s ease-out both;
  transform-origin: 50% 50%;
  mix-blend-mode: screen;
`;

const StyledShine = styled(DialogBgShine)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  & > g {
    animation: ${scaleShineIn} 1s ease-out both;
    transform-origin: 50% 30%;
  }
`;

const ClaimedStoreItemCard = styled(StoreItemCard)`
  margin-top: 60px;
  background-color: transparent;
`;

const Title = styled.h2`
  margin-block: 40px 0;
  font-size: 20px;
  line-height: 20px;
`;

const Subtitle = styled.p`
  margin-block: 10px 0;
  font-size: 16px;
  line-height: 18px;
  padding-inline: 30px;
  text-align: center;
  max-width: 320px;
`;
